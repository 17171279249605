import { Fragment, Suspense, lazy, useEffect, useRef, useState } from "react";
import { AppHistoryRequest } from "../../../../redux/slices/AppHistorySlice";
import ChatSkelton from "../../../../components/common/ChatSkelton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate, useParams } from "react-router-dom";
import Toaster from "../../../../components/common/Toaster";
import { Alert, Grid, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import ico from "../../../../assets/images/favicon.png";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ButtonSpinner from "../../../../components/common/ButtonSpinner";
import { Form } from "react-bootstrap";
import { WordCountRequest } from "../../../../redux/slices/CounterSlice";
import { SaveScrapReq } from "../../../../redux/slices/SaveScrapSlice";
import { SaveChatRequest } from "../../../../redux/slices/ChatPromptSlice";
import {
  BASEURL,
  checktoken,
  headers,
  markdownToText,
} from "../../../../utils/helper";
const MarkdownViewer = lazy(() =>
  import("../../../../components/chat/MarkdownViewer")
);

const ReaderHistory = () => {
  const chatBlock = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [height, setHeight] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const [input, setInput] = useState("");
  const [rows, setRows] = useState(1);
  const [chat, setChat] = useState([]);
  const { data: files } = useSelector((state) => state.ScrapeSlice);
  const [file, setFile] = useState("");
  const [link, setLink] = useState("");
  const { data: project } = useSelector((state) => state.DocProjectSlice);

  const type = "doc";
  const user = useSelector((state) => state.AuthSlice);
  const { data: prompts, loading } = useSelector(
    (state) => state.AppHistorySlice
  );
  const handleCopy = (content) => {
    content
      ? toast.success("Content copied to clipboard!")
      : toast.error("Content not found !");
  };
  // console.log("prompts..../", prompts);
  // console.log("project.../", project);

  const handleSend = async (e) => {
    e.preventDefault();
    dispatch(WordCountRequest());
    if (file === "" && link === "") {
      toast.error("Please select trained file or links from the top corner!");
      return false;
    }
    setInput("");
    setSpinner(true);
    setChat([...chat, { role: "user", prompt: input }]);
    try {
      scrollChat();

      const message = JSON.stringify({ id: link ? link : file, input: input });
      const response = await fetch(`${BASEURL}/user/scrap/chat`, {
        method: "POST",
        responseType: "stream",
        headers: headers(),
        body: message,
      });
      //eslint-disable-next-line
      if (!response.ok) {
        const errorResponse = await response.json();
        toast.error(errorResponse.error || "Something went wrong");
        setSpinner(false);
        return;
      }
      const readData = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      setSpinner(false);
      let aiRes = "";

      while (true) {
        scrollChat();
        const { done, value } = await readData.read();
        if (done) {
          break;
        }
        aiRes += value;
        setChat([
          ...chat,
          { role: "user", prompt: input },
          { airole: "assistant", airesponse: aiRes },
        ]);
        scrollChat();
      }

      scrollChat();

      dispatch(
        SaveChatRequest({
          prompt: input,
          type: "doc",
          airesponse: aiRes,
          project: id,
          file: link ? link : file,
        })
      );
      // dispatch(AppHistoryRequest({ id, type }));

      dispatch(SaveScrapReq({ prompt: input, airesponse: aiRes }));
      dispatch(WordCountRequest());
      scrollChat();
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      checktoken(err);
      if (err?.response?.data?.error) {
        toast.error(err.response.data.error);
      }
    }
  };

  const scrollChat = () => {
    setTimeout(function () {
      const objDiv = chatBlock.current;
      if (objDiv !== null && objDiv !== undefined) {
        objDiv.scrollTo({
          top: objDiv.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  };

  useEffect(() => {
    // console.log("input.length.../", input.length);
    // console.log("rows../", rows);
    if (input.length >= 0 && input.length < 120) setRows(1);
    else if (input.length >= 120 && input.length < 240) setRows(2);
    else if (input.length >= 240 && input.length < 360) setRows(3);
    else if (input.length >= 360 && input.length < 480) setRows(4);
    else if (input.length >= 480 && input.length < 600) setRows(5);
    else if (input.length > 600) setRows(6);
  }, [input]);

  useEffect(() => {
    if (prompts.length > 0 && prompts[prompts.length - 1]?.file)
      // console.log("prompts.../", prompts[prompts.length - 1].file);
      setFile(prompts[prompts.length - 1]?.file);
    // console.log(prompts)
  }, [prompts]);

  useEffect(() => {
    const getChat = async () => {
      if (type && id) {
        const chatHist = await dispatch(AppHistoryRequest({ id, type }));
        // console.log("chatHist.../", chatHist.payload.data);
        setChat(chatHist.payload.data);
        scrollChat();
      }
    };
    getChat();
    setHeight(document.getElementById("chat-layout").offsetHeight);
  }, [navigate]);

  return (
    <div
      className="flex flex-col"
      style={{ height: `calc(100vh - ${height}px)` }}
    >
      <Toaster />
      {loading && <ChatSkelton />}
      <div
        id="chat-body"
        className={`col-md-9 mx-auto d-flex flex-column flex-grow-1 chat-block h-100 overflow-auto`}
        ref={chatBlock}
      >
        {chat.length > 0 && (
          <div
            className={`mt-auto px-3 px-sm-0 hide-scroll-bar chat-section mb-sm-3 pt-2 ${
              chat.length > 0 ? "d-flex justify-content-end flex-column" : ""
            }`}
          >
            {chat.map((item, index) => {
              return (
                <Fragment key={index}>
                  {item.role === "user" && item.prompt !== "" && (
                    <div
                      className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start ${item.promptrole} w-100`}
                    >
                      <div className="d-flex ms-auto flex-grow-1 align-items-start">
                        <div className="d-block">
                          <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2">
                            {user.firstname.split("")[0]}
                            {user.lastname.split("")[0]}
                          </span>
                        </div>
                        <div
                          className="leading-loose w-100"
                          style={{
                            whiteSpace: "break-spaces",
                            wordBreak: "break-word",
                          }}
                        >
                          <div
                            className="question"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                item.prompt.replaceAll(/【.*?】/g, "")
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {item.airole === "assistant" && (
                    <div
                      className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start w-100 answer`}
                    >
                      <div className="d-block">
                        <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2 p-1">
                          <img className="w-100 h-100" src={ico} alt="ico" />
                        </span>
                      </div>
                      <div className="d-block flex-grow-1 answer-div">
                        <div
                          className="leading-loose"
                          style={{ fontWeight: 500 }}
                        >
                          <Suspense fallback={<div>Loading...</div>}>
                            <MarkdownViewer
                              className="answer-content"
                              content={DOMPurify.sanitize(
                                item.airesponse
                              ).replaceAll(/【.*?】/g, "")}
                            />
                          </Suspense>
                        </div>
                        <div className="d-flex align-items-center justify-content-end mt-1">
                          <CopyToClipboard
                            text={markdownToText(item.airesponse)}
                          >
                            <IconButton
                              className="p-0 d-flex align-items-center"
                              onClick={() =>
                                handleCopy(
                                  item.airesponse.replaceAll(/【.*?】/g, "")
                                )
                              }
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>

      {/* <div
        id="chat-body"
        className="grow chat-block overflow-y-auto h-full p-[20px]"
        ref={chatBlock}
      >
        {prompts?.length > 0 && (
          <Grid container className={`hide-scroll-bar chat-section mt-3`}>
            {prompts.map((item, index) => (
              <Fragment key={index}>
                {item.role === "user" && item.prompt !== "" && (
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start ${item.promptrole} w-100`}
                  >
                    <div className="d-flex ms-auto flex-grow-1 align-items-start">
                      <div className="d-block">
                        <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2">
                          {user?.firstname?.split("")[0]}
                          {user?.lastname?.split("")[0]}
                        </span>
                      </div>
                      <div
                        className="leading-loose w-100"
                        style={{
                          whiteSpace: "break-spaces",
                          wordBreak: "break-word",
                        }}
                      >
                        <div
                          className="question"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item.prompt),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                )}

                {item.airole === "assistant" && (
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start w-100 answer`}
                  >
                    <div className="d-block">
                      <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2 p-1">
                        <img className="w-100 h-100" src={ico} alt="ico" />
                      </span>
                    </div>
                    <div className="d-block flex-grow-1 answer-div">
                      <div
                        className={`leading-loose ${
                          item.type === "image" ? "d-block" : ""
                        }`}
                        style={{ fontWeight: 500 }}
                      >
                        {item.type === "image" ? (
                          <div style={{ maxWidth: "400px" }}>
                            <img
                              src={item.airesponse}
                              alt="image"
                              className="rounded mx-auto w-100"
                            />
                          </div>
                        ) : (
                          <Suspense fallback={<div>Loading...</div>}>
                            <MarkdownViewer
                              className="answer-content"
                              content={DOMPurify.sanitize(
                                item.airesponse
                              ).replaceAll(/【.*?】/g, "")}
                            />
                          </Suspense>
                        )}
                      </div>
                      {item?._id && item?.type !== "image" && (
                        <div className="d-flex align-items-center justify-content-end mt-1">
                          <CopyToClipboard
                            text={
                              item.type === "image"
                                ? item.airesponse
                                : item.airesponse.replaceAll(/【.*?】/g, "")
                            }
                          >
                            <IconButton
                              onClick={() => handleCopy(item.airesponse)}
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          </CopyToClipboard>
                        </div>
                      )}
                    </div>
                  </Grid>
                )}
              </Fragment>
            ))}
            {!prompts?.length && (
              <div className="col-12 p-3">
                <Alert severity="info" variant="filled">
                  Content not available is this project.
                </Alert>
              </div>
            )}
          </Grid>
        )}
      </div> */}
      <div
        className="col-md-9 col-12 mx-auto d-flex chat-actions sticky-bottom bg-white mb-3"
        style={{ zIndex: "2" }}
      >
        <div className="chat-input-group chat-input-group-custom px-3 px-sm-0 w-100">
          <Form
            onKeyDown={(e) => {
              e.key === "Enter" && !e.shiftKey && e.preventDefault();
            }}
          >
            <Form.Group className="position-relative">
              <Form.Control
                placeholder="Type your message here..."
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                onKeyUp={(e) => {
                  e.key === "Enter" &&
                    !e.shiftKey &&
                    e.target.value.trim() !== "" &&
                    handleSend(e);
                }}
                value={input}
                as="textarea"
                rows={rows}
              />
              {input.length < 4 ? (
                <button type="button" disabled className="chat-btn">
                  {spinner ? <ButtonSpinner /> : <ArrowUpwardRoundedIcon />}
                </button>
              ) : (
                <button
                  type="submit"
                  className="chat-btn"
                  onClick={(e) => {
                    handleSend(e);
                  }}
                >
                  {spinner ? <ButtonSpinner /> : <ArrowUpwardRoundedIcon />}
                </button>
              )}
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ReaderHistory;
