import { Toolbar, Avatar, Tooltip, Menu, Box, Button } from '@mui/material';
import { checktoken, headers } from '../../utils/helper';
import { Link, useNavigate } from 'react-router-dom';
import ButtonSpinner from '../common/ButtonSpinner';
import { FiLogOut, FiUser } from "react-icons/fi";
import { Cookies } from 'react-cookie'
import { useState } from 'react';
import axios from "axios"

const Header = ({ user }) => {
  const cookies = new Cookies();
  const navigate = useNavigate()
  const [spinner, setSpinner] = useState(false)
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleLogout = (id) => {
    setSpinner(true)
    axios.post(`${process.env.REACT_APP_API_URL}/admin/logout`, { id: id }, {
      headers: headers()
    }).then((res) => {
      if (res.data?.success === true) {
        cookies.remove('auth', { path: '/' });
        navigate(res.data.redirect);
      }
      setSpinner(false)
    }).catch((err) => {
      setSpinner(false)
      checktoken(err)
    })
  }

  const handleOpenUserMenu = (e) => {
    setAnchorElUser(e.currentTarget);
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  }

  return (
    <header className="bg-green1-dark">
      <Toolbar disableGutters className="justify-end">
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title={user.name}>
            <Box>
              <Button
                onClick={handleOpenUserMenu}
                size="small"
                startIcon={
                  <Avatar alt={user.firstname} src={user.image} sx={{ height: 30, width: 30 }} />
                }>
                <div className="inline-block">
                  <span className="block text-white font-medium">{user.firstname}</span>
                  <small className="block text-white" style={{ fontSize: '10px' }}>{user.role}</small>
                </div>
              </Button>
            </Box>
          </Tooltip>
          <Menu
            sx={{ mt: '45px', width: "auto" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Button
              className="text-gray-800 w-full"
              LinkComponent={Link}
              to="/admin/profile"
              startIcon={<FiUser />}
            >Profile</Button>
            <Button
              className="text-gray-800 w-full"
              onClick={() => { handleLogout(user._id) }}
              startIcon={<FiLogOut />}
            >Logout {spinner === true && <ButtonSpinner />}</Button>
          </Menu>
        </Box>
      </Toolbar>
    </header>
  )
}

export default Header;