import ManualPayment from "../pages/admin/transactions/ManualPayment";
import Transaction from "../pages/admin/transactions/Transaction";
import EditPrompt from "../pages/admin/prompt/EditPrompt";
import EditAdmin from "../pages/admin/admins/EditAdmin";
import AddPrompt from "../pages/admin/prompt/AddPrompt";
import AddAdmin from "../pages/admin/admins/AddAdmin";
import EditTone from "../pages/admin/tones/EditTone";
import AddTone from "../pages/admin/tones/AddTone";
import Prompt from "../pages/admin/prompt/Prompt";
import Dashboard from "../pages/admin/Dashboard";
import Admin from "../pages/admin/admins/Admin";
import Tones from "../pages/admin/tones/Tones";
import User from "../pages/admin/users/User";
import Profile from "../pages/admin/Profile";
import UserDevices from "../pages/admin/users/UserDevices";

const AdminRoute = [
  { path: "/admin/list", name: "Admin", component: <Admin /> },
  { path: "/admin/user/list", name: "User", component: <User /> },
  { path: "/admin/user/devices", name: "User", component: <UserDevices /> },
  { path: "/admin", name: "Dashboard", component: <Dashboard /> },
  { path: "/admin/prompts", name: "Prompt", component: <Prompt /> },
  { path: "/admin/profile", name: "Profile", component: <Profile /> },
  { path: "/admin/create", name: "AddAdmin", component: <AddAdmin /> },
  { path: "/admin/user/list/:page", name: "User", component: <User /> },
  { path: "/admin/add/prompt", name: "AddPrompt", component: <AddPrompt /> },
  { path: "/admin/list/edit/:id", name: "EditAdmin", component: <EditAdmin /> },
  {
    path: "/admin/transactions",
    name: "Transaction",
    component: <Transaction />,
  },
  {
    path: "/admin/prompt/edit/:id",
    name: "EditPrompt",
    component: <EditPrompt />,
  },
  { path: "/admin/tones", name: "Tones", component: <Tones /> },
  { path: "/admin/create/tone", name: "AddTone", component: <AddTone /> },
  { path: "/admin/edit/tone/:id", name: "EditTone", component: <EditTone /> },
  {
    path: "/admin/payment",
    name: "ManualPayment",
    component: <ManualPayment />,
  },
  {
    path: "/admin/payment/:page",
    name: "ManualPayment",
    component: <ManualPayment />,
  },
];

export default AdminRoute;
