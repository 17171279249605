import { SaveArticleHistory } from "../../../redux/slices/ArticleProjectSlice";
import { SaveArticleReq } from "../../../redux/slices/SaveArticleSlice";
import { WordCountRequest } from "../../../redux/slices/CounterSlice";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import {
  BASEURL,
  checktoken,
  headers,
  markdownToText,
} from "../../../utils/helper";
import CustomTooltip from "../../../components/common/CustomTooltip";
import MarkdownViewer from "../../../components/chat/MarkdownViewer";
import FormSkelton from "../../../components/common/FormSkelton";
import { ToneListRequest } from "../../../redux/slices/ToneSlice";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Toaster from "../../../components/common/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { Stack, Form, Card } from "react-bootstrap";
import ErrorIcon from "@mui/icons-material/Error";
import ProjectComponent from "./ProjectComponent";
import Select from "react-dropdown-select";
import { Button } from "@mui/material";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { marked } from "marked";

function GptArticle() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { historyId } = location.state || { historyId: null };
  // console.log("historyId.../", historyId);

  const [word, setWord] = useState(50);
  const [tone, setTone] = useState("");
  const [topic, setTopic] = useState("");
  const [limitError, setLimitError] = useState(false);
  const [stop, setStop] = useState(false);
  const [content, setContent] = useState([]);
  const [industry, setIndustry] = useState("");
  const [rows, setRows] = useState(1);

  const [tonelist, setToneList] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const user = useSelector((state) => state.AuthSlice);
  const [controller, setController] = useState(new AbortController());
  const { data: tones, loading } = useSelector((state) => state.ToneSlice);
  const { data: project } = useSelector((state) => state.ArticleProjectSlice);

  const handleStopStream = (e) => {
    setStop(false);
    controller.abort();
    setController(new AbortController());
  };

  const handleCopy = (content) => {
    content
      ? toast.success("Response copied to clipboard!")
      : toast.error("Response not found !");
  };

  const GenerateArticle = async (e) => {
    e.preventDefault();
    setLimitError(false);
    setSpinner(true);
    try {
      if (word === 0 || word === "") {
        toast.error("Please the total word!");
        setSpinner(false);
        return false;
      } else if (topic === "") {
        toast.error("Please enter the topic!");
        setSpinner(false);
        return false;
      } else if (industry === "") {
        toast.error("Please be more specific!");
        setSpinner(false);
        return false;
      } else if (tone === "") {
        toast.error("Please select any tone!");
        setSpinner(false);
        return false;
      }
      const formData = {
        tone: tone,
        word: +word,
        industry: industry,
        topic: topic,
      };
      dispatch(WordCountRequest());
      const message = JSON.stringify(formData);
      const response = await fetch(`${BASEURL}/user/generate/article`, {
        method: "POST",
        signal: controller.signal,
        responseType: "stream",
        headers: headers(),
        body: message,
      });
      // console.log("gpt article response../", response.status);
      //eslint-disable-next-line
      if (response.status === 500) {
        // console.log("response.status === 500");
        // setLimitError(true);
        toast.error("Daily limit exceeded");
        setSpinner(false);
        setStop(true);
      } else {
        const readData = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();
        setSpinner(false);
        setStop(true);
        let aiRes = "";
        while (true) {
          const { done, value } = await readData.read();
          if (done) {
            break;
          }
          aiRes += value;
          setContent([...content, { airesponse: aiRes }]);
        }
        setStop(false);
        setIsComplete(true);
        const savehistory = {
          prompt: topic,
          type: "article",
          airesponse: aiRes,
          project: project._id,
        };
        // console.log("saveArticleCall.../", saveArticleCall);
        const articleHistory = await dispatch(SaveArticleHistory(savehistory));
        // console.log("articleHistory.../", articleHistory.payload.data[articleHistory.payload.data.length - 1]);
        const saveArticle = {
          topic: topic,
          airesponse: aiRes,
          industry: industry,
          tone: tone,
          word: word,
          history_id:
            articleHistory.payload.data[articleHistory.payload.data.length - 1]
              ._id,
        };
        dispatch(SaveArticleReq(saveArticle));
        dispatch(WordCountRequest());
        setWord("");
        setTone("");
        setTopic("");
        setIndustry("");
      }
    } catch (err) {
      setSpinner(false);
      checktoken(err);
      if (err.response.status === 500) {
        // setLimitError(true);
        toast.error(err.response.data.error);
      }
      if (err?.response?.data?.error) {
        toast.error(err.response.data.error);
      }
    }
  };

  const handleSelectChange = (values) => {
    setTone(values[0].value);
  };

  const getProject = async () => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/get/article/${historyId}`,
        { headers: headers() }
      );
      // return response.data
      console.log(response.data.data);
      setTopic(response.data.data.topic);
      setWord(response.data.data.word);
      setIndustry(response.data.data.industry);
      // setHashtags(response.data.data.hashtags);
      setTone(response.data.data.tone);
    } catch (error) {
      checktoken(error);
      return error;
    }
  };
  useEffect(() => {
    if (tones?.length > 0 && Object.keys(tones)?.length) {
      const values = [];
      tones.length > 0 &&
        tones.map((item) => {
          values.push({
            label: `${item.imoji} ${item.title}`,
            value: item.title,
          });
        });
      setToneList(values);
    }
  }, [tones]);

  useMemo(() => {
    setContent([]);
    return content;
  }, [project]);

  useEffect(() => {
    if (topic.length >= 0 && topic.length < 120) setRows(1);
    else if (topic.length >= 120 && topic.length < 240) setRows(2);
    else if (topic.length >= 240 && topic.length < 360) setRows(3);
    else if (topic.length >= 360 && topic.length < 480) setRows(4);
    else if (topic.length >= 480 && topic.length < 600) setRows(5);
    else if (topic.length > 600) setRows(6);
  }, [topic]);

  useEffect(() => {
    if (user?.currentplan) {
      dispatch(ToneListRequest());
      dispatch(WordCountRequest());
    }
    if (historyId) getProject();
  }, []);

  return (
    <div className="h-100">
      {user?.currentplan && <ProjectComponent />}
      <Toaster />
      <Stack className="h-100">
        <div
          className={`mx-auto p-4 rounded border my-5 ${
            content.length === 0 ? "col-md-6 col-11" : "col-sm-11"
          }`}
        >
          {loading && <FormSkelton />}
          {!content.length && !loading && (
            <Form
              onSubmit={(e) => {
                user?.currentplan ? GenerateArticle(e) : e.preventDefault();
              }}
              onKeyDown={(e) =>
                e.key === "Enter" && !e.shiftKey ? e.preventDefault() : null
              }
            >
              <Form.Group className="mb-3 input-field">
                <div className="d-flex justify-content-between align-items-center">
                  <CustomTooltip
                    // title="تعداد کلمات"
                    arrow
                    placement="left"
                    content={
                      <Form.Label>
                        Words
                        <ErrorIcon className="text-body-tertiary fs-6" />
                      </Form.Label>
                    }
                  />
                </div>
                <CustomTooltip
                  title="WRITE NUMBERS IN ENGLISH"
                  placement="left"
                  arrow
                  content={
                    <Form.Control
                      type="number"
                      className="custom-field"
                      placeholder="Enter total number of words"
                      value={word}
                      onChange={(e) => {
                        setWord(e.target.value);
                      }}
                    />
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3 input-field">
                <div className="d-flex justify-content-between align-items-center">
                  <CustomTooltip
                    // title="موضوع مقاله چه باشد"
                    arrow
                    placement="left"
                    content={
                      <Form.Label>
                        Topic <ErrorIcon className="text-body-tertiary fs-6" />
                      </Form.Label>
                    }
                  />
                </div>
                <Form.Control
                  as="textarea"
                  placeholder="Artificial Intelligence in Copywriting"
                  className="custom-field custom-textarea"
                  value={topic}
                  rows={rows}
                  onChange={(e) => {
                    setTopic(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 input-field">
                <div className="d-flex justify-content-between align-items-center">
                  <CustomTooltip
                    // title="تمرکز مقاله را دقیقتر بنویسید"
                    arrow
                    placement="left"
                    content={
                      <Form.Label>
                        Be more specific{" "}
                        <ErrorIcon className="text-body-tertiary fs-6" />
                      </Form.Label>
                    }
                  />
                </div>
                <Form.Control
                  as={"textarea"}
                  type="text"
                  className="custom-field custom-textarea"
                  placeholder="Be more specific"
                  value={industry}
                  onChange={(e) => {
                    setIndustry(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <CustomTooltip
                    // title="تون مقاله را انتخاب کنید"
                    placement="left"
                    arrow
                    content={
                      <Form.Label>
                        Tone <ErrorIcon className="text-body-tertiary fs-6" />
                      </Form.Label>
                    }
                  />
                </div>
                <Select
                  style={{ height: "50px", fontSize: "16px" }}
                  className="text-capitalize multiselect-dropdown"
                  options={tonelist}
                  searchable={false}
                  placeholder="Select Tone"
                  labelField="label"
                  valueField="value"
                  values={tonelist.filter(
                    (toneItem) => toneItem.value === tone
                  )}
                  onChange={handleSelectChange}
                />
              </Form.Group>
              <CustomTooltip
                // title="مقاله را تولید کن"
                arrow
                placement="left"
                content={
                  <Button
                    type="submit"
                    className="bg-primary-light hover:bg-primary-main text-white"
                  >
                    Generate Article {spinner === true && <ButtonSpinner />}
                  </Button>
                }
              />
              {limitError && (
                <div className="mt-3">
                  <p className="font-bold text-red-500">Daily limit exceeded</p>
                </div>
              )}{" "}
            </Form>
          )}
          {content.length > 0 && (
            <Card className="border-0">
              <Card.Title> {topic?.current?.value}</Card.Title>
              <Card.Body className="p-0" style={{ whiteSpace: "break-spaces" }}>
                {content.map((item) => (
                  <MarkdownViewer
                    className="answer-content"
                    content={DOMPurify.sanitize(item.airesponse).replaceAll(
                      /【.*?】/g,
                      ""
                    )}
                  />
                ))}
                {stop && isComplete === false && (
                  <div className="mt-3 d-flex align-items-center justify-content-between">
                    <Button
                      type="button"
                      className="bg-primary-light hover:bg-primary-main text-white"
                      onClick={handleStopStream}
                    >
                      stop
                    </Button>
                  </div>
                )}
                {stop === false && isComplete === false && (
                  <Button
                    type="button"
                    className="bg-primary-light hover:bg-primary-main text-white"
                    onClick={() => {
                      setContent([]);
                      setIsComplete(false);
                    }}
                  >
                    Reset
                  </Button>
                )}
                {isComplete === true && (
                  <div className="mt-3 d-flex align-items-center justify-content-between">
                    <Button
                      type="button"
                      className="bg-primary-light hover:bg-primary-main text-white"
                      onClick={() => {
                        setContent([]);
                        setIsComplete(false);
                      }}
                    >
                      Reset
                    </Button>
                    <div className="flex gap-4">
                      <CopyToClipboard
                        text={markdownToText(content[0].airesponse)}
                      >
                        <Button
                          className="d-flex align-items-center bg-primary-light hover:bg-primary-main text-white"
                          onClick={() => handleCopy(content[0].airesponse)}
                        >
                          <ContentCopyIcon
                            fontSize="inherit"
                            className="me-1"
                          />
                          Copy as plain text
                        </Button>
                      </CopyToClipboard>
                      <CopyToClipboard text={marked(content[0].airesponse)}>
                        <Button
                          className="d-flex align-items-center bg-primary-light hover:bg-primary-main text-white"
                          onClick={() => handleCopy(content[0].airesponse)}
                        >
                          <ContentCopyIcon
                            fontSize="inherit"
                            className="me-1"
                          />
                          Copy as html
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </div>
      </Stack>
    </div>
  );
}

export default GptArticle;
