import { createSlice } from '@reduxjs/toolkit'

export const MessageSlice = createSlice({
    name: 'message',
    initialState: {},
    reducers: {
        addMessage: (state, action) => {
            state = action.payload
            return state
        },
        removeMessage: (state, action) => {
            state = action.payload
            return state
        },
    },
    extraReducers(builder) {
        builder.addCase('LOGOUT', () => this.initialState)
    }
})

export const { addMessage, removeMessage } = MessageSlice.actions
export default MessageSlice.reducer
