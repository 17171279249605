import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const EmailProjectRequest = createAsyncThunk(
  "EmailProjectRequest",
  async (type) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/latest/project?type=${type}`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const EmailProjectCreateReq = createAsyncThunk(
  "EmailProjectCreateReq",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/project/create`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const SaveEmailContent = createAsyncThunk(
  "SaveEmailContent",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/save/email/content`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const EmailProjectSlice = createSlice({
  name: "EmailProject",
  initialState: {
    data: {},
    error: null,
    loading: false,
  },
  reducers: {
    AddEmailProject: (state, action) => {
      state.data = action.payload;
      return state;
    },
    DeleteEmailProject: (state, action) => {
      state.data = {};
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(EmailProjectRequest.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(EmailProjectRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(EmailProjectRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase(EmailProjectCreateReq.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(EmailProjectCreateReq.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(EmailProjectCreateReq.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export const { AddEmailProject, DeleteEmailProject } =
  EmailProjectSlice.actions;
export default EmailProjectSlice.reducer;
