import { PersistGate } from "redux-persist/es/integration/react";
import LanguageDetector from "i18next-browser-languagedetector";
import { GoogleOAuthProvider } from "@react-oauth/google";
import store, { persistor } from "./redux/store/store";
import { BrowserRouter } from "react-router-dom";
import { initReactI18next } from "react-i18next";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/tailwind.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HttpApi from "i18next-http-backend";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./assets/css/index.css";
import "./assets/css/admin.css";
import "./assets/css/media.css";
import i18n from "i18next";
import App from "./App";
import { StyledEngineProvider } from "@mui/material";
import { PopupProvider } from "./context/PopupContext";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    suportedLngs: ["en", "fr"],
    // lng: document.querySelector('html').lang,
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    interpolation: {
      escapeValue: false,
    },
    react: { useSuspense: false },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <PopupProvider>
              <App />
            </PopupProvider>
          </StyledEngineProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </GoogleOAuthProvider>
);

reportWebVitals();
