import {
  Fragment,
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  Autocomplete,
  TextField,
  IconButton,
} from "@mui/material";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { SaveChatRequest } from "../../../../redux/slices/ChatPromptSlice";
import { WordCountRequest } from "../../../../redux/slices/CounterSlice";
import ButtonSpinner from "../../../../components/common/ButtonSpinner";
import {
  BASEURL,
  checktoken,
  headers,
  markdownToText,
} from "../../../../utils/helper";
import { SaveScrapReq } from "../../../../redux/slices/SaveScrapSlice";
import { GET_SCRAPE_REQ } from "../../../../redux/saga/sagaConst";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Toaster from "../../../../components/common/Toaster";
import ico from "../../../../assets/images/favicon.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import ProjectComponent from "../ProjectComponent";
import { Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
const MarkdownViewer = lazy(() =>
  import("../../../../components/chat/MarkdownViewer")
);

const Reader = () => {
  const chatBlock = useRef();
  const dispatch = useDispatch();
  const [file, setFile] = useState("");
  const [link, setLink] = useState("");
  const [chat, setChat] = useState([]);
  const [input, setInput] = useState("");
  const [rows, setRows] = useState(1);
  const [spinner, setSpinner] = useState(false);
  const user = useSelector((state) => state.AuthSlice);
  const { data: files } = useSelector((state) => state.ScrapeSlice);
  const { data: project } = useSelector((state) => state.DocProjectSlice);

  let allLink, allFile;
  if (files) {
    allLink = files.filter((item) => item.type === "link");
    allFile = files.filter((item) => item.type === "file");
  }

  const handleCopy = (content) => {
    content
      ? toast.success("Response copied to clipboard!")
      : toast.error("Response not found !");
  };

  const scrollChat = () => {
    setTimeout(function () {
      const objDiv = chatBlock.current;
      if (objDiv !== null && objDiv !== undefined) {
        objDiv.scrollTo({
          top: objDiv.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const handleSend = async (e) => {
    e.preventDefault();
    dispatch(WordCountRequest());
    if (file === "" && link === "") {
      toast.error("Please select trained file or links from the top corner!");
      return false;
    }
    setInput("");
    setSpinner(true);
    setChat([...chat, { role: "user", prompt: input }]);
    try {
      scrollChat();

      const message = JSON.stringify({ id: link ? link : file, input: input });
      const response = await fetch(`${BASEURL}/user/scrap/chat`, {
        method: "POST",
        responseType: "stream",
        headers: headers(),
        body: message,
      });
      //eslint-disable-next-line
      if (!response.ok) {
        const errorResponse = await response.json();
        toast.error(errorResponse.error || "Something went wrong");
        setSpinner(false);
        return;
      }
      const readData = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      setSpinner(false);
      let aiRes = "";

      while (true) {
        scrollChat();
        const { done, value } = await readData.read();
        if (done) {
          break;
        }
        aiRes += value;
        setChat([
          ...chat,
          { role: "user", prompt: input },
          { airole: "assistant", airesponse: aiRes },
        ]);
        scrollChat();
      }

      scrollChat();

      dispatch(
        SaveChatRequest({
          prompt: input,
          type: "doc",
          airesponse: aiRes,
          project: project._id,
          file: link ? link : file,
        })
      );
      dispatch(SaveScrapReq({ prompt: input, airesponse: aiRes }));
      dispatch(WordCountRequest());
      scrollChat();
      setSpinner(false);
    } catch (err) {
      setSpinner(false);
      checktoken(err);
      if (err?.response?.data?.error) {
        toast.error(err.response.data.error);
      }
    }
  };

  useMemo(() => {
    setChat([]);
    return chat;
  }, [project]);

  useEffect(() => {
    // console.log("input.length.../", input.length);
    // console.log("rows../", rows);
    if (input.length >= 0 && input.length < 120) setRows(1);
    else if (input.length >= 120 && input.length < 240) setRows(2);
    else if (input.length >= 240 && input.length < 360) setRows(3);
    else if (input.length >= 360 && input.length < 480) setRows(4);
    else if (input.length >= 480 && input.length < 600) setRows(5);
    else if (input.length > 600) setRows(6);
  }, [input]);

  useEffect(() => {
    if (
      user?.currentplan &&
      (user?.currentplan?.plankey === "execs_pro_monthly" ||
        user?.currentplan?.plankey === "execs_pro_semianual" ||
        user?.currentplan?.plankey === "execs_pro_yearly" ||
        user?.currentplan?.plankey === "team_member_monthly" ||
        user?.currentplan?.plankey === "team_member_semi" ||
        user?.currentplan?.plankey === "team_member_annual")
    ) {
      dispatch(WordCountRequest());
      dispatch({ type: GET_SCRAPE_REQ, payload: "" });
    }
    //eslint-disable-next-line
  }, []);

  return (
    <div
      className="d-flex flex-column pb-4"
      style={{
        height: `${window.innerWidth < 600 ? "90vh" : "75vh"}`,
      }}
    >
      {user?.currentplan?.[0] && <ProjectComponent />}
      <Toaster />
      <div className="d-flex align-items-start justify-content-between p-3">
        <div className="d-none d-sm-block">
          <h5>ExecutiveGPT</h5>
          <p>We answer any Question about your uploaded link/document.</p>
        </div>
        <div className="d-flex">
          {files && (
            <div className="d-flex gap-1 w-[800px] flex-wrap">
              <Box sx={{ width: 300 }}>
                <Autocomplete
                  fullWidth
                  name="file"
                  size="small"
                  id="trained-link"
                  options={allLink}
                  clearOnBlur={false}
                  value={link ? null : file}
                  defaultValue={null}
                  getOptionLabel={(option) => option?.[option?.type] ?? ""}
                  // getOptionLabel={(option) => console.log(option)}
                  getOptionKey={(option) => option?._id}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Trained File" />
                  )}
                  onChange={(_, val) => {
                    setFile("");
                    setLink(val?._id);
                  }}
                />
              </Box>
              <Box sx={{ width: 300 }}>
                <Autocomplete
                  fullWidth
                  name="file"
                  size="small"
                  id="trained-file"
                  clearOnBlur={false}
                  options={allFile}
                  value={file ? null : link}
                  defaultValue={null}
                  getOptionLabel={(option) =>
                    (option?.name ? option?.name : option?.title) || ""
                  }
                  getOptionKey={(option) => option?._id}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Trained File" />
                  )}
                  onChange={(_, val) => {
                    setLink("");
                    setFile(val?._id);
                  }}
                />
              </Box>

              <Button
                LinkComponent={Link}
                to="/user/chat/scrapping"
                startIcon={<ArrowBackIcon />}
                className="bg-primary-light hover:bg-primary-main text-white px-3"
              >
                go back
              </Button>
            </div>
          )}
        </div>
      </div>

      <div
        id="chat-body"
        className={`col-md-9 mx-auto d-flex flex-column flex-grow-1 chat-block h-100 overflow-auto`}
        ref={chatBlock}
      >
        {chat.length > 0 && (
          <div
            className={`mt-auto px-3 px-sm-0 hide-scroll-bar chat-section mb-sm-3 pt-2 ${
              chat.length > 0 ? "d-flex justify-content-end flex-column" : ""
            }`}
          >
            {chat.map((item, index) => {
              return (
                <Fragment key={index}>
                  {item.role === "user" && item.prompt !== "" && (
                    <div
                      className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start ${item.promptrole} w-100`}
                    >
                      <div className="d-flex ms-auto flex-grow-1 align-items-start">
                        <div className="d-block">
                          <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2">
                            {user.firstname.split("")[0]}
                            {user.lastname.split("")[0]}
                          </span>
                        </div>
                        <div
                          className="leading-loose w-100"
                          style={{
                            whiteSpace: "break-spaces",
                            wordBreak: "break-word",
                          }}
                        >
                          <div
                            className="question"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                item.prompt.replaceAll(/【.*?】/g, "")
                              ),
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {item.airole === "assistant" && (
                    <div
                      className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start w-100 answer`}
                    >
                      <div className="d-block">
                        <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2 p-1">
                          <img className="w-100 h-100" src={ico} alt="ico" />
                        </span>
                      </div>
                      <div className="d-block flex-grow-1 answer-div">
                        <div
                          className="leading-loose"
                          style={{ fontWeight: 500 }}
                        >
                          <Suspense fallback={<div>Loading...</div>}>
                            <MarkdownViewer
                              className="answer-content"
                              content={DOMPurify.sanitize(
                                item.airesponse
                              ).replaceAll(/【.*?】/g, "")}
                            />
                          </Suspense>
                        </div>
                        <div className="d-flex align-items-center justify-content-end mt-1">
                          <CopyToClipboard
                            text={markdownToText(item.airesponse)}
                          >
                            <IconButton
                              className="p-0 d-flex align-items-center"
                              onClick={() =>
                                handleCopy(
                                  item.airesponse.replaceAll(/【.*?】/g, "")
                                )
                              }
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
        )}
      </div>
      <div
        className="col-md-9 col-12 mx-auto d-flex chat-actions sticky-bottom bg-white mb-3"
        style={{ zIndex: "2" }}
      >
        <div className="chat-input-group chat-input-group-custom px-3 px-sm-0 w-100">
          <Form
            onKeyDown={(e) => {
              e.key === "Enter" && !e.shiftKey && e.preventDefault();
            }}
          >
            <Form.Group className="position-relative">
              <Form.Control
                placeholder="Type your message here..."
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                onKeyUp={(e) => {
                  e.key === "Enter" &&
                    !e.shiftKey &&
                    e.target.value.trim() !== "" &&
                    handleSend(e);
                }}
                value={input}
                as="textarea"
                rows={rows}
              />
              {input.length < 4 ? (
                <button type="button" disabled className="chat-btn">
                  {spinner ? <ButtonSpinner /> : <ArrowUpwardRoundedIcon />}
                </button>
              ) : (
                <button
                  type="submit"
                  className="chat-btn"
                  onClick={(e) => {
                    handleSend(e);
                  }}
                >
                  {spinner ? <ButtonSpinner /> : <ArrowUpwardRoundedIcon />}
                </button>
              )}
            </Form.Group>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default Reader;
