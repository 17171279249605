import { ScrapeOnLoading, ScrapeOnSuccess } from "../slices/ScrapeSlice"
import { call, put, takeEvery } from "redux-saga/effects"
import { BASEURL, headers } from "../../utils/helper"
import { GET_SCRAPE_REQ } from "./sagaConst"
import axios from "axios";

function* getScrapeFileReq({ payload }) {
    try {
        yield put(ScrapeOnLoading())
        const response = yield call(axios.get, `${BASEURL}/user/train/file/list`,
            { headers: headers() }
        )
        yield put(ScrapeOnSuccess(response?.data?.data))
    } catch (error) {
        console.log(error);
        return error;
    }
}

const watchScrapeSaga = function* () {
    yield takeEvery(GET_SCRAPE_REQ, getScrapeFileReq)
}

export default watchScrapeSaga;