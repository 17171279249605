// SocialProjectSlice

import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const SocialProjectRequest = createAsyncThunk(
  "SocialProjectRequest",
  async (type) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/latest/project?type=${type}`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const SocialProjectCreateReq = createAsyncThunk(
  "SocialProjectCreateReq",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/project/create`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const SocialContentRequest = createAsyncThunk(
  "SocialContentRequest",
  async (data) => {
    try {
      const response = await axios.post(`${BASEURL}/user/save/social`, data, {
        headers: headers(),
      });
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const SocialContentUpdateRequest = createAsyncThunk(
  "SocialContentRequest",
  async (data) => {
    try {
      const response = await axios.put(
        `${BASEURL}/user/update/social/${data.id}`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const SocialProjectSlice = createSlice({
  name: "SocialProject",
  initialState: {
    data: {},
    error: null,
    loading: false,
  },
  reducers: {
    AddSocialProject: (state, action) => {
      state.data = action.payload;
      return state;
    },
    DeleteSocialProject: (state, action) => {
      state.data = {};
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(SocialProjectRequest.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(SocialProjectRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(SocialProjectRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase(SocialProjectCreateReq.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(SocialProjectCreateReq.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(SocialProjectCreateReq.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export const { AddSocialProject, DeleteSocialProject } =
  SocialProjectSlice.actions;
export default SocialProjectSlice.reducer;
