import { Button, Dialog, Slide, DialogContent, Tooltip, Chip } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { checktoken, headers, BASEURL } from "../../../utils/helper";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import { Fragment, useEffect, useState, forwardRef } from "react";
import { Table, Container, Card, Form } from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import Toaster from '../../../components/common/Toaster';
import Loader from '../../../components/common/Loader';
import Pagination from '@mui/material/Pagination';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { icons } from "../../../assets/js/icons"
import { toast } from 'react-toastify';
import { CSVLink } from "react-csv";
import axios from 'axios';
const { MuiTransaction } = icons

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const ManualPayment = () => {
    const { page } = useParams()
    const navigate = useNavigate()
    const [date, setDate] = useState('');
    const [email, setEmail] = useState('')
    const [input, setInput] = useState('');
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [userid, setUserid] = useState('')
    const [uexport, setUExport] = useState('');
    const [status, setStatus] = useState('');
    const [payment, setPayment] = useState(0);
    const [spinner, setSpinner] = useState(false)
    const [totalPage, setTotalPage] = useState(0)
    const [isLoader, setIsLoader] = useState(true)
    const [pagination, setPagination] = useState([])
    const [currentPage, setCurrentPage] = useState('')

    const resetFun = () => {
        setInput('');
        page ? UserList(page) : UserList(1);
    }

    const UserList = (page) => {
        setIsLoader(true)
        axios.get(`${BASEURL}/admin/users/list?page=${page}`, {
            headers: headers()
        }).then((res) => {
            if (res.data?.success === true) {
                setUsers(res.data.data);
                setTotalPage(res.data.pages)
                setCurrentPage(res.data.current)
                let updatePag = [];
                for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                setPagination(updatePag);
            }
            setIsLoader(false)
        }).catch((err) => { checktoken(err); setIsLoader(false) })
    }

    const UserSearch = (value, npage) => {
        if (value !== "") {
            axios.get(`${BASEURL}/admin/user/search?page=${npage ? npage : 1}&text=${value}`, {
                headers: headers()
            }).then((res) => {
                if (res.data?.success === true) {
                    setUsers(res.data.data);
                    setTotalPage(res.data.pages)
                    setCurrentPage(res.data.current)
                    let updatePag = [];
                    for (let i = 1; i <= res.data.pages; i++) { updatePag.push(i) }
                    setPagination(updatePag);
                }
            }).catch((err) => {
                checktoken(err);
                setUsers([])
                setTotalPage(0)
                setPagination([])
                setCurrentPage('')
                if (err?.response?.data?.success === false) {
                    toast.error(err.response.data.error);
                }
            })
        } else {
            setInput('');
            page ? UserList(page) : UserList(1)
        }
    }

    const ExportPayment = () => {
        axios.get(`${BASEURL}/admin/export/paymet`, {
            headers: headers()
        }).then((res) => {
            if (res.data?.success === true) {
                const rows = res?.data?.data.map((item) => ({
                    'Full Name': `${item?.firstname} ${item?.lastname}`,
                    'Email': item?.email,
                    'Status': item?.status,
                    'Payment': `$ ${item?.payment}`,
                    'Expire Date': item?.expiredplan,
                }));
                setUExport(rows)
            }
        }).catch((err) => {
            checktoken(err);
            if (err?.response?.data?.success === false) {
                toast.error(err.response.data.error);
            }
        })
    }

    useEffect(() => {
        ExportPayment();
        page ? UserList(page) : UserList(1)

        // eslint-disable-next-line
    }, [navigate])

    return (
        <Fragment>
            <Toaster />
            {isLoader === true && <Loader />}
            <Container>
                <Card className="shadow-sm border-none">
                    <Card.Header className="d-flex align-items-center justify-content-between">
                        <Card.Title className="my-2 flex-grow-1">User List</Card.Title>
                        <Form.Group className="d-flex">
                            <Form.Control type="text" value={input} onChange={(e) => { setInput(e.target.value); UserSearch(e.target.value, null) }} placeholder="Search users" />
                            <Button type="button" onClick={() => { resetFun() }} className="ms-2 bg-green1-main hover:bg-green1-dark p-2 text-white">reset</Button>
                            <CSVLink type="button" className="text-uppercase ms-2 bg-green1-main hover:bg-green1-dark p-2 px-3 rounded text-white d-flex" style={{ width: '257px' }} data={uexport}><DownloadForOfflineIcon className='me-1' />export csv</CSVLink>
                        </Form.Group>
                    </Card.Header>
                    <Card.Body>
                        <Fragment>
                            <div className="d-block table-responsive">
                                <Table striped bordered hover className="m-0 custom-tbl">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Full Name</th>
                                            <th>Email</th>
                                            <th>Payment Status</th>
                                            <th>Payment</th>
                                            <th>Time/Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            users.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{page > 1 ?
                                                            (10 * page) + (index - 9) : index + 1
                                                        }</td>
                                                        <td className="fw-bold">{item.firstname + ' ' + item.lastname}</td>
                                                        <td>{item.email}</td>
                                                        <td>
                                                            <Chip color={`${item.status === 'free' ? 'warning' : 'success'}`} label={item.status} />
                                                        </td>
                                                        <td>${item?.payment}</td>
                                                        <td>{item?.expiredplan && new Date(item.expiredplan).toDateString()}</td>
                                                        <td>
                                                            <Tooltip title="Update Payment" arrow>
                                                                <IconButton onClick={() => { setOpen(true); setEmail(item?.email); setStatus(item?.status); setUserid(item?._id) }} aria-label="delete">
                                                                    {MuiTransaction}
                                                                </IconButton>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                            {(pagination.length > 1 && input === '') &&
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <Pagination count={totalPage} page={+currentPage} defaultPage={1} onChange={(e, value) => navigate(`/admin/payment/${value}`)} />
                                </div>
                            }
                            {(pagination.length > 1 && input !== '') &&
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    <Pagination count={totalPage} page={+currentPage} defaultPage={1} onChange={(e, value) => UserSearch(input, value)} />
                                </div>
                            }
                        </Fragment>
                    </Card.Body>
                </Card>
            </Container>
        </Fragment>
    )
}

export default ManualPayment;