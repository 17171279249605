import ButtonSpinner from '../../../components/common/ButtonSpinner';
import { checktoken, headers, BASEURL } from '../../../utils/helper';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import Toaster from '../../../components/common/Toaster';
import Loader from '../../../components/common/Loader';
import { Link, useNavigate } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios'

const AddTone = () => {
    const navigate = useNavigate()
    const [title, setTitle] = useState('')
    const [imoji, setImoji] = useState('')
    const [spinner, setSpinner] = useState(false)
    const [isLoader, setIsLoader] = useState(true)

    const handleSubmit = (e) => {
        setSpinner(true)
        e.preventDefault()
        axios.post(`${BASEURL}/admin/create/tone`, { title, imoji }, {
            headers: headers(),
        }).then((res) => {
            if (res?.data?.success === true) {
                navigate('/admin/tones');
            }
            setSpinner(false);
        }).catch((err) => {
            checktoken(err);
            setSpinner(false);
            if (err?.response?.data?.success === false) {
                toast.error(err.response.data.error);
            }
        })
    }

    useEffect(() => {
        setIsLoader(false)
    }, [])

    return (
        <Container>
            {isLoader === true && <Loader />}
            <Toaster /><Card className='shadow-sm border-0'>
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <Card.Title className="my-2">Add Tone</Card.Title>
                    <Button
                        size="small"
                        to="/admin/tones"
                        LinkComponent={Link}
                        startIcon={<ListIcon />}
                        className="bg-green1-main hover:bg-green1-dark p-2 text-white"
                    >Tones</Button>
                </Card.Header>
                <Card.Body>
                    <Form className="mb-3" encType='multipart/form-data' onSubmit={handleSubmit}>
                        <Row>
                            <Col md={6} className='m-auto'>
                                <Form.Group className="input-field mb-3">
                                    <Form.Control type="text" name="title" className="custom-field" placeholder="Enter tone title" value={title} onChange={(e) => { setTitle(e.target.value) }} />
                                </Form.Group>
                                <Form.Group className="input-field mb-3">
                                    <Form.Control type="text" className="custom-field" placeholder="Enter tone imoji" name="imoji" value={imoji} onChange={(e) => { setImoji(e.target.value); }} />
                                </Form.Group>
                                <Form.Group className="input-field">
                                    <Button type="submit" className="bg-green1-main hover:bg-green1-dark p-2 text-white">Submit {spinner === true && <ButtonSpinner />}</Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default AddTone;