const TextLoader = () => {
    return (
        <section className="position-fixed top-0 end-0 bottom-0 start-0 d-flex align-items-center justify-content-center" style={{ zIndex: 999999, background: '#6C0345' }}>
            <div className="loading loading06">
                <span data-text="T">T</span>
                <span data-text="R">R</span>
                <span data-text="A">A</span>
                <span data-text="N">N</span>
                <span data-text="S">S</span>
                <span data-text="L">L</span>
                <span data-text="A">A</span>
                <span data-text="T">T</span>
                <span data-text="I">I</span>
                <span data-text="O">O</span>
                <span data-text="N">N</span>
                <span>&nbsp;</span>
                <span data-text="I">I</span>
                <span data-text="N">N</span>
                <span>&nbsp;</span>
                <span data-text="P">P</span>
                <span data-text="R">R</span>
                <span data-text="O">O</span>
                <span data-text="G">G</span>
                <span data-text="R">R</span>
                <span data-text="E">E</span>
                <span data-text="S">S</span>
                <span data-text="S">S</span>
            </div>
        </section>
    )
}

export default TextLoader;