import { Grid, Stack, Skeleton } from "@mui/material";

const TableSkelton = () => {
    return (
        <Stack spacing={1}>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={50} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={50} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={50} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={50} />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton variant="rounded" height={40} />
                </Grid>
            </Grid>
        </Stack>
    )
}

export default TableSkelton;