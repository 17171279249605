import { IconButton, Button, Avatar, Container, Grid, Box } from '@mui/material';
import ButtonSpinner from '../../../components/common/ButtonSpinner';
import { BASEURL, headers, checktoken } from '../../../utils/helper';
import { addAuth } from '../../../redux/slices/AuthSlice';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Toaster from '../../../components/common/Toaster';
import { useDispatch, useSelector } from 'react-redux';
import { deepOrange } from '@mui/material/colors';
import { Card, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Cookies } from 'react-cookie';
import axios from 'axios';

const Profile = () => {
	const cookies = new Cookies()
	const dispatch = useDispatch()
	const [email, setEmail] = useState('')
	const [lastname, setLastname] = useState('')
	const [password, setPassword] = useState('')
	const [spinner, setSpinner] = useState(false)
	const [firstname, setFirstname] = useState('')
	const [isUpdate, setIsUpdate] = useState(false)
	const [newpassword, setNewPassword] = useState('')
	const [confirmpass, setConfirmpass] = useState('')
	const user = useSelector(state => state.AuthSlice)
	const [passspinner, setPassSpinner] = useState(false)

	const handleImage = (e) => {
		setSpinner(true)
		e.preventDefault()
		const formData = new FormData();
		formData.append('image', e.target.files[0]);
		axios.post(`${BASEURL}/user/update/image`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': `Bearer ${cookies.get('auth')}`,
			},
		}).then((res) => {
			if (res?.data?.success === true) {
				dispatch(addAuth(res.data.user))
				toast.success(res.data.msg);
			}
			setSpinner(false);
		}).catch((err) => {
			checktoken(err);
			setSpinner(false);
			if (err?.response?.data?.success === false) {
				toast.error(err.response.data.error);
			}
		})
	}

	const handleUpdate = (e) => {
		setSpinner(true)
		e.preventDefault()
		const formData = {
			email: email,
			lastname: lastname,
			firstname: firstname,
		}
		axios.put(`${BASEURL}/user/update/info/edit/${user._id}`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
				'Authorization': `Bearer ${cookies.get('auth')}`,
			},
		}).then((res) => {
			if (res?.data?.success === true) {
				dispatch(addAuth(res.data.user))
				toast.success(res.data.msg);
			}
			setSpinner(false);
		}).catch((err) => {
			checktoken(err);
			setSpinner(false);
			if (err?.response?.data?.success === false) {
				toast.error(err.response.data.error);
			}
		})
	}

	const handleUpdatePassword = (e) => {
		setPassSpinner(true)
		e.preventDefault();
		axios.put(`${BASEURL}/user/update/password/${user._id}`, { password, newpassword, confirmpass }, {
			headers: headers(),
		}).then((res) => {
			if (res?.data?.success === true) {
				setPassword('')
				setNewPassword('')
				setConfirmpass('')
				dispatch(addAuth(res.data.user))
				toast.success(res.data.msg);
			}
			setPassSpinner(false);
		}).catch((err) => {
			checktoken(err);
			setPassSpinner(false);
			if (err?.response?.data?.success === false) {
				toast.error(err.response.data.error);
			}
		})
	}

	useEffect(() => {
		if (Object.keys(user).length > 0) {
			setEmail(user.email)
			setLastname(user.lastname)
			setFirstname(user.firstname)
		}

		// eslint-disable-next-line
	}, [])

	return (
		<section id="Profile" className="border-top py-10">
			<Toaster />
			<Container>
				<Grid container spacing={2}>
					<Grid item xs={12} md>
						<div id="user-profile">
							<Box className="m-auto w-full sm:w-3/4">
								<Card className="border-0 shadow-sm p-3">
									<div className='d-flex justify-content-between'>
										<div className="user-pic mb-4 position-relative" style={{ width: 100, height: 100 }}>
											<Avatar src={user.image} alt={user.firstname} sx={{ width: 100, height: 100 }} />
											{isUpdate === true &&
												<IconButton className="position-absolute top-100 start-50 translate-middle p-0 bg-primary-light hover:bg-primary-main text-white w-[30px] h-[30px]">
													<AddAPhotoIcon className="text-[16px]" />
													<input type="file" onChange={(e) => handleImage(e)} className="position-absolute opacity-0 start-0 end-0" />
												</IconButton>
											}
										</div>
										<div className="d-block text-end mb-3"><Button onClick={() => { setIsUpdate(!isUpdate) }} sx={{ bgcolor: deepOrange[500] }} className="bg-primary-light hover:bg-primary-main text-white">{isUpdate === false ? 'edit' : 'cancel'}</Button></div>
									</div>
									<Form onSubmit={handleUpdate}>
										<Form.Group className="custom-field mb-3">
											<Form.Control className="custom-field" type="text" placeholder="Firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} readOnly={isUpdate === false ? true : false} />
										</Form.Group>
										<Form.Group className="custom-field mb-3">
											<Form.Control className="custom-field" type="text" placeholder="Lastname" value={lastname} onChange={(e) => setLastname(e.target.value)} readOnly={isUpdate === false ? true : false} />
										</Form.Group>
										<Form.Group className="custom-field mb-3">
											<Form.Control className="custom-field" type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} readOnly={isUpdate === false ? true : false} />
										</Form.Group>
										{
											isUpdate === true &&
											<Form.Group className="custom-field">
												<Button className="bg-primary-light hover:bg-primary-main text-white" type="submit">udpate profile {spinner === true && <ButtonSpinner />}</Button>
											</Form.Group>
										}
									</Form>
									<Form onSubmit={handleUpdatePassword}>
										<Form.Group className="custom-field mb-3 mt-3">
											<Form.Control className="custom-field" type="password" placeholder="Enter old password" value={password} onChange={(e) => setPassword(e.target.value)} readOnly={isUpdate === false ? true : false} />
										</Form.Group>
										<Form.Group className="custom-field mb-3">
											<Form.Control className="custom-field" type="password" placeholder="Enter new password" value={newpassword} onChange={(e) => setNewPassword(e.target.value)} readOnly={isUpdate === false ? true : false} />
										</Form.Group>
										<Form.Group className="custom-field mb-3">
											<Form.Control className="custom-field" type="password" placeholder="Confirm password" value={confirmpass} onChange={(e) => setConfirmpass(e.target.value)} readOnly={isUpdate === false ? true : false} />
										</Form.Group>
										{
											isUpdate === true &&
											<Form.Group className="custom-field">
												<Button  className="bg-primary-light hover:bg-primary-main text-white"type="submit">udpate password {passspinner === true && <ButtonSpinner />}</Button>
											</Form.Group>
										}
									</Form>
								</Card>
							</Box>
						</div>
					</Grid>
				</Grid>
			</Container>
		</section>
	)
}

export default Profile;