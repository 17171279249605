import { Box, Container, Grid, Typography, Chip, Button } from "@mui/material";
import { BASEURL, headers, checktoken } from "../../../utils/helper";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import { addAuth } from "../../../redux/slices/AuthSlice";
import Toaster from "../../../components/common/Toaster";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { Card, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { MemberPaymentRequest } from "../../../redux/slices/MemberSlice";

const Account = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [spinner, setSpinner] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const user_id = searchParams.get("user_id");
  const user = useSelector((state) => state.AuthSlice);
  //   console.log("user.../", user);
  const { data } = useSelector((state) => state.MemberSlice);

  const CancelSubscription = (id, index) => {
    setSpinner({ ...spinner, [index]: true });
    console.log("CancelSubscription = (id).../", id);
    axios
      .get(`${BASEURL}/admin/subscription/cancel/${id}`, {
        headers: headers(),
      })
      .then((res) => {
        if (res?.data?.success === true) {
          dispatch(addAuth(res.data.data));
          toast.success(res.data.msg);
        }
        // setSpinner([...spinner, ([index] = false)]);
        setSpinner({ ...spinner, [index]: false });

        // setSpinner();
      })
      .catch((err) => {
        checktoken(err);
        // setSpinner(false);
        // setSpinner([...spinner, ([index] = false)]);
        setSpinner({ ...spinner, [index]: false });

        if (err?.response?.data?.success === false) {
          toast.error(err.response.data.error);
        }
      });
  };

  console.log("user?.currentplan.../", user?.currentplan);

  useEffect(() => {
    if (user_id) {
      // console.log(data)
      console.log({
        user_id,
        subscriptionid: data?.id,
        priceid: data?.plan?.id,
      });
      dispatch(
        MemberPaymentRequest({
          user_id: user_id,
          subscriptionid: data?.id,
          priceid: data?.plan?.id,
        })
      );
    }
  }, [user]);

  return (
    <section id="Playlist" className="border-top py-10">
      <Toaster />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={8} className="md:mx-auto">
            <div className="flex flex-col gap-4">
              {user?.currentplan?.map((plan, index) => {
                return (
                  <>
                    <Card>
                      <Card.Header className="m-0">
                        <Grid
                          item
                          xs={12}
                          className="pb-[12px] d-flex align-items-start justify-content-between m-0 p-0"
                        >
                          <Typography
                            variant="h3"
                            className="text-2xl ff-lato text-gray-600 font-medium m-0 p-0"
                          >
                            {plan?.packagename}
                            {/* Account */}
                          </Typography>
                          <div className="d-flex ">
                            {plan?.subscriptionid &&
                              plan?.status !== "canceled" && (
                                <Button
                                  className="primary-blue-btn ff-lato me-2"
                                  onClick={() => {
                                    CancelSubscription(
                                      plan?.subscriptionid,
                                      index
                                    );
                                  }}
                                >
                                  <ClearIcon className="me-2" />
                                  Cancel Subscription{" "}
                                  {spinner[index] === true && <ButtonSpinner />}
                                </Button>
                              )}
                            <Button
                              LinkComponent={Link}
                              to="/pricing"
                              className="primary-blue-btn ff-lato me-2"
                            >
                              {" "}
                              upgrade{" "}
                            </Button>
                            {plan?.status && (
                              <Chip
                                label={plan?.status}
                                className="text-capitalize"
                                color="success"
                              />
                            )}
                          </div>
                        </Grid>
                      </Card.Header>
                      <Card.Body>
                        <Table striped bordered hover>
                          <tr>
                            <td className="font-medium">Plan</td>
                            <td>{plan?.packagename ?? "Free Plan"}</td>
                          </tr>
                          {plan?.expiredAt && (
                            <tr>
                              <td className="font-medium">Valid Till </td>
                              <td>
                                {moment(plan?.expiredAt).format("MMM DD, YYYY")}
                              </td>
                            </tr>
                          )}
                          {plan?.expiredAt && plan?.status !== "canceled" && (
                            <tr>
                              <td className="font-medium">
                                Next Payment Date :
                              </td>
                              <td>
                                {moment(plan?.expiredAt).format("MMM DD, YYYY")}
                              </td>
                            </tr>
                          )}
                          {plan?.minutes && plan?.status !== 'trialing' && (
                            <tr>
                              <td className="font-medium">TTV minutes assigned: </td>
                              <td>{plan?.plankey == 'pro_monthly' ? 40 : plan?.plankey == 'pro_semianual' ? 300 : plan?.plankey == 'ttv_monthly' ? 80 : plan?.plankey == 'ttv_semianual' ? 480 : 'N/A'}</td>
                            </tr>
                          )}
                          {plan?.minutes && plan?.status == 'trialing' && (
                            <tr>
                              <td className="font-medium">TTV minutes assigned: </td>
                              <td>10</td>
                            </tr>
                          )}
                          {plan?.minutes && (
                            <tr>
                              <td className="font-medium">TTV minutes remaining: </td>
                              <td>{plan?.minutes.toFixed(2)}</td>
                            </tr>
                          )}
                        </Table>
                      </Card.Body>
                    </Card>
                  </>
                );
              })}
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Account;
