import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const SaveTranslationReq = createAsyncThunk(
  "SaveTranslationReq",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/save/translation`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
    }
  }
);

const SaveTranslationSlice = createSlice({
  name: "SaveTranslationSlice",
  initialState: {
    data: {},
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(SaveTranslationReq.pending, (state, action) => {
      state.loading = true;
      state.data = {};
    });
    builder.addCase(SaveTranslationReq.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(SaveTranslationReq.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export default SaveTranslationSlice.reducer;
