import { Dialog, Slide, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, Fragment } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const LanguageModal = ({ open, handleClose, text }) => {
    return (
        <Fragment>
            <Dialog
                open={open}
                keepMounted
                maxWidth={'sm'}
                fullWidth
                className="language-modal"
                onClose={handleClose}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogContent className="language-content text-center position-relative">
                    <IconButton aria-label="delete" size="small" className="position-absolute top-0 start-100 translate-middle language-close-button" onClick={handleClose}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                    <h4 className='m-0'>{text}</h4>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}
export default LanguageModal;