import { TeamMemberReq } from '../../../redux/slices/TeamMemberSlice';
import CustomTooltip from '../../../components/common/CustomTooltip';
import ButtonSpinner from '../../../components/common/ButtonSpinner';
import { BASEURL, checktoken, headers } from '../../../utils/helper';
import TableSkelton from '../../../components/common/TableSkelton';
import { Alert, Button, Chip, IconButton } from '@mui/material';
import MemberMail from '../../../components/common/MemberMail';
import { addAuth } from '../../../redux/slices/AuthSlice';
import Toaster from '../../../components/common/Toaster';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useDispatch, useSelector } from 'react-redux';
import PaymentIcon from '@mui/icons-material/Payment';
import { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next'
import { Card, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from 'axios';

const Team = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [subid, setSubid] = useState('')
    const [open, setOpen] = useState(false)
    const handleClose = () => setOpen(false)
    const [spinner, setSpinner] = useState(false)
    const { data, loading } = useSelector(state => state.TeamMemberSlice);

    const CancelSubscription = (id) => {
        setSubid(id)
        setSpinner(true)
        axios.get(`${BASEURL}/admin/subscription/cancel/${id}`, {
            headers: headers(),
        }).then((res) => {
            if (res?.data?.success === true) {
                dispatch(addAuth(res.data.data));
                dispatch(TeamMemberReq())
                toast.success(res.data.msg);
                setSpinner(false);
                setSubid('');
            }
        }).catch((err) => {
            checktoken(err);
            setSpinner(false);
            setSubid('');
            if (err?.response?.data?.success === false) {
                toast.error(err.response.data.error);
            }
        })
    }

    useMemo(() => {
        handleClose()
        return data;
    }, [data])

    useEffect(() => {
        dispatch(TeamMemberReq())
    }, [])

    return (
        <div className="h-100">
            <Toaster />
            <MemberMail open={open} handleClose={handleClose} />
            <div className="d-block p-3">
                <Card>
                    <Card.Header>
                        <Card.Title className="d-flex align-items-center justify-content-between m-0">
                            <div>
                                <h5 className='mb-2'>{t("team_members")}</h5>
                                <p className="text-[16px]">{t("membership_text")}</p>
                            </div>
                            <div className='d-block'>
                                <Button startIcon={<GroupAddIcon />} className="px-2 bg-primary-light hover:bg-primary-main text-white" onClick={(e) => { setOpen(true) }}>Add Member</Button>
                            </div>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {(loading && !data?.length) && <TableSkelton />}
                        {(!loading && !data?.length) && <Alert severity="info">Still not added any team member.</Alert>}
                        {data?.length > 0 &&
                            <div className="d-block table-responsive">
                                <Table striped bordered hover className="m-0 custom-tbl">
                                    <thead>
                                        <tr>
                                            <th>no</th>
                                            <th>name</th>
                                            <th>email</th>
                                            <th>status</th>
                                            <th>start date</th>
                                            <th>end date</th>
                                            <th>action</th>
                                        </tr>
                                    </thead>
                                    {data.map((user, index) => (
                                        <tr key={user?._id}>
                                            <td>{index + 1}</td>
                                            <td className='text-capitalize'>{user.firstname + " " + user.lastname}</td>
                                            <td>{user.email}</td>
                                            <td>
                                                {user?.memberplan?.status &&
                                                    <Chip className={`bg-${user?.memberplan?.status === 'active' ? 'success' : user?.memberplan?.status === 'cancled' ? 'warning' : 'danger'} text-white text-capitalize`} label={user?.memberplan?.status} />
                                                }
                                            </td>
                                            <td>{user?.memberplan?.startedAt && new Date(user?.memberplan?.startedAt).toDateString()}</td>
                                            <td>{user?.memberplan?.expiredAt && new Date(user?.memberplan?.expiredAt).toDateString()}</td>
                                            <td>
                                                <CustomTooltip title={`${user?.memberplan?.status === 'active' ? 'Remove Plan' : ''}`} placement={'top'} arrow content={user?.memberplan?.status === 'active' ?
                                                    <IconButton onClick={() => { CancelSubscription(user?.memberplan?.subscriptionid) }}>
                                                        {(subid === user?._id && spinner) ? <ButtonSpinner /> : <CloseIcon />}
                                                    </IconButton>
                                                    :
                                                    <Button size="small" className="my-1 ms-1" LinkComponent={Link} to={`/member-pricing?user_id=${user?._id}`}>
                                                        <PaymentIcon className='me-1' /> {t("activate_member")}
                                                    </Button>
                                                } />
                                            </td>
                                        </tr>
                                    ))}
                                </Table>
                            </div>
                        }
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export default Team;
