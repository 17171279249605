import { checktoken, headers, BASEURL } from "../../../utils/helper";
import { Table, Container, Card, Form } from "react-bootstrap";
import { Alert, Stack, Chip, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Toaster from "../../../components/common/Toaster";
import Loader from "../../../components/common/Loader";
import { Fragment, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "@mui/material/Pagination";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import swal from "sweetalert";
import axios from "axios";

const UserDevices = () => {
  const { page } = useParams();
  const navigate = useNavigate();
  const [input, setInput] = useState("");
  const [devices, setDevices] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoader, setIsLoader] = useState(true);
  const [pagination, setPagination] = useState([]);
  const [currentPage, setCurrentPage] = useState("");

  const resetFun = () => {
    setInput("");
    page ? UserList(page) : UserList(1);
  };

  const UserList = (page) => {
    setIsLoader(true);
    axios
      .get(`${BASEURL}/admin/users/devices/list?page=${page}`, {
        headers: headers(),
      })
      .then((res) => {
        if (res.data?.success === true) {
          setDevices(res.data.data);
          setTotalPage(res.data.pages);
          setCurrentPage(res.data.current);
          let updatePag = [];
          for (let i = 1; i <= res.data.pages; i++) {
            updatePag.push(i);
          }
          setPagination(updatePag);
        }
        setIsLoader(false);
      })
      .catch((err) => {
        checktoken(err);
        setIsLoader(false);
      });
  };

  const DeleteUser = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Device!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .delete(`${BASEURL}/admin/user/device/delete/${id}`, {
            headers: headers(),
          })
          .then((res) => {
            if (res.data?.success === true) {
              toast.success(res?.data?.msg);
              UserList(1);
            }
          })
          .catch((err) => {
            checktoken(err);
            if (err?.response?.data?.success === false) {
              toast.error(err.response.data.error);
            }
          });
      } else {
        swal("User is safe!");
      }
    });
  };

  const UserSearch = (value, npage) => {
    if (value !== "") {
      axios
        .get(
          `${BASEURL}/admin/users/search/devices/list?page=${
            npage ? npage : 1
          }&text=${value}`,
          {
            headers: headers(),
          }
        )
        .then((res) => {
          if (res.data?.success === true) {
            setDevices(res.data.data);
            setTotalPage(res.data.pages);
            setCurrentPage(res.data.current);
            let updatePag = [];
            for (let i = 1; i <= res.data.pages; i++) {
              updatePag.push(i);
            }
            setPagination(updatePag);
          }
        })
        .catch((err) => {
          checktoken(err);
          setDevices([]);
          setTotalPage(0);
          setPagination([]);
          setCurrentPage("");
          if (err?.response?.data?.success === false) {
            toast.error(err.response.data.error);
          }
        });
    } else {
      setInput("");
      page ? UserList(page) : UserList(1);
    }
  };
  useEffect(() => {
    page ? UserList(page) : UserList(1);
    // eslint-disable-next-line
  }, [navigate]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoader(false);
    }, 2000);
  }, []);
  return (
    <Fragment>
      <Toaster />
      {isLoader === true && <Loader />}
      <Container>
        <Card className="shadow-sm border-none">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <Card.Title className="my-2 flex-grow-1">User List</Card.Title>
            <Form.Group className="d-flex">
              <Form.Control
                type="text"
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                  UserSearch(e.target.value, null);
                }}
                placeholder="Search users"
              />
              <Button
                type="button"
                onClick={() => {
                  resetFun();
                }}
                className="ms-2 bg-green1-main hover:bg-green1-dark p-2 text-white"
              >
                reset
              </Button>
            </Form.Group>
          </Card.Header>
          <Card.Body>
            <Fragment>
              <div className="d-block table-responsive">
                <Table striped bordered hover className="m-0 custom-tbl">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Email Verified</th>
                      <th>Device ID</th>
                      {/* <th>How did you hear about us</th> */}
                      {/* <th>Language</th> */}
                      <th>Created at</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {devices.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {page > 1 ? 10 * page + (index - 9) : index + 1}
                          </td>
                          <td className="fw-bold">
                            {item.user.firstname + " " + item.user.lastname}
                          </td>
                          <td>{item.user.email}</td>
                          <td>
                            <Chip
                              color={`${
                                item.user.is_email_verified === "1"
                                  ? "success"
                                  : "primary"
                              }`}
                              label={
                                item.user.is_email_verified === "1"
                                  ? "Yes"
                                  : "No"
                              }
                            />
                          </td>
                          <td>{item.device}</td>

                          <td>
                            {new Date(item.user.createdAt).toDateString()}
                          </td>
                          <td>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              onClick={() => {
                                DeleteUser(item._id);
                              }}
                            >
                              <DeleteIcon className="text-green1-main hover:text-green1-dark" />
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              {pagination.length > 1 && input === "" && (
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <Pagination
                    count={totalPage}
                    page={+currentPage}
                    defaultPage={1}
                    onChange={(e, value) => {
                      //   navigate(`/admin/user/list/${value}`)
                      UserList(value);
                    }}
                  />
                </div>
              )}
              {pagination.length > 1 && input !== "" && (
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <Pagination
                    count={totalPage}
                    page={+currentPage}
                    defaultPage={1}
                    onChange={(e, value) => UserSearch(input, value)}
                  />
                </div>
              )}
            </Fragment>
          </Card.Body>
        </Card>
      </Container>
    </Fragment>
  );
};

export default UserDevices;
