import HomeIcon from '@mui/icons-material/Home';
import Logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="page-notfound d-flex flex-column align-items-center justify-content-center" style={{ height: '100vh' }}>
            <div className="d-block mb-4">
                <img src="https://storage.googleapis.com/wisdom-frontend-bucket/logo-top.png" alt="img" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
            </div>
            <h1>page 404</h1>
            <h5 className="mb-4">The page you were looking for could not be found.</h5>
            <Link to="/" className="d-flex align-items-center justify-content-center rounded"><HomeIcon className="me-2" />go home</Link>
        </div>
    )
}
export default NotFound;