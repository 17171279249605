import { headers, checktoken, BASEURL } from "../../utils/helper";
import { Container, Row, Col, Card } from "react-bootstrap";
import { FiUsers, FiDollarSign } from "react-icons/fi";
import { Fragment, useState, useEffect } from "react";
import Toaster from '../../components/common/Toaster';
import Loader from '../../components/common/Loader';
import { FaRegUser } from "react-icons/fa";
import { toast } from 'react-toastify';
import axios from 'axios';

const Dashboard = () => {
    const [userCounter, setUserCounter] = useState({})
    const [isLoader, setIsLoader] = useState(true)

    const AdminDashboard = () => {
        setIsLoader(true)
        axios.get(`${BASEURL}/admin/dashboard/info`, {
            headers: headers()
        }).then((res) => {
            if (res.data?.success === true) {
                setUserCounter(res.data.data);
            }
            setIsLoader(false)
        }).catch((err) => {
            checktoken(err);
            setIsLoader(false)
            if (err?.response?.data?.success === false) {
                toast.error(err.response.data.error);
            }
        })
    }

    useEffect(() => {
        AdminDashboard();
    }, [])

    return (
        <Fragment>
            {isLoader === true && <Loader />}
            <Toaster />
            <Container>
                <Row>
                    <Col md={3}>
                        <Card className="custom-card p-2 position-relative border-danger shadow mb-3 flex-row">
                            <FiUsers className="bg-danger" />
                            <div className="d-inline-block ps-2">
                                <p className="mb-1 capitalize">total users</p>
                                <h4>{userCounter?.users ? userCounter?.users : 0}</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="custom-card p-2 position-relative border-success shadow mb-3 flex-row">
                            <FaRegUser className="bg-success" />
                            <div className="d-inline-block ps-2">
                                <p className="mb-1 capitalize">weekly user</p>
                                <h4>{userCounter?.weeklyUsers ? userCounter?.weeklyUsers : 0}</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="custom-card p-2 position-relative border-warning shadow mb-3 flex-row">
                            <FaRegUser className="bg-warning" />
                            <div className="d-inline-block ps-2">
                                <p className="mb-1 capitalize">monthly user</p>
                                <h4>{userCounter?.monthlyUsers ? userCounter?.monthlyUsers : 0}</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="custom-card p-2 position-relative border-info shadow mb-3 flex-row">
                            <FaRegUser className="bg-info" />
                            <div className="d-inline-block ps-2">
                                <p className="mb-1 capitalize">total author</p>
                                <h4>{userCounter?.authors ? userCounter?.authors : 0}</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="custom-card p-2 position-relative border-danger shadow mb-3 flex-row">
                            <FiUsers className="bg-danger" />
                            <div className="d-inline-block ps-2">
                                <p className="mb-1 capitalize">users From Friends</p>
                                <h4>{userCounter?.friendsUser ? userCounter?.friendsUser : 0}</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="custom-card p-2 position-relative border-success shadow mb-3 flex-row">
                            <FaRegUser className="bg-success" />
                            <div className="d-inline-block ps-2">
                                <p className="mb-1 capitalize">users From YouTube</p>
                                <h4>{userCounter?.youtubeUser ? userCounter?.youtubeUser : 0}</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="custom-card p-2 position-relative border-warning shadow mb-3 flex-row">
                            <FaRegUser className="bg-warning" />
                            <div className="d-inline-block ps-2">
                                <p className="mb-1 capitalize">users Instagram Channel</p>
                                <h4>{userCounter?.instapageUser ? userCounter?.instapageUser : 0}</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="custom-card p-2 position-relative border-info shadow mb-3 flex-row">
                            <FaRegUser className="bg-info" />
                            <div className="d-inline-block ps-2">
                                <p className="mb-1 capitalize">users Instagram Ads</p>
                                <h4>{userCounter?.instaadUser ? userCounter.instaadUser : 0}</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="custom-card p-2 position-relative border-success shadow mb-3 flex-row">
                            <FiDollarSign className="bg-success" />
                            <div className="d-inline-block ps-2">
                                <p className="mb-1 capitalize">users From Internet</p>
                                <h4>{userCounter?.oninternetUser ? userCounter?.oninternetUser : 0}</h4>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="custom-card p-2 position-relative border-info shadow mb-3 flex-row">
                            <FaRegUser className="bg-info" />
                            <div className="d-inline-block ps-2">
                                <p className="mb-1 capitalize">users From others</p>
                                <h4>{userCounter?.otherUser ? userCounter.otherUser : 0}</h4>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default Dashboard;