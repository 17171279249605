import { DeviceRequest, addDevice } from "./redux/slices/DeviceSlice";
import AdminLayout from "./components/admin/AdminLayout";
import { useDispatch, useSelector } from "react-redux";
import ChatLayout from "./components/chat/ChatLayout";
import { useEffect, useState, useMemo } from "react";
import ProtectedRoute from "./utils/ProtectedRoute";
import FrontendRoute from "./router/FrontendRoute";
import Layout from "./components/frontend/Layout";
import { Route, Routes } from "react-router-dom";
import NotFound from "./pages/frontend/NotFound";
import { TourProvider } from "@reactour/tour";
import AdminRoute from "./router/AdminRoute";
import ChatRoute from "./router/ChatRoute";
import { Cookies } from "react-cookie";
import Landing from "./pages/frontend/Landing/Landing";

function App() {
  const cookies = new Cookies();
  const radius = 10;
  const dispatch = useDispatch();
  const { data: deviceid } = useSelector((state) => state.DeviceSlice);
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [
    {
      selector: ".first-step",
      content:
        "ExecutiveGPT is your friendly and powerful tool to assist you with Everyday updates of website.",
    },
    {
      selector: ".second-step",
      content:
        "The articles list offers you sophisticated and weekly-updated articles for all use cases.",
    },
    {
      selector: ".third-step",
      content:
        "The Doc Reader feature gives your content a personal touch and matches your unique style effortlessly",
    },
    {
      selector: ".fourth-step",
      content:
        "The Doc Reader feature gives your content a personal touch and matches your unique style effortlessly.",
    },
    {
      selector: ".fifth-step",
      content: "You can translate your content.",
    },
    {
      selector: ".sixth-step",
      content: "You can rephrase your content.",
    },
    {
      selector: ".seventh-step",
      content: "Generate the different type of email templates.",
    },
  ];
  console.log("ChatRoute.../", ChatRoute);
  const clearCacheData = async () => {
    const allCaches = await caches.keys();
    allCaches?.length > 0 &&
      allCaches.forEach((name) => {
        caches.delete(name);
      });
  };

  useMemo(() => {
    localStorage.setItem("device", deviceid);
    return deviceid;
  }, [deviceid]);

  useEffect(() => {
    console.log(cookies);
    const device = localStorage.getItem("device");
    if (device === "null" || device === "undefined") {
      dispatch(DeviceRequest());
    } else {
      dispatch(addDevice(device));
    }
    clearCacheData();
    window.scrollTo(0, 0);
    //eslint-disable-next-line
  }, []);

  return (
    <div className="App">
      <TourProvider
        steps={steps}
        startAt={0}
        currentStep={currentStep}
        badgeContent={({ totalSteps, currentStep }) =>
          currentStep + 1 + "/" + totalSteps
        }
        setCurrentStep={() => {
          if (currentStep === steps.length - 1) {
            setCurrentStep(0);
          } else {
            setCurrentStep(currentStep + 1);
          }
        }}
        beforeClose={() => setCurrentStep(0)}
        onClickClose={({ setIsOpen }) => {
          setIsOpen(false);
        }}
        onClickMask={({ setIsOpen }) => {
          setIsOpen(false);
        }}
        styles={{
          popover: (base) => ({
            ...base,
            "--reactour-accent": "#2d6a4f",
            borderRadius: radius,
            left: 30,
          }),
          maskArea: (base) => ({ ...base, rx: radius }),
          maskWrapper: (base) => ({ ...base, color: "#2d6a4f" }),
          badge: (base) => ({ ...base, left: "auto", right: "-0.8125em" }),
          controls: (base) => ({ ...base, marginTop: 100 }),
          close: (base) => ({
            ...base,
            right: "auto",
            left: 8,
            top: 8,
            content: "skip",
          }),
        }}
      >
        <Routes>
          <Route path="/landing" element={<Landing />} />

          <Route path="/" element={<Layout />}>
            {FrontendRoute &&
              FrontendRoute.map((item, index) => (
                <Route key={index} path={item.path} element={item.component} />
              ))}
          </Route>
          <Route path="/admin" element={<AdminLayout />}>
            {AdminRoute &&
              AdminRoute.map((item, index) => (
                <Route
                  key={index}
                  path={item.path}
                  element={<ProtectedRoute>{item.component}</ProtectedRoute>}
                />
              ))}
          </Route>
          <Route path="/user/chat" element={<ChatLayout />}>
            {ChatRoute &&
              ChatRoute.map((item, index) => (
                <Route
                  key={index}
                  path={item.path}
                  element={<ProtectedRoute>{item.component}</ProtectedRoute>}
                />
              ))}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </TourProvider>
    </div>
  );
}

export default App;
