import { HomeVideoOnLoading, HomeVideoOnSuccess } from "../slices/HomeVideoSlice"
import { call, put, takeEvery } from "redux-saga/effects"
import { BASEURL, headers } from "../../utils/helper"
import { GET_HOME_VIDEOS } from "./sagaConst"
import axios from "axios";

function* getHomeVideoReq({ payload }) {
    try {
        yield put(HomeVideoOnLoading())
        const response = yield call(axios.get, `${BASEURL}/home/video/content`,
            { headers: headers() }
        )
        yield put(HomeVideoOnSuccess(response?.data?.data))
    } catch (error) {
        console.log(error);
    }
}

const watchHomeVideoSaga = function* () {
    yield takeEvery(GET_HOME_VIDEOS, getHomeVideoReq)
}

export default watchHomeVideoSaga;