import { headers, BASEURL, checktoken } from '../../utils/helper';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const WordCountRequest = createAsyncThunk('WordCountRequest', async () => {
    try {
        const response = await axios.get(`${BASEURL}/admin/counter`, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const CounterSlice = createSlice({
    name: 'wordcounter',
    initialState: {
        data: 0,
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers(builder) {
        builder.addCase(WordCountRequest.pending, (state, action) => {
            state.loading = true
            state.data = 0
            return state
        })
        builder.addCase(WordCountRequest.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            return state
        })
        builder.addCase(WordCountRequest.rejected, (state, action) => {
            state.loading = false
            state.data = 0
            state.error = 'Error occured'
        })
        builder.addCase('LOGOUT', () => this.initialState)
    }
})

export default CounterSlice.reducer
