import { DialogContent, DialogTitle, Button } from '@mui/material';
import { BASEURL, checktoken, headers } from '../../utils/helper';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { forwardRef, Fragment, useState } from 'react';
import ButtonSpinner from './ButtonSpinner';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Toaster from './Toaster';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { TeamMemberReq } from '../../redux/slices/TeamMemberSlice';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const MemberMail = ({ open, handleClose }) => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [spinner, setSpinner] = useState(false)

    const AddMember = (e) => {
        setSpinner(true);
        e.preventDefault();
        axios.post(`${BASEURL}/user/member/create`, { email }, {
            headers: headers()
        }).then((res) => {
            if (res.data.success === true) {
                setEmail('')
                setSpinner(false);
                dispatch(TeamMemberReq())
                toast.success(res.data.msg);
            }
        }).catch((err) => {
            checktoken(err)
            setSpinner(false);
            if (err.response.data.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    return (
        <Fragment>
            <Toaster />
            <Dialog
                maxWidth={'sm'}
                fullWidth
                open={open}
                keepMounted
                onClose={handleClose}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="text-center ff-lato">Add Member</DialogTitle>
                <DialogContent>
                    <Form onSubmit={AddMember}>
                        <Form.Group className='input-field mb-3'>
                            <Form.Control type="text" name="email" className="custom-field" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='input-field text-center'>
                            <Button type="submit" className="primary-blue-btn ff-lato me-2"><GroupAddIcon className='me-2' /> Add Member {spinner === true && <ButtonSpinner />}</Button>
                        </Form.Group>
                    </Form>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default MemberMail;