import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASEURL } from '../../utils/helper';
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import axios from "axios";

export const handleFileUpload = createAsyncThunk("handleFileUpload", async ({ files, id }) => {
    const cookies = new Cookies()
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
        formData.append(`file`, files[i]);
    }
    formData.append(`project`, id);
    const response = axios.post(`${BASEURL}/user/upload/files`, formData, {
        headers: {
            'Content-Type': "multipart/form-data",
            'Authorization': `Bearer ${cookies.get('auth')}`,
        }
    }).then((res) => {
        return res.data;
    }).catch((err) => {
        return err;
    })
    return response;
})

const initialState = {
    data: {}
}
const FileSlice = createSlice({
    name: "FileSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(handleFileUpload.pending, (state, action) => {
            return {
                ...state,
                loading: true,
                data: {},
            }
        })
        builder.addCase(handleFileUpload.fulfilled, (state, action) => {
            if (action?.payload?.success === true) {
                toast.success(action.payload.msg)
                return {
                    ...state,
                    loading: false,
                    data: action.payload.data,
                }
            }
            if (action?.payload?.response?.data.success === false) {
                toast.error(action?.payload?.response?.data?.error)
                return {
                    ...state,
                    loading: false,
                    data: {},
                }
            }
        })
        builder.addCase(handleFileUpload.rejected, (state, action) => {
            return {
                ...state,
                loading: false,
                data: {},
            }
        })
        builder.addCase('LOGOUT', () => this.initialState)
    }
})

export default FileSlice.reducer;