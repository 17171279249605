import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const RephraseProjectRequest = createAsyncThunk(
  "RephraseProjectRequest",
  async (type) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/latest/project?type=${type}`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const CreateRephraseProject = createAsyncThunk(
  "CreateRephraseProject",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/project/create`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const SaveRephraseHistory = createAsyncThunk(
  "SaveRephraseHistory",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/create/chat/history`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const RephraseProjectSlice = createSlice({
  name: "RephraseProject",
  initialState: {
    data: {},
    error: null,
    loading: false,
  },
  reducers: {
    AddRephraseProject: (state, action) => {
      state.data = action.payload;
      return state;
    },
    DeleteRephraseProject: (state, action) => {
      state.data = {};
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(RephraseProjectRequest.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(RephraseProjectRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(RephraseProjectRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase(CreateRephraseProject.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(CreateRephraseProject.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(CreateRephraseProject.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export const { AddRephraseProject, DeleteRephraseProject } =
  RephraseProjectSlice.actions;
export default RephraseProjectSlice.reducer;
