// TextToVideoSlice

import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const TextToVideoProjectRequest = createAsyncThunk(
  "TextToVideoProjectRequest",
  async (type) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/latest/project?type=${type}`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const CreateTextToVideoProject = createAsyncThunk(
  "CreateTextToVideoProject",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/project/create`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const SaveTextToVideoHistory = createAsyncThunk(
  "SaveTextToVideoHistory",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/create/chat/history`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const TextToVideoSlice = createSlice({
  name: "TextToVideoProject",
  initialState: {
    data: {},
    error: null,
    loading: false,
  },
  reducers: {
    AddTextToVideoProject: (state, action) => {
      state.data = action.payload;
      return state;
    },
    DeleteTextToVideoProject: (state, action) => {
      state.data = {};
    },
  },
  extraReducers(builder) {
    builder.addCase(TextToVideoProjectRequest.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(TextToVideoProjectRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(TextToVideoProjectRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase(CreateTextToVideoProject.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(CreateTextToVideoProject.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(CreateTextToVideoProject.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export const { AddTextToVideoProject, DeleteTextToVideoProject } =
  TextToVideoSlice.actions;
export default TextToVideoSlice.reducer;
