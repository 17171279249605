import { createSlice } from '@reduxjs/toolkit'
const initialState = {}

export const HomeVideoSlice = createSlice({
    name: 'homevideo',
    initialState,
    reducers: {
        // Single-video-content
        HomeVideoOnLoading: (state, { payload }) => {
            return {
                ...state,
                loading: true,
                data: [],
                error: null
            }
        },
        HomeVideoOnSuccess: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null
            }
        },
        HomeVideoOnFailure: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: [],
                error: payload
            }
        },
        // end reducers
    },
})

export const {
    HomeVideoOnLoading,
    HomeVideoOnSuccess,
    HomeVideoOnFailure,
} = HomeVideoSlice.actions

export default HomeVideoSlice.reducer