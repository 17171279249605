import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const ProjectRequest = createAsyncThunk(
  "ProjectRequest",
  async (type) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/latest/project?type=${type}`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const ChatProjectCreateReq = createAsyncThunk(
  "ChatProjectCreateReq",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/project/create`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const ChatProjectSlice = createSlice({
  name: "ChatProject",
  initialState: {
    data: {},
    error: null,
    loading: false,
  },
  reducers: {
    AddChatProject: (state, action) => {
      state.data = action.payload;
      return state;
    },
    RemoveProject: (state, action) => {
      state.data = action.payload;
      return state;
    },
    DeleteChatProject: (state, action) => {
      state.data = {};
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(ProjectRequest.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(ProjectRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(ProjectRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase(ChatProjectCreateReq.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(ChatProjectCreateReq.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(ChatProjectCreateReq.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export const { AddChatProject, RemoveProject, DeleteChatProject } =
  ChatProjectSlice.actions;
export default ChatProjectSlice.reducer;
