import { Stack, Skeleton } from '@mui/material';

const FormSkelton = () => {
    return (
        <Stack spacing={2}>
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} width={150} />
        </Stack>
    );
}

export default FormSkelton;