import ButtonSpinner from '../../../components/common/ButtonSpinner';
import { BASEURL, checktoken, headers } from '../../../utils/helper';
import { Col, Container, Form, Card } from 'react-bootstrap';
import Toaster from '../../../components/common/Toaster';
import Loader from '../../../components/common/Loader';
import { useNavigate, Link, useParams } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios'

const EditAdmin = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [firstName, setFirstName] = useState('');

  const handleSubmit = (e) => {
    setSpinner(true);
    e.preventDefault();
    const formData = {
      email: email,
      lastname: lastName,
      firstname: firstName,
    }
    axios.put(`${BASEURL}/admin/adminupdate/${id}`, formData, {
      headers: headers()
    }).then((res) => {
      setSpinner(false);
      if (res.data.success === true) {
        navigate('/admin/list');
      }
    }).catch((err) => {
      checktoken(err)
      setSpinner(false);
      if (err?.response?.data?.error !== undefined) {
        toast.error(err.response.data.error);
      }
    })
  }

  const AdminInfo = (id) => {
    setIsLoader(true);
    axios.get(`${BASEURL}/admin/adminedit/${id}`, {
      headers: headers()
    }).then((res) => {
      if (res.data.success === true) {
        setEmail(res?.data?.data?.email ? res?.data?.data?.email : '')
        setLastName(res?.data?.data?.lastname ? res?.data?.data?.lastname : '')
        setFirstName(res?.data?.data?.firstname ? res?.data?.data?.firstname : '')
        // navigate('/admin/list');
      }
      setIsLoader(false);
    }).catch((err) => {
      checktoken(err)
      setIsLoader(false);
      if (err?.response?.data?.error !== undefined) {
        toast.error(err.response.data.error);
      }
    })
  }

  useEffect(() => {
    AdminInfo(id)

    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      {isLoader === true && <Loader />}
      <Toaster />
      <Card className='shadow-sm border-0'>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <Card.Title className="my-2">udpate Admin information</Card.Title>
          <div>
            <Button
              size="small"
              LinkComponent={Link}
              to="/admin/list"
              startIcon={<ListIcon />}
              className="bg-green1-main hover:bg-green1-dark p-2 text-white"
            >admins</Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Col md={4} className='m-auto'>
            <Form onSubmit={handleSubmit}>
              <Form.Group className='input-field'>
                <Form.Control type="text" name="fName" className="custom-field" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </Form.Group>
              <Form.Group className='input-field mt-3'>
                <Form.Control type="text" name="lName" className="custom-field" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </Form.Group>
              <Form.Group className='input-field mt-3'>
                <Form.Control type="email" name="email" placeholder="Enter email" className="custom-field" value={email} onChange={(e) => { setEmail(e.target.value) }} />
              </Form.Group>

              <Form.Group className='mt-3'>
                <Button type="submit" className="bg-green1-main hover:bg-green1-dark p-2 text-white"
                >update {spinner === true && <ButtonSpinner />}</Button>
              </Form.Group>
            </Form>
          </Col>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default EditAdmin;