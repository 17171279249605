import { headers, checktoken } from "../../utils/helper";
import { Box, Drawer, IconButton } from "@mui/material";
import { addAuth } from "../../redux/slices/AuthSlice";
import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assets/js/icons";
import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import ChatSidebar from "./ChatSidebar";
import { Cookies } from "react-cookie";
import Block from "../common/Block";
import Header from "./Header";
import axios from "axios";
import { PopupContext } from "../../context/PopupContext";

const ChatLayout = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cookie = cookies.get("auth");
  const { popupState, setpopupState } = useContext(PopupContext);
  const [open, setOpen] = useState(false);
  const [stop, setStop] = useState(false);
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/admin/verification`, {
        headers: headers(),
      })
      .then((res) => {
        if (res.data?.success === true) {
          if (
            res.data?.data === "user" &&
            res.data?.data !== "admin" &&
            cookie !== null &&
            cookie !== undefined
          ) {
            dispatch(addAuth(res.data.user));
            return true;
          }
          if (
            (res.data?.data === "admin" || res.data?.data === "superadmin") &&
            res.data?.data !== "user" &&
            cookie !== null &&
            cookie !== undefined
          ) {
            return navigate("/admin");
          }
        }
      })
      .catch((err) => {
        checktoken(err);
      });
    setOpen(false);
    //eslint-disable-next-line
  }, [cookie, navigate]);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", (e) => {
      setWidth(e.target.innerWidth);
    });
  }, []);

  return (
    <Fragment>
      {stop && <Block />}
      <Box id="Chat">
        {width > 900 ? (
          <div className="chat-sidebar overflow-auto">
            <ChatSidebar />
          </div>
        ) : (
          <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
            <ChatSidebar closeFun={() => setOpen(false)} />
          </Drawer>
        )}
        <Box
          sx={{
            marginLeft: { md: "250px" },
            width: { md: "calc(100% - 250px)" },
          }}
        >
          <main className="flex  flex-col h-[100vh]">
            <Box
              className="flex bg-white sticky-sm-top top-0"
              id="chat-layout"
              style={{ zIndex: "1" }}
            >
              <IconButton
                className="flex md:hidden self-start position-absolute z-10"
                onClick={() => {
                  setOpen(true);
                }}
              >
                {<icons.MuiMenu />}
              </IconButton>
              <Box className="grow max-h-[100%]">
                <Header />
              </Box>
            </Box>
            <div className="grow">
              {popupState && (
                <div className="absolute z-[99] h-[106vh] inset-0 flex items-center justify-center bg-black bg-opacity-50">
                  <div className="relative bg-white p-6 rounded shadow-lg w-96">
                    <button
                      className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                      onClick={() => setpopupState(false)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18 18 6M6 6l12 12"
                        />
                      </svg>

                      {/* <XIcon className="h-6 w-6" /> */}
                    </button>
                    <div>Please Upgrade to Pro plan</div>
                    <button
                      className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                      onClick={() => {
                        console.log("BUTTONCLICKED");
                        navigate("/pricing");
                        setpopupState(false);
                      }}
                    >
                      Upgrade
                    </button>
                  </div>
                </div>
              )}
              <Outlet />
            </div>
          </main>
        </Box>
      </Box>
    </Fragment>
  );
};

export default ChatLayout;
