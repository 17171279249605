import { headers, BASEURL, checktoken } from '../../utils/helper';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const IsTrialReq = createAsyncThunk('IsTrialReq', async () => {
    try {
        const response = await axios.get(`${BASEURL}/user/check/trial`, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
        return error;
    }
})

const IsTrialSlice = createSlice({
    name: 'IsTrialSlice',
    initialState: {
        data: false,
        error: null,
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(IsTrialReq.pending, (state, action) => {
            state.loading = true
            state.data = false
            return state;
        })
        builder.addCase(IsTrialReq.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            return state;
        })
        builder.addCase(IsTrialReq.rejected, (state, action) => {
            state.loading = false
            state.data = false
            state.error = 'Error occured'
            return state;
        })
    }
})

export default IsTrialSlice.reducer