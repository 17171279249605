import ArticleProjectSlice from "../slices/ArticleProjectSlice";
import TranslationProjectSlice from "../slices/TranslationProjectSlice";
import SocialProjectSlice from "../slices/SocialProjectSlice";
import EmailProjectSlice from "../slices/EmailProjectSlice";
import ImageProjectSlice from "../slices/ImageProjectSlice";
import SaveArticleSlice from "../slices/SaveArticleSlice";
import ChatProjectSlice from "../slices/ChatProjectSlice";
import SingleToneSlice from "../slices/SingleToneSlice";
import DocProjectSlice from "../slices/DocProjectSlice";
import ChatPromptSlice from "../slices/ChatPromptSlice";
import AppProjectSlice from "../slices/AppProjectSlice";
import AppHistorySlice from "../slices/AppHistorySlice";
import UserPromptSlice from "../slices/UserPromptSlice";
import TeamMemberSlice from "../slices/TeamMemberSlice";
import HomeVideoSlice from "../slices/HomeVideoSlice";
import SaveScrapSlice from "../slices/SaveScrapSlice";
import BizReaderSlice from "../slices/BizReaderSlice";
import HRUploadSlice from "../slices/HRUploadSlice";
import FileListSlice from "../slices/FileListSlice";
import { combineReducers } from "@reduxjs/toolkit";
import CounterSlice from "../slices/CounterSlice";
import MessageSlice from "../slices/MessageSlice";
import IsTrialSlice from "../slices/IsTrialSlice";
import ScrapeSlice from "../slices/ScrapeSlice";
import DeviceSlice from "../slices/DeviceSlice";
import MemberSlice from "../slices/MemberSlice";
import ModalSlice from "../slices/ModalSlice";
import AuthSlice from "../slices/AuthSlice";
import ToneSlice from "../slices/ToneSlice";
import FileSlice from "../slices/FileSlice";
import SaveTranslationSlice from "../slices/SaveTranslationSlice";
import RephraseProjectSlice from "../slices/RephraseProjectSlice";
import SaveRephraseSlice from "../slices/SaveRephraseSlice";
import SaveTranscribeImageSlice from "../slices/SaveTranscribeImageSlice";
import TranscribeImageSlice from "../slices/TranscribeImageSlice";
// import TextToVideo from "../../pages/user/chat/TextToVideo";
import TextToVideoSlice from "../slices/TextToVideoSlice";
import SaveTextToVideoSlice from "../slices/SaveTextToVideoSlice";
import ChatFileSlice from "../slices/ChatFileSlice";
const rootReducer = combineReducers({
  AuthSlice,
  FileSlice,
  ChatFileSlice,
  ToneSlice,
  ModalSlice,
  ScrapeSlice,
  DeviceSlice,
  MemberSlice,
  IsTrialSlice,
  MessageSlice,
  CounterSlice,
  HRUploadSlice,
  FileListSlice,
  BizReaderSlice,
  HomeVideoSlice,
  SaveScrapSlice,
  TeamMemberSlice,
  SingleToneSlice,
  ChatPromptSlice,
  DocProjectSlice,
  AppProjectSlice,
  AppHistorySlice,
  UserPromptSlice,
  ChatProjectSlice,
  SaveArticleSlice,
  EmailProjectSlice,
  ImageProjectSlice,
  SocialProjectSlice,
  ArticleProjectSlice,
  TranslationProjectSlice,
  SaveTranslationSlice,
  TranscribeImageSlice,
  SaveTranscribeImageSlice,
  RephraseProjectSlice,
  SaveRephraseSlice,
  TextToVideoSlice,
  SaveTextToVideoSlice,
});

export default rootReducer;
