import {
  ImageChatRequest,
  ImageDownload,
  AddImage,
} from "../../../redux/slices/ImageProjectSlice";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { useState, useEffect, Fragment, useMemo, useRef } from "react";
import { WordCountRequest } from "../../../redux/slices/CounterSlice";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import CustomTooltip from "../../../components/common/CustomTooltip";
import { BASEURL, checktoken, headers } from "../../../utils/helper";
import { IconButton, Grid, Alert, Snackbar } from "@mui/material";
import ChatSkelton from "../../../components/common/ChatSkelton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Toaster from "../../../components/common/Toaster";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch, useSelector } from "react-redux";
import ico from "../../../assets/images/favicon.png";
import ProjectComponent from "./ProjectComponent";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";

const ImageGeneration = () => {
  const chatBlock = useRef();
  const dispatch = useDispatch();
  const [chat, setChat] = useState([]);
  const [title, setTitle] = useState("");
  const [input, setInput] = useState("");
  const [ImgUrl, setImgUrl] = useState("");
  const [Index, setIndex] = useState(null);
  const [height, setHeight] = useState("");
  const [rows, setRows] = useState(1);
  const [spinner, setSpinner] = useState(false);
  const user = useSelector((state) => state.AuthSlice);
  const {
    data: project,
    saveloader,
    chats,
    image,
    imgload,
  } = useSelector((state) => state.ImageProjectSlice);

  const handleSend = async (e, edit) => {
    setSpinner(true);
    e.preventDefault();
    dispatch(WordCountRequest());
    axios
      .post(
        `${BASEURL}/user/image/generator`,
        { input: edit ? title : input, edit, url: ImgUrl },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res?.data?.success) {
          const data = {
            prompt: edit ? title : input,
            type: "image",
            project: project?._id,
            airesponse: res?.data?.data,
            imagedata: res?.data?.imagedata,
          };
          setChat([
            ...chat,
            {
              prompt: input,
              type: "image",
              project: project?._id,
              airesponse: res?.data?.data,
            },
          ]);
          saveHistory(data);
          setInput("");
        }
        setSpinner(false);
        scrollChat();
      })
      .catch((err) => {
        setSpinner(false);
        checktoken(err);
        if (err?.response?.data?.error) {
          toast.error(err.response.data.error);
        }
      });
  };

  const saveHistory = (data) => {
    axios
      .post(`${BASEURL}/user/create/chat/history`, data, { headers: headers() })
      .then((res) => {
        if (res.data.success === true) {
          setChat(res.data.data);
        }
        scrollChat();
      })
      .catch((err) => {
        checktoken(err);
        if (err?.response?.data?.error) {
          toast.error(err.response.data.error);
        }
      });
  };

  const scrollChat = () => {
    setTimeout(function () {
      const objDiv = chatBlock.current;
      if (objDiv !== null && objDiv !== undefined) {
        objDiv.scrollTo({
          top: objDiv.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  };

  const RenameAction = (name, index) => {
    setIndex(index);
    setTitle(name);
  };
  console.log("user.../", user);
  useMemo(() => {
    if (chats?.length > 0) {
      setChat(chats);
      scrollChat();
    } else {
      setChat([]);
    }
    return chat;
  }, [chats]);

  useEffect(() => {
    setHeight(document.getElementById("chat-layout").offsetHeight);
    if (project?._id && user?.currentplan) {
      dispatch(ImageChatRequest({ id: project._id, type: "image" }));
    }
    //eslint-disable-next-line
  }, [project]);

  useEffect(() => {
    scrollChat();
    if (
      user?.currentplan &&
      (user?.currentplan?.plankey === "execs_pro_monthly" ||
        user?.currentplan?.plankey === "execs_pro_semianual" ||
        user?.currentplan?.plankey === "execs_pro_yearly" ||
        user?.currentplan?.plankey === "team_member_monthly" ||
        user?.currentplan?.plankey === "team_member_semi" ||
        user?.currentplan?.plankey === "team_member_annual")
    ) {
      dispatch(WordCountRequest());
    }
    setHeight(document.getElementById("chat-layout").offsetHeight);
  }, []);

  useMemo(() => {
    if (image?.imagedata) {
      const link = document.createElement("a");
      link.href = image?.imagedata;
      link.setAttribute("download", `${new Date().getTime()}.png`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    dispatch(AddImage(null));
    return true;
  }, [image]);

  useEffect(() => {
    // console.log("input.length.../", input.length);
    // console.log("rows../", rows);
    if (input.length >= 0 && input.length < 120) setRows(1);
    else if (input.length >= 120 && input.length < 240) setRows(2);
    else if (input.length >= 240 && input.length < 360) setRows(3);
    else if (input.length >= 360 && input.length < 480) setRows(4);
    else if (input.length >= 480 && input.length < 600) setRows(5);
    else if (input.length > 600) setRows(6);
  }, [input]);

  return (
    <div
      className="flex flex-col"
      style={{ height: `calc(100vh - ${height}px)` }}
    >
      {/* {user?.currentplan?.[0] &&
        (user?.currentplan?.plankey === "ig_monthly" ||
          user?.currentplan?.plankey === "ig_semianual" ||
          user?.currentplan?.plankey === "pro_monthly" ||
          user?.currentplan?.plankey === "pro_semianual") && (
          <ProjectComponent />
        )} */}

      {user?.currentplan?.[0] && <ProjectComponent />}

      <Toaster />
      {imgload && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
          autoHideDuration={6000}
          key={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity="info" variant="filled" sx={{ width: "100%" }}>
            File downloading in progress.
          </Alert>
        </Snackbar>
      )}
      <div
        id="chat-body"
        className="grow chat-block overflow-y-auto h-full p-[20px]"
        ref={chatBlock}
      >
        {saveloader && <ChatSkelton />}
        <Grid
          container
          className={`hide-scroll-bar chat-section ${
            input !== "" ? "flex justify-end flex-col" : ""
          }`}
        >
          {chat?.length > 0 &&
            chat.map((item, index) => {
              return (
                <Fragment key={index}>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start question w-100`}
                  >
                    <div className="d-flex ms-auto flex-grow-1 align-items-start">
                      <div className="d-block">
                        <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2">
                          {user?.firstname?.split("")[0]}
                          {user?.lastname?.split("")[0]}
                        </span>
                      </div>
                      <div
                        className="leading-loose w-100"
                        style={{
                          whiteSpace: "break-spaces",
                          wordBreak: "break-word",
                        }}
                      >
                        <div className="question">{item?.prompt}</div>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start w-100 answer`}
                  >
                    <div className="d-block">
                      <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2 p-1">
                        <img className="w-100 h-100" src={ico} alt="ico" />
                      </span>
                    </div>
                    <div className="d-block flex-grow-1 answer-div">
                      <div className="d-flex flex-grow-1 align-items-end justify-content-between">
                        <div
                          className="text-center position-relative dalle-image"
                          style={{ maxWidth: "400px" }}
                        >
                          <img
                            src={item?.airesponse}
                            alt="image"
                            className="rounded m-auto w-100"
                          />
                          <div className="position-absolute top-0 start-0 end-0 p-2 text-start dalle-image-action">
                            <IconButton
                              onClick={() => {
                                dispatch(ImageDownload({ id: item?._id }));
                              }}
                            >
                              <DownloadIcon />
                            </IconButton>
                          </div>
                        </div>
                        {/* {(Index !== index) &&
                                                <CustomTooltip title="Edit Image" arrow placement='top' content={<IconButton onClick={() => { setImgUrl(item.airesponse); RenameAction(item.prompt, index) }}>
                                                    <EditOutlinedIcon />
                                                </IconButton>} />
                                            } */}
                      </div>
                      {Index === index && (
                        <div className="d-flex pt-3 modify-question">
                          <Form.Control
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                          <button
                            className="btn update-btn ms-1"
                            onClick={() => {
                              setTitle("");
                              setImgUrl("");
                              setIndex(null);
                            }}
                          >
                            cancel
                          </button>
                          <button
                            className="btn update-btn ms-1"
                            onClick={(e) => {
                              handleSend(e, true);
                            }}
                          >
                            send
                          </button>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Fragment>
              );
            })}
        </Grid>
      </div>
      <Grid
        container
        className="chat-actions sticky bottom-0 bg-white px-[20px] pb-[12px]"
        style={{ zIndex: "2" }}
      >
        <Grid
          item
          xs={12}
          md={10}
          className="chat-input-group chat-input-group-custom mx-auto"
        >
          <Form
            onKeyDown={(e) => {
              e.key === "Enter" && !e.shiftKey && e.preventDefault();
            }}
          >
            <Form.Group className="position-relative">
              <Form.Control
                placeholder="Type here to generate image..."
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                onKeyUp={(e) => {
                  e.key === "Enter" &&
                    !e.shiftKey &&
                    e.target.value.trim() !== "" &&
                    handleSend(e, false);
                }}
                value={input}
                as="textarea"
                rows={rows}
              />
              <div className="chat-btns">
                {input.length < 4 ? (
                  <button type="button" disabled className="ochat-btn btn">
                    {spinner ? <ButtonSpinner /> : <ArrowUpwardRoundedIcon />}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn ochat-btn"
                    onClick={(e) => {
                      handleSend(e, false);
                    }}
                  >
                    {spinner ? <ButtonSpinner /> : <ArrowUpwardRoundedIcon />}
                  </button>
                )}
              </div>
            </Form.Group>
          </Form>
        </Grid>
      </Grid>
    </div>
  );
};

export default ImageGeneration;
