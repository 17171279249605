import ButtonSpinner from "../../../components/common/ButtonSpinner";
import { BASEURL, checktoken, headers } from "../../../utils/helper";
import CustomTooltip from "../../../components/common/CustomTooltip";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect, useMemo, useState } from "react";
import Toaster from "../../../components/common/Toaster";
import { useDispatch, useSelector } from "react-redux";
import UpdateIcon from "@mui/icons-material/Update";
import { Button, Grid, Stack } from "@mui/material";
import { icons } from "../../../assets/js/icons";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import axios from "axios";

import {
  AddArticleProject,
  ArticleProjectCreateReq,
  ArticleProjectRequest,
} from "../../../redux/slices/ArticleProjectSlice";
import {
  AddSocialProject,
  SocialProjectCreateReq,
  SocialProjectRequest,
} from "../../../redux/slices/SocialProjectSlice";
import {
  AddChatProject,
  ChatProjectCreateReq,
  ProjectRequest,
} from "../../../redux/slices/ChatProjectSlice";
import {
  AddDocProject,
  DocProjectCreateReq,
  DocProjectRequest,
} from "../../../redux/slices/DocProjectSlice";
import {
  AddEmailProject,
  EmailProjectRequest,
  EmailProjectCreateReq,
} from "../../../redux/slices/EmailProjectSlice";
import {
  AddImageProject,
  ImageProjectRequest,
  ImgProjectCreateReq,
} from "../../../redux/slices/ImageProjectSlice";
import {
  AddHRProject,
  HRProjectCreateReq,
  HRProjectRequest,
} from "../../../redux/slices/HRUploadSlice";

import {
  AddTranslationProject,
  CreateTranslationProject,
  SaveTranslationHistory,
  TranslationProjectRequest,
} from "../../../redux/slices/TranslationProjectSlice";

import {
  RephraseProjectRequest,
  AddRephraseProject,
  CreateRephraseProject,
} from "../../../redux/slices/RephraseProjectSlice";

import {
  AddTextToVideoProject,
  CreateTextToVideoProject,
  SaveTextToVideoHistory,
  TextToVideoProjectRequest,
} from "../../../redux/slices/TextToVideoSlice";

import {
  AddTranscribeImageProject,
  CreateTranscribeImageProject,
  TranscribeImageProjectRequest,
} from "../../../redux/slices/TranscribeImageSlice";
import { resetChatFileSlice } from "../../../redux/slices/ChatFileSlice";

const ProjectComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isSpinner2, setIsSpinner2] = useState(false);
  const [project, setProject] = useState({ title: "Name Your Project" });
  const { data: isProject, loading } = useSelector(
    (state) => state.ChatProjectSlice
  );
  const { data: isTranslationProject, loading: tloading } = useSelector(
    (state) => state.TranslationProjectSlice
  );
  const { data: isRephraseProject, loading: rloading } = useSelector(
    (state) => state.RephraseProjectSlice
  );
  const { data: isDocProject, loading: dloading } = useSelector(
    (state) => state.DocProjectSlice
  );
  const { data: isSocialProject, loading: sloading } = useSelector(
    (state) => state.SocialProjectSlice
  );
  const { data: isArticleProject, loading: aloading } = useSelector(
    (state) => state.ArticleProjectSlice
  );
  const { data: isEmailProject, loading: eloading } = useSelector(
    (state) => state.EmailProjectSlice
  );
  const { data: isHRProject, loading: hloading } = useSelector(
    (state) => state.HRUploadSlice
  );
  const { data: isImgProject, loading: iloading } = useSelector(
    (state) => state.ImageProjectSlice
  );
  const { data: isTranscribeImageProject, loading: tIloading } = useSelector(
    (state) => state.TranscribeImageSlice
  );
  const { data: TextToVideoProject, loading: tVloading } = useSelector(
    (state) => state.TextToVideoSlice
  );

  // console.log("TextToVideoProject.../", TextToVideoProject);

  const CreateProject = () => {
    console.log("CreateProject.../");
    if (pathname === "/user/chat/translator") {
      dispatch(
        CreateTranslationProject({ title: project.title, type: "translation" })
      );
    }
    if (pathname === "/user/chat/TranscribeImage") {
      dispatch(
        CreateTranscribeImageProject({
          title: project.title,
          type: "transcribeImage",
        })
      );
    }
    if (pathname === "/user/chat/rephrase") {
      dispatch(
        CreateRephraseProject({ title: project.title, type: "rephrase" })
      );
    }
    if (pathname === "/user/chat/outside") {
      dispatch(resetChatFileSlice());
      dispatch(ChatProjectCreateReq({ title: project.title, type: "chat" }));
    }
    if (pathname === "/user/chat/articles") {
      dispatch(
        ArticleProjectCreateReq({ title: project.title, type: "article" })
      );
    }
    if (pathname === "/user/chat/scrap-reader") {
      dispatch(DocProjectCreateReq({ title: project.title, type: "doc" }));
    }
    if (pathname === "/user/chat/social-media") {
      dispatch(
        SocialProjectCreateReq({ title: project.title, type: "social" })
      );
    }
    if (pathname === "/user/chat/emails") {
      dispatch(EmailProjectCreateReq({ title: project.title, type: "email" }));
    }
    if (pathname === "/user/chat/hruploads") {
      dispatch(HRProjectCreateReq({ title: project.title, type: "hr" }));
    }
    if (pathname === "/user/chat/image-generator") {
      dispatch(ImgProjectCreateReq({ title: project.title, type: "image" }));
    }
    if (pathname === "/user/chat/TextToVideo") {
      console.log(`pathname === "/user/chat/TextToVideo"`);
      dispatch(
        CreateTextToVideoProject({ title: project.title, type: "textToVideo" })
      );
    }
  };

  const EnterKeyPress = (e, id) => {
    e.keyCode === 13 && UpdateProject(project.title, id);
  };

  const UpdateProject = (title, id) => {
    if (title === "") {
      toast.error("Project name required!");
      return;
    }

    setIsSpinner2(true);
    axios
      .put(
        `${BASEURL}/user/project/update/${id}`,
        { title: title.toLowerCase() },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          setProject(res.data.data);
          toast.success(res.data.msg);
          if (pathname === "/user/chat/rephrase") {
            dispatch(AddRephraseProject(res.data.data));
          }
          if (pathname === "/user/chat/TranscribeImage") {
            dispatch(AddTranscribeImageProject(res.data.data));
          }
          if (pathname === "/user/chat/translator") {
            dispatch(AddTranslationProject(res.data.data));
          }
          if (pathname === "/user/chat/outside") {
            dispatch(AddChatProject(res.data.data));
          }
          if (pathname === "/user/chat/articles") {
            dispatch(AddArticleProject(res.data.data));
          }
          if (pathname === "/user/chat/scrap-reader") {
            dispatch(AddDocProject(res.data.data));
          }
          if (pathname === "/user/chat/social-media") {
            dispatch(AddSocialProject(res.data.data));
          }
          if (pathname === "/user/chat/emails") {
            dispatch(AddEmailProject(res.data.data));
          }
          if (pathname === "/user/chat/hruploads") {
            dispatch(AddHRProject(res.data.data));
          }
          if (pathname === "/user/chat/image-generator") {
            dispatch(AddImageProject(res.data.data));
          }
          if (pathname === "/user/chat/TextToVideo") {
            dispatch(AddTextToVideoProject(res.data.data));
          }
        }
        setIsSpinner2(false);
      })
      .catch((err) => {
        checktoken(err);
        setIsSpinner2(false);
        if (err?.response?.data !== undefined) {
          toast.error(err?.response?.data.error);
        }
      });
  };

  useMemo(() => {
    if (pathname === "/user/chat/outside") {
      if (isProject && Object.keys(isProject).length > 0) {
        setProject(isProject);
      }
    }
    if (pathname === "/user/chat/translator") {
      if (
        isTranslationProject &&
        Object.keys(isTranslationProject).length > 0
      ) {
        setProject(isTranslationProject);
      }
    }
    if (pathname === "/user/chat/TranscribeImage") {
      if (
        isTranscribeImageProject &&
        Object.keys(isTranscribeImageProject).length > 0
      ) {
        setProject(isTranscribeImageProject);
      }
    }
    if (pathname === "/user/chat/rephrase") {
      if (isRephraseProject && Object.keys(isRephraseProject).length > 0) {
        setProject(isRephraseProject);
      }
    }
    if (pathname === "/user/chat/articles") {
      if (isArticleProject && Object.keys(isArticleProject).length > 0) {
        setProject(isArticleProject);
      }
    }
    if (pathname === "/user/chat/scrap-reader") {
      if (isDocProject && Object.keys(isDocProject).length > 0) {
        setProject(isDocProject);
      }
    }
    if (pathname === "/user/chat/social-media") {
      if (isSocialProject && Object.keys(isSocialProject).length > 0) {
        setProject(isSocialProject);
      }
    }
    if (pathname === "/user/chat/emails") {
      if (isEmailProject && Object.keys(isEmailProject).length > 0) {
        setProject(isEmailProject);
      }
    }
    if (pathname.includes("/user/chat/history/hr/")) {
      if (isHRProject && Object.keys(isHRProject).length > 0) {
        setProject(isHRProject);
      }
    }
    if (pathname.includes("/user/chat/history/image/")) {
      if (isImgProject && Object.keys(isImgProject).length > 0) {
        setProject(isImgProject);
      }
    }
    if (pathname === "/user/chat/hruploads") {
      if (isHRProject && Object.keys(isHRProject).length > 0) {
        setProject(isHRProject);
      }
    }
    if (pathname === "/user/chat/image-generator") {
      if (isImgProject && Object.keys(isImgProject).length > 0) {
        setProject(isImgProject);
      }
    }
    if (pathname === "/user/chat/TextToVideo") {
      if (TextToVideoProject && Object.keys(TextToVideoProject).length > 0) {
        setProject(TextToVideoProject);
      }
    }

    return project;
  }, [
    isProject,
    isArticleProject,
    isDocProject,
    isSocialProject,
    isEmailProject,
    isHRProject,
    isImgProject,
    isTranslationProject,
    isTranscribeImageProject,
    isRephraseProject,
    TextToVideoProject,
  ]);

  useEffect(() => {
    if (pathname === "/user/chat/outside") {
      if (isProject && Object.keys(isProject).length > 0) {
        setProject(isProject);
      } else {
        dispatch(ProjectRequest("chat"));
      }
    } else if (pathname === "/user/chat/rephrase") {
      if (isRephraseProject && Object.keys(isRephraseProject).length > 0) {
        setProject(isRephraseProject);
      } else {
        dispatch(RephraseProjectRequest("rephrase"));
      }
    } else if (pathname === "/user/chat/translator") {
      if (
        isTranslationProject &&
        Object.keys(isTranslationProject).length > 0
      ) {
        setProject(isTranslationProject);
      } else {
        dispatch(TranslationProjectRequest("translation"));
      }
    } else if (pathname === "/user/chat/TranscribeImage") {
      if (
        isTranscribeImageProject &&
        Object.keys(isTranscribeImageProject).length > 0
      ) {
        setProject(isTranscribeImageProject);
      } else {
        dispatch(TranscribeImageProjectRequest("transcribeImage"));
      }
    } else if (pathname === "/user/chat/articles") {
      if (isArticleProject && Object.keys(isArticleProject).length > 0) {
        setProject(isArticleProject);
      } else {
        dispatch(ArticleProjectRequest("article"));
      }
    } else if (pathname === "/user/chat/scrap-reader") {
      if (isDocProject && Object.keys(isDocProject).length > 0) {
        setProject(isDocProject);
      } else {
        dispatch(DocProjectRequest("doc"));
      }
    } else if (pathname === "/user/chat/social-media") {
      if (isSocialProject && Object.keys(isSocialProject).length > 0) {
        setProject(isSocialProject);
      } else {
        dispatch(SocialProjectRequest("social"));
      }
    } else if (pathname === "/user/chat/emails") {
      if (isEmailProject && Object.keys(isEmailProject).length > 0) {
        setProject(isEmailProject);
      } else {
        dispatch(EmailProjectRequest("email"));
      }
    } else if (pathname === "/user/chat/hruploads") {
      if (isHRProject && Object.keys(isHRProject).length > 0) {
        setProject(isHRProject);
      } else {
        dispatch(HRProjectRequest("hr"));
      }
    } else if (pathname.includes("/user/chat/history/hr/")) {
      if (isHRProject && Object.keys(isHRProject).length > 0) {
        setProject(isHRProject);
      } else {
        dispatch(HRProjectRequest("hr"));
      }
    } else if (pathname.includes("/user/chat/history/image/")) {
      if (isImgProject && Object.keys(isImgProject).length > 0) {
        setProject(isImgProject);
      } else {
        dispatch(ImageProjectRequest("image"));
      }
    } else if (pathname === "/user/chat/image-generator") {
      if (isImgProject && Object.keys(isImgProject).length > 0) {
        setProject(isImgProject);
      } else {
        dispatch(ImageProjectRequest("image"));
      }
    } else if (pathname === "/user/chat/TextToVideo") {
      if (TextToVideoProject && Object.keys(TextToVideoProject).length > 0) {
        console.log(
          "TextToVideoProject projectComponent.../",
          TextToVideoProject
        );
        setProject(TextToVideoProject);
      } else {
        dispatch(TextToVideoProjectRequest("textToVideo"));
      }
    }
    // eslint-disable-next-line
  }, [navigate]);
  // console.log("project.../", project);
  return (
    <Fragment>
      <Toaster />
      <Grid
        container
        id="chat-header"
        className="user-dashboard-header p-2 z-[50]  border-border border-y-[1px] align-items-center sticky top-0 bg-white start-0 end-0 grow flex-wrap-reverse justify-between"
      >
        <Grid item xs={12} sm={4}>
          <Stack direction="row" className="gap-[8px] justify-content-start">
            <Form.Control
              // className="border-0 ps-0"
              type="text"
              value={project.title}
              className="custom-field"
              // placeholder="Search projects"
              style={{ maxWidth: "300px" }}
              onKeyUp={(e) => {
                EnterKeyPress(e, project._id);
              }}
              onChange={(e) => {
                setProject({ ...project, title: e.target.value });
              }}
              onFocus={(e) => {
                e.target.value = "";
                // setProject({ ...project, title: "" });
              }}
              placehoder="Enter Project name"
              // style={{ maxWidth: "500px" }}
            />
            <CustomTooltip
              // title="پروژه خود را نام گذاری و ذخیره نمایید"
              arrow
              placement="bottom"
              content={
                <Button
                  startIcon={<UpdateIcon />}
                  className="primary-blue-btn ff-lato"
                  onClick={() => {
                    UpdateProject(project.title, project._id);
                  }}
                >
                  {t("save_project_name")}
                  {isSpinner2 === true && <ButtonSpinner />}
                </Button>
              }
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Stack direction="row" className="gap-[8px] justify-content-end">
            {!pathname.includes("/user/chat/history/hr/") &&
              !pathname.includes("/user/chat/history/image/") && (
                <>
                  <CustomTooltip
                    // title="ایجاد پروژه جدید"
                    arrow
                    placement={"bottom"}
                    content={
                      <Button
                        startIcon={<AddIcon />}
                        className="primary-blue-btn ff-lato"
                        onClick={CreateProject}
                      >
                        {t("create_pro_text")}{" "}
                        {(loading ||
                          dloading ||
                          aloading ||
                          sloading ||
                          eloading ||
                          hloading ||
                          tloading ||
                          iloading ||
                          tIloading ||
                          rloading ||
                          tVloading) && <ButtonSpinner />}
                      </Button>
                    }
                  />
                  <CustomTooltip
                    // title="سوابق"
                    arrow
                    placement={"bottom"}
                    content={
                      <Button
                        startIcon={icons.MuiHistory}
                        LinkComponent={Link}
                        to={`/user/chat/projects/${
                          pathname === "/user/chat/outside"
                            ? "chat"
                            : pathname === "/user/chat/articles"
                            ? `article`
                            : pathname === "/user/chat/scrap-reader"
                            ? `doc`
                            : pathname === "/user/chat/social-media"
                            ? `social`
                            : pathname === "/user/chat/emails"
                            ? `email`
                            : pathname === "/user/chat/hruploads"
                            ? `hr`
                            : pathname === "/user/chat/image-generator"
                            ? `image`
                            : pathname === "/user/chat/translator"
                            ? `translation`
                            : pathname === "/user/chat/rephrase"
                            ? `rephrase`
                            : pathname === "/user/chat/TranscribeImage"
                            ? `transcribeImage`
                            : pathname === "/user/chat/TextToVideo"
                            ? `textToVideo`
                            : "/user/chat/projects"
                        }`}
                        className="primary-blue-btn ff-lato"
                      >
                        {t("history_text")}
                      </Button>
                    }
                  />
                </>
              )}{" "}
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ProjectComponent;
