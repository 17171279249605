import { Cookies } from "react-cookie";
import axios from "axios";
const cookies = new Cookies();
export const UPLOAD_CKEDITOR_URL = `${process.env.REACT_APP_API_URL}/admin/file/uploader`;
export const BASEURL = process.env.REACT_APP_API_URL;

export const isUserLogin = Boolean(cookies.get("auth"));

export function headers() {
  return {
    withCredentials: true,
    credentials: "include",
    "Content-Type": "application/json",
    Authorization: `Bearer ${cookies.get("auth")}`,
  };
}

export function checktoken(err) {
  if (err?.response !== undefined) {
    if (
      err.response?.data?.redirect === true &&
      !err.response?.data?.blacklist
    ) {
      cookies.remove("auth");
      if (cookies.get("refreshToken")) {
        console.log("for refresh token", cookies.get("refreshToken"));
        axios
          .post(
            `${BASEURL}/refresh-token`,
            { refreshToken: cookies.get("refreshToken") },
            {
              headers: {
                withCredentials: true,
                credentials: "include",
                "Content-Type": "application/json",
                Authorization: `Bearer ${cookies.get("refreshToken")}`,
              },
            }
          )
          .then((res) => {
            cookies.set("auth", res.data.token, { path: "/" });
            cookies.set("refreshToken", res.data.refreshToken, { path: "/" });
            // window.location.reload();
          })
          .catch((err) => {
            if (err.response?.data?.redirect === true) {
              cookies.remove("auth");
              cookies.remove("refreshToken");
              window.location.assign(err.response.data.location);
            }
          });
      } else {
        console.log("no refresh token");
        window.location.assign(err.response.data.location);
      }
    }

    if (
      err.response?.data?.redirect === true &&
      err.response?.data?.blacklist
    ) {
      console.log(err.response?.data?.blacklist);
      localStorage.setItem(
        "logmsg",
        "You can not access the application in multiple device!"
      );
      console.log("black token");
      cookies.remove("auth");
      cookies.remove("refreshToken");
      window.location.assign(err.response.data.location);
    }
  }
}

export function formatDuration(time) {
  let hrs = 0,
    mins = 0,
    sec = 0;
  hrs = Math.floor(time / 3600);
  mins = Math.floor((time % 3600) / 60);
  sec = Math.floor((time % 3600) % 60);
  return hrs > 0 ? { hrs, mins } : { mins, sec };
}

export const TextToVidLangs = [
  { value: "Dutch", code: "nl", actor: "Tim" },
  { value: "English", code: "en", actor: "Jackson" },
  { value: "French", code: "fr", actor: "Gabriel" },
  { value: "German", code: "de", actor: "Wilbur" },
  { value: "Italian", code: "it", actor: "Bianca" },
  { value: "Japanese", code: "ja", actor: "Takumi" },
  { value: "Spanish", code: "es", actor: "Pedro" },
  // { value: "Italian", code: "it", actor: "Marco" },
  // { value: "French", code: "fr", actor: "Marin" },
  // { value: "Portuguese", code: "pt", actor: "Ines" },
  // { value: "Spanish", code: "es", actor: "Hugo" },
];

export const TextToVidActors = [
  {
    value: "Dutch",
    code: "nl",
    actor: "Tim",
    optionLabel: "Tim - Male",
    audioSample:
      "https://pictory-static.pictorycontent.com/google/samples/nl-NL-Tim.mp3",
  },
  {
    value: "Dutch",
    code: "nl",
    actor: "Laura",
    optionLabel: "Laura - Female",
    audioSample:
      "https://pictory-static.pictorycontent.com/polly/samples/Laura_nl-NL.mp3",
  },
  {
    value: "English",
    code: "en",
    actor: "Jackson",
    optionLabel: "Jackson - Male",
    audioSample:
      "https://pictory-static.pictorycontent.com/google/samples/en-US-Neural2-I_MALE.mp3",
  },
  {
    value: "English",
    code: "en",
    actor: "Kendra",
    optionLabel: "Kendra - Female",
    audioSample:
      "https://pictory-static.pictorycontent.com/polly/samples/Kendra_100_sample.mp3",
  },
  {
    value: "English",
    code: "en",
    actor: "Adeline",
    optionLabel: "Adeline - Female",
    audioSample:
      "https://pictory-static.pictorycontent.com/google/samples/en-US-Neural2-E_FEMALE.mp3",
  },
  {
    value: "English",
    code: "en",
    actor: "Joanna",
    optionLabel: "Joanna - Female",
    audioSample:
      "https://pictory-static.pictorycontent.com/polly/samples/Joanna_100_sample.mp3",
  },
  {
    value: "English",
    code: "en",
    actor: "Arthur",
    optionLabel: "Arthur - Male",
    audioSample:
      "https://pictory-static.pictorycontent.com/google/samples/en-GB-Neural2-B_MALE.mp3",
  },
  {
    value: "English",
    code: "en",
    actor: "James",
    optionLabel: "James - Male",
    audioSample:
      "https://pictory-static.pictorycontent.com/google/samples/en-GB-Neural2-D_MALE.mp3",
  },
  {
    value: "French",
    code: "fr",
    actor: "Léa",
    optionLabel: "Léa - Female",
    audioSample:
      "https://pictory-static.pictorycontent.com/polly/samples/Lea_fr-FR.mp3",
  },
  {
    value: "French",
    code: "fr",
    actor: "Gabriel",
    optionLabel: "Gabriel - Male",
    audioSample:
      "https://pictory-static.pictorycontent.com/google/samples/fr-CA-Gabriel.mp3",
  },
  {
    value: "German",
    code: "de",
    actor: "Wilbur",
    optionLabel: "Wilbur - Male",
    audioSample:
      "https://pictory-static.pictorycontent.com/google/samples/de-DE-Wilbur.mp3",
  },
  {
    value: "Italian",
    code: "it",
    actor: "Bianca",
    optionLabel: "Bianca - Female",
    audioSample:
      "https://pictory-static.pictorycontent.com/polly/samples/Bianca_it-IT.mp3",
  },
  {
    value: "Japanese",
    code: "ja",
    actor: "Takumi",
    optionLabel: "Takumi - Male",
    audioSample:
      "https://pictory-static.pictorycontent.com/polly%2Fsamples%2FTakumi_ja-JP.mp3",
  },
  {
    value: "Spanish",
    code: "es",
    actor: "Pedro",
    optionLabel: "Pedro - Male",
    audioSample:
      "https://pictory-static.pictorycontent.com/polly/samples/Pedro_es-US.mp3",
  },
];

export const languages = [
  { value: "Albanian", code: "al" },
  { value: "Arabic", code: "ar" },
  { value: "Armenian", code: "ar" },
  { value: "Awadhi", code: "ar" },
  { value: "Azerbaijani", code: "ar" },
  { value: "Bashkir", code: "ar" },
  { value: "Basque", code: "ar" },
  { value: "Belarusian", code: "ar" },
  { value: "Bengali", code: "ar" },
  { value: "Bhojpuri", code: "ar" },
  { value: "Bosnian", code: "ar" },
  { value: "Brazilian Portuguese", code: "ar" },
  { value: "Bulgarian", code: "ar" },
  { value: "Cantonese", code: "ar" },
  { value: "Chhattisgarhi", code: "ar" },
  { value: "Chinese", code: "ar" },
  { value: "Danish", code: "ar" },
  { value: "Dutch", code: "ar" },
  { value: "English", code: "en" },
  { value: "Finnish", code: "ar" },
  { value: "French", code: "fr" },
  { value: "Galician", code: "ar" },
  { value: "Georgian", code: "ar" },
  { value: "German", code: "ar" },
  { value: "Greek", code: "ar" },
  { value: "Gujarati", code: "ar" },
  { value: "Haryanvi", code: "ar" },
  { value: "Hindi" },
  { value: "Hungarian", code: "ar" },
  { value: "Indonesian", code: "ar" },
  { value: "Irish", code: "ar" },
  { value: "Italian", code: "ar" },
  { value: "Japanese", code: "ar" },
  { value: "Javanese", code: "ar" },
  { value: "Kannada", code: "ar" },
  { value: "Kashmiri", code: "ar" },
  { value: "Kazakh", code: "ar" },
  { value: "Konkani", code: "ar" },
  { value: "Korean", code: "ar" },
  { value: "Kyrgyz", code: "ar" },
  { value: "Latvian", code: "ar" },
  { value: "Lithuanian", code: "ar" },
  { value: "Macedonian", code: "ar" },
  { value: "Maithili", code: "ar" },
  { value: "Malay", code: "ar" },
  { value: "Maltese", code: "ar" },
  { value: "Mandarin", code: "ar" },
  { value: "Marathi", code: "ar" },
  { value: "Marwari", code: "ar" },
  { value: "Min Nan", code: "ar" },
  { value: "Moldovan", code: "ar" },
  { value: "Mongolian", code: "ar" },
  { value: "Montenegrin", code: "ar" },
  { value: "Nepali", code: "ar" },
  { value: "Norwegian", code: "ar" },
  { value: "Oriya", code: "ar" },
  { value: "Pashto", code: "ar" },
  { value: "Persian (Farsi)", code: "ar" },
  { value: "Polish", code: "ar" },
  { value: "Portuguese", code: "ar" },
  { value: "Punjabi", code: "ar" },
  { value: "Rajasthani", code: "ar" },
  { value: "Romanian", code: "ar" },
  { value: "Russian", code: "ar" },
  { value: "Sanskrit", code: "ar" },
  { value: "Santali", code: "ar" },
  { value: "Serbian", code: "ar" },
  { value: "Sindhi", code: "ar" },
  { value: "Sinhala", code: "ar" },
  { value: "Slovak", code: "ar" },
  { value: "Slovene", code: "ar" },
  { value: "Slovenian", code: "ar" },
  { value: "Ukrainian", code: "ar" },
  { value: "Urdu", code: "ar" },
  { value: "Uzbek", code: "ar" },
  { value: "Vietnamese", code: "ar" },
  { value: "Welsh", code: "ar" },
  { value: "Wu", code: "ar" },
];

const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
  minimumIntegerDigits: 2,
});

const slickSettings = {
  additionalTransfrom: 0,
  arrows: true,
  autoPlaySpeed: 3000,
  centerMode: false,
  className: "py-2",
  dotListClass: "",
  draggable: true,
  focusOnSelect: false,
  infinite: false,
  itemClass: "series-item",
  keyBoardControl: true,
  minimumTouchDrag: 80,
  pauseOnHover: true,
  renderArrowsWhenDisabled: false,
  renderButtonGroupOutside: false,
  renderDotsOutside: false,
  responsive: {
    superLargeDesktop: {
      breakpoint: {
        max: 3000,
        min: 1200,
      },
      items: 4,
      slidesToSlide: 4,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: {
        max: 1200,
        min: 900,
      },
      items: 3,
      slidesToSlide: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: {
        max: 600,
        min: 0,
      },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 900,
        min: 600,
      },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 30,
    },
  },
  rewind: false,
  rewindWithAnimation: false,
  rtl: false,
  shouldResetAutoplay: true,
  showDots: false,
  sliderClass: "",
  slidesToSlide: 4,
  swipeable: true,
};

export function markdownToText(markdown) {
  // Remove headers
  markdown = markdown.replace(/#+\s?(.+)\n/g, "$1\n");
  // Remove emphasis
  markdown = markdown.replace(/(\*|_){1,3}([^*]+)\1{1,3}/g, "$2");
  // Remove strikethrough
  markdown = markdown.replace(/~~(.*?)~~/g, "$1");
  // Remove links
  markdown = markdown.replace(/\[([^\]]+)\]\(([^)]+)\)/g, "$1");
  // Remove images
  markdown = markdown.replace(/!\[([^\]]*)\]\(([^)]+)\)/g, "$1");
  // Remove blockquotes
  markdown = markdown.replace(/>\s(.+)\n/g, "$1\n");
  // Remove unordered lists
  markdown = markdown.replace(/(\*|\+|\-)\s(.+)\n/g, "$2\n");
  // Remove ordered lists
  markdown = markdown.replace(/\d+\.\s(.+)\n/g, "$1\n");
  // Remove inline code
  markdown = markdown.replace(/`([^`]+)`/g, "$1");
  // Remove code blocks
  markdown = markdown.replace(/```([^`]+)```/gs, "$1");
  // Remove horizontal rules
  markdown = markdown.replace(/---/g, "");
  // Remove remaining asterisks and underscores
  markdown = markdown.replace(/[*_]/g, "");

  return markdown;
}

export const convertHTMLToPlainText = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  const walk = (node) => {
    let text = "";
    node.childNodes.forEach((child) => {
      if (child.nodeType === Node.TEXT_NODE) {
        text += child.textContent;
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        if (child.tagName === "BR") {
          text += "\n";
        } else if (child.tagName === "P") {
          text += (text.endsWith("\n") ? "" : "\n\n") + walk(child) + "\n";
        } else if (child.tagName === "DIV") {
          text += (text.endsWith("\n") ? "" : "\n") + walk(child) + "\n";
        } else {
          text += walk(child);
        }
      }
    });
    return text;
  };

  return walk(doc.body)
    .replace(/\n{3,}/g, "\n")
    .trim();
};

export { leadingZeroFormatter, slickSettings };
