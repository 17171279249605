import Logo from '../../assets/images/logo-white.png'
import { Typography } from '@mui/material';

const Loader = () => {
    return (
        <div className="position-fixed top-0 bottom-0 start-0 end-0 d-grid align-items-center justify-content-center loadar-div" style={{ zIndex: 999, background: '#6c0345' }}>
            <div className="flex justify-center flex-col">
                <div className="text-center">
                    <img className='zoom-in-zoom-out' src={Logo} width={150} alt="img" />
                </div>
                <Typography component="p" className="text-4xl text-center font-semibold ff-jost p-7 text-nowrap text-white">TopExecutives</Typography>
            </div>
        </div>
    )
}

export default Loader;