import { headers, checktoken } from '../../utils/helper'
import { Outlet, useNavigate } from 'react-router-dom'
import { addAuth } from '../../redux/slices/AuthSlice'
import { Fragment, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Cookies } from 'react-cookie'
import Sidebar from './Sidebar'
import Header from './Header'
import Footer from './Footer'
import axios from "axios"

const AdminLayout = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cookies = new Cookies();
    const cookie = cookies.get('auth');
    const [user, setUser] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(`${process.env.REACT_APP_API_URL}/admin/verification`, {
            headers: headers()
        }).then((res) => {
            if (res.data?.success === true) {
                setUser(res.data.user);
                dispatch(addAuth(res.data.user))
                if (res.data?.data === 'user' && (res.data?.data !== 'admin' || res.data?.data !== 'superadmin') && cookie !== null && cookie !== undefined) {
                    return navigate('/user/chat/outside');
                }
                if ((res.data?.data === 'admin' || res.data?.data === 'superadmin') && res.data?.data !== 'user' && cookie !== null && cookie !== undefined) {
                    return true;
                }
            }
        }).catch((err) => {
            checktoken(err);
        })

        //eslint-disable-next-line
    }, [cookie, navigate]);

    return (
        <Fragment>
            <div className="page-wrapper">
                <aside className='sidebar-div'>
                    <Sidebar />
                </aside>
                <main className='admin-layout'>
                    <Header user={user} />
                    <div className="page-content">
                        <Outlet context={[user]} />
                    </div>
                    <Footer />
                </main>
            </div>
        </Fragment>
    )
}

export default AdminLayout;