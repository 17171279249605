import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const HRProjectRequest = createAsyncThunk(
  "HRProjectRequest",
  async (type) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/latest/project?type=${type}`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const HRProjectCreateReq = createAsyncThunk(
  "HRProjectCreateReq",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/project/create`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const HRProjectChatReq = createAsyncThunk(
  "HRProjectChatReq",
  async ({ id, type }) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/project/${id}/${type}/prompts`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const HRUploadSlice = createSlice({
  name: "HRUploadProject",
  initialState: {
    data: {},
    chat: [],
    error: null,
    loading: false,
    cloading: false,
  },
  reducers: {
    AddHRProject: (state, action) => {
      state.data = action.payload;
      return state;
    },
    DeleteHRProject: (state, action) => {
      state.data = {};
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(HRProjectRequest.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(HRProjectRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(HRProjectRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase(HRProjectCreateReq.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(HRProjectCreateReq.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(HRProjectCreateReq.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase(HRProjectChatReq.pending, (state, action) => {
      state.cloading = true;
      state.chat = [];
      return state;
    });
    builder.addCase(HRProjectChatReq.fulfilled, (state, action) => {
      state.cloading = false;
      state.chat = action.payload.data;
      return state;
    });
    builder.addCase(HRProjectChatReq.rejected, (state, action) => {
      state.cloading = false;
      state.chat = [];
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export const { AddHRProject, DeleteHRProject } = HRUploadSlice.actions;
export default HRUploadSlice.reducer;
