import { Box, Button, Card, CardContent, Container, Grid, Stack, Typography, Tooltip } from '@mui/material';
import { BASEURL, headers, checktoken } from '../../utils/helper';
import ButtonSpinner from '../../components/common/ButtonSpinner';
import CustomTooltip from '../../components/common/CustomTooltip';
import { addMessage } from '../../redux/slices/MessageSlice';
import { useDispatch, useSelector } from 'react-redux';
import Toaster from '../../components/common/Toaster';
import { useLocation, useNavigate } from 'react-router-dom';
import { icons } from '../../assets/js/icons';
import { Fragment, useState } from 'react';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { Helmet } from "react-helmet";
import axios from 'axios';

const plan = [
    {
        title: { monthly: "Team Member's Monthly", semi: "Team Member's Semi-Annual", annual: "Team Member's Annual" },
        price: { monthly: 64, halfYearly: 354, yearly: 648 },
        ids: { monthly: '665ec93bef1e929ba3396f69', halfYearly: '665ec9dd7ad7578eddf7da0f', yearly: '665eca037c8ddd51acdc6d9a' },
        plankey: { monthly: 'team_member_monthly', halfYearly: 'team_member_semi', yearly: 'team_member_annual' }
    }
]

function MemberPlan() {
    const [tab, setTab] = useState(0)

    return (
        <Fragment>
            <Helmet>
                <title>AI Assistant | Top Executives</title>
                <meta property="og:title" content={'AI Assistant'} />: for SEO
            </Helmet>
            <Box id="Subscriptions" className="bg-gray-100">
                <section className="py-10 border-t-[1px] border-border">
                    <Container>
                        <Box>
                            <Typography variant="h2" className="ff-jost capitalize font-semibold text-5xl text-primary-main text-center">Membership</Typography>
                            <Typography variant="h6" className="font-bold py-2 text-center text-primary-main w-full md:w-5/6 mx-auto ff-lato">“If you don’t have credit card send a message in your own language to the Telegram group of “Top-executives” to be guided on how to pay for your subscription</Typography>
                        </Box>
                        <Box className="flex justify-center py-7">
                            <Box className="shadow-lg px-[20px] py-[10px] rounded-md">
                                <Button className={`tab-btn hover:text-[#fff] hover:bg-[#6c0345] px-3 me-1 rounded-full transition-custom${tab === 0 ? " active" : ""}`} onClick={() => { setTab(0) }}>monthly</Button>
                                <CustomTooltip title="6 Monthly" placement="top" arrow content={<Button className={`tab-btn hover:text-[#fff] hover:bg-[#6c0345] px-3 me-1 rounded-full transition-custom${tab === 1 ? " active" : ""}`} onClick={() => { setTab(1) }}>semi annual</Button>} />
                                <Button className={`tab-btn hover:text-[#fff] hover:bg-[#6c0345] px-3 me-1 rounded-full transition-custom${tab === 2 ? " active" : ""}`} onClick={() => { setTab(2) }}>annual</Button>
                            </Box>
                        </Box>
                        <Box>
                            <Box role="tabpanel" hidden={tab !== 0} id="detail-tabpanel" aria-labelledby="detail-tab">
                                <Grid container spacing={2} className="justify-center">
                                    {plan.map((item, i) => (
                                        <Grid key={`item-${i}`} item >
                                            <PriceCard
                                                name={item?.title?.monthly}
                                                price={item?.price?.monthly}
                                                id={item?.ids?.monthly}
                                                trial={item?.trial}
                                                features={item?.features}
                                                plankey={item?.plankey.monthly}
                                            />
                                        </Grid>
                                    ))
                                    }
                                </Grid>
                            </Box>
                            <Box role="tabpanel" hidden={tab !== 1} id="detail-tabpanel" aria-labelledby="detail-tab">
                                <Grid container spacing={2} className="justify-center">
                                    {plan.map((item, i) => (
                                        <Grid key={`item-${i}`} item >
                                            <PriceCard
                                                name={item?.title?.semi}
                                                price={item?.price?.halfYearly}
                                                id={item?.ids?.halfYearly}
                                                trial={item?.trial}
                                                features={item?.features}
                                                plankey={item?.plankey.halfYearly}
                                            />
                                        </Grid>
                                    ))
                                    }
                                </Grid>
                            </Box>
                            <Box role="tabpanel" hidden={tab !== 2} id="detail-tabpanel" aria-labelledby="detail-tab">
                                <Grid container spacing={2} className="justify-center">
                                    {plan.map((item, i) => (
                                        <Grid key={`item-${i}`} item >
                                            <PriceCard
                                                name={item?.title?.annual}
                                                price={item?.price?.yearly}
                                                id={item?.ids?.yearly}
                                                trial={item?.trial}
                                                features={item?.features}
                                                plankey={item?.plankey.yearly}
                                            />
                                        </Grid>
                                    ))
                                    }
                                </Grid>
                            </Box>
                        </Box>
                    </Container>
                </section>
            </Box>
        </Fragment>
    )
}

const PriceCard = ({ name, price, trial, features, id, plankey }) => {
    const cookies = new Cookies();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isLogin = Boolean(cookies.get("auth"));
    const searchParams = new URLSearchParams(location.search);
    const user_id = searchParams.get('user_id');
    const [spinner, setSpinner] = useState(false);
    const { currentplan } = useSelector(state => state.AuthSlice)
    const { data: istrial } = useSelector(state => state.IsTrialSlice)

    const CreatePaymentLink = (id) => {
        setSpinner(true)
        axios.post(`${BASEURL}/admin/create/payment?user_id=${user_id}`, { id: id }, {
            headers: headers()
        }).then((res) => {
            setSpinner(false)
            if (res.data.success === true) {
                dispatch(addMessage({ msg: 'You all are set!' }))
                window.location.replace(res.data.data.url);
            }
        }).catch((err) => {
            setSpinner(false)
            checktoken(err)
            if (err?.response?.data?.success === false) {
                toast.error(err.response.data.error);
            }
        })
    }

    const upgradePlan = (id) => {
        setSpinner(true)
        axios.post(`${BASEURL}/admin/update/payment`, { id: id }, {
            headers: headers()
        }).then((res) => {
            setSpinner(false)
            if (res.data.success === true) {
                dispatch(addMessage({ msg: 'You all are set!' }))
                navigate('/user')
            }
        }).catch((err) => {
            checktoken(err)
            setSpinner(false)
            if (err?.response?.data?.success === false) {
                toast.error(err.response.data.error);
            }
        })
    }

    return (
        <Card className="border-[2px] max-w-[275px] h-full shadow-md hover:shadow-lg border-border hover:border-primary-light bg-primary-light transition-custom rounded-lg">
            <Toaster />
            <CardContent className="rounded-br-[100px] bg-white h-full">
                <Stack spacing={3} className="min-h-[170px]">
                    <Typography variant="h6" className="text-center ff-jost text-2xl font-bold text-primary-main">{name}</Typography>
                    <Typography variant="body1" className="text-center text-4xl font-semibold text-primary-main ff-lato">
                        <span className="font-normal">$</span>{price.toFixed(2)}</Typography>
                    <Typography variant="body2" style={{ minHeight: '65px' }} className="text-center font-medium text-gray-500 ff-lato">
                        <Typography variant="body2" component="span" className="text-center ff-lato text-primary-main text-base font-semibold">Free trial : </Typography>
                        {trial}</Typography>
                </Stack>
                <Box className="pb-5">
                    {(isLogin && currentplan?.plankey === plankey) ?
                        <Button size="large" className="w-full rounded-full bg-secondary-main hover:bg-secondary-main text-white">Active Plan</Button> :
                        <Button size="large" onClick={() => {
                            if (cookies.get('auth') && !currentplan) {
                                CreatePaymentLink(id)
                            } else if (cookies.get('auth') && currentplan) {
                                CreatePaymentLink(id)
                            } else {
                                navigate(`/login?priceid=${id}`)
                            }
                        }} className="w-full rounded-full bg-primary-light hover:bg-primary-main text-white">
                            {isLogin && (currentplan?.status === 'active') ?
                                'Upgrade' :
                                istrial?.isTrial === true && !currentplan ?
                                    'Buy Now' : 'Get started'} {spinner === true && <ButtonSpinner />}
                        </Button>
                    }
                </Box>
                <Stack spacing={1}>
                    {features && features.map((item, i) => (
                        <Box key={`item-${i}`} className="flex w-full gap-1 text-gray-500">
                            {<icons.MuiTaskAlt className="text-lg" />}
                            <Typography variant="body2" className="font-medium ff-lato">{item}</Typography>
                        </Box>
                    ))
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}

export default MemberPlan;