import { BASEURL, headers, checktoken } from '../../../utils/helper';
import { Alert, IconButton, Button, Tooltip } from '@mui/material';
import { Container, Card, Table } from 'react-bootstrap';
import Toaster from '../../../components/common/Toaster';
import Loader from '../../../components/common/Loader';
import { useEffect, useState, Fragment } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import DOMPurify from 'dompurify';
import swal from 'sweetalert';
import axios from 'axios';

const Prompt = () => {
  // const navigate = useNavigate()
  const { page } = useParams()
  const [prompts, setPrompts] = useState([])
  // const [totalPage, setTotalPage] = useState(0)
  const [isLoader, setIsLoader] = useState(true)
  // const [pagination, setPagination] = useState([])
  // const [currentPage, setCurrentPage] = useState('')

  const PromptList = () => {
    setIsLoader(true)
    axios.get(`${BASEURL}/admin/prompt/list`, {
      headers: headers(),
    }).then((res) => {
      if (res?.data?.success === true) {
        setPrompts(res?.data?.data)
      }
      setIsLoader(false);
    }).catch((err) => {
      checktoken(err);
      setIsLoader(false);
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.error);
      }
    })
  }

  const DeletePrompt = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this content!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.delete(`${BASEURL}/admin/prompt/delete/${id}`, {
          headers: headers()
        }).then((res) => {
          if (res?.data?.success === true) {
            swal(res.data.msg, { icon: "success" });
            PromptList();
          }
        }).catch((err) => {
          checktoken(err);
          if (err?.response?.data?.success === false) {
            toast.error(err.response.data.error);
          }
        })
      } else { swal("Your Content is safe!") }
    });
  }

  useEffect(() => {
    PromptList()
  }, [])

  return (
    <Container>
      {isLoader === true && <Loader />}
      <Toaster />
      <Card className='shadow-sm border-0'>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <Card.Title className="my-2">Featured Content</Card.Title>
          <div>
            <Button
              size="small"
              startIcon={<AddIcon />}
              LinkComponent={Link}
              to="/admin/add/prompt"
              className="bg-green1-main hover:bg-green1-dark p-2 text-white"
            >add Featured Content</Button>
          </div>
        </Card.Header>
        <Card.Body>
          {
            prompts.length > 0 ?
              <Fragment>
                <div className="d-block table-responsive">
                  <Table striped bordered hover className="m-0 custom-tbl">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Created at</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        prompts.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {page > 1 ?
                                  (10 * page) + (index - 9) : index + 1
                                }
                              </td>
                              <td> {item.title?.length > 30 ? item.title?.slice(0, 30) + '...' : item.title}</td>
                              <td>
                                <div className="question" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.description?.length > 30 ? item.description?.slice(0, 30) + '...' : item.description) }} /></td>
                              <td>{new Date(item.createdAt).toDateString()}</td>
                              <td>
                                <Tooltip title="Edit" arrow placement="bottom-start">
                                  <IconButton LinkComponent={Link} to={`/admin/prompt/edit/${item._id}`} size="small">
                                    <EditIcon className="text-green1-main hover:text-green1-dark" fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete" arrow placement="bottom-start">
                                  <IconButton onClick={() => { DeletePrompt(item._id) }} size="small">
                                    <DeleteIcon className="text-red-600 hover:text-red-700" fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </div>
                {/* {pagination.length > 1 &&
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    <Pagination count={totalPage} page={+currentPage} defaultPage={1} onChange={(e, value) => navigate(`/admin/prompts/${value}`)} />
                  </div>
                } */}
              </Fragment> : <Alert variant="filled" severity="error">Featured Content does not exist!</Alert>
          }
        </Card.Body>
      </Card>
    </Container >
  )
}

export default Prompt;