import { checktoken, BASEURL, UPLOAD_CKEDITOR_URL } from '../../../utils/helper';
import ButtonSpinner from '../../../components/common/ButtonSpinner';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Toaster from '../../../components/common/Toaster';
import Loader from '../../../components/common/Loader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ListIcon from '@mui/icons-material/List';
import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
import axios from 'axios'

const AddPrompt = () => {
  const cookies = new Cookies()
  const navigate = useNavigate()
  const [title, setTitle] = useState('')
  const [video, setVideo] = useState('')
  const [spinner, setSpinner] = useState(false)
  const [isLoader, setIsLoader] = useState(true)
  const [description, setDescription] = useState('')

  const handleSubmit = (e) => {
    setSpinner(true)
    e.preventDefault()
    const formData = new FormData();
    formData.append('video', video);
    formData.append('title', title);
    formData.append('description', description);
    axios.post(`${BASEURL}/admin/prompt/create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${cookies.get('auth')}`,
      },
    }).then((res) => {
      if (res?.data?.success === true) {
        navigate('/admin/prompts');
      }
      setSpinner(false);
    }).catch((err) => {
      checktoken(err);
      setSpinner(false);
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.error);
      }
    })
  }

  useEffect(() => {
    setIsLoader(false)
  }, [])

  return (
    <Container>
      {isLoader === true && <Loader />}
      <Toaster /><Card className='shadow-sm border-0'>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <Card.Title className="my-2">add featured content information</Card.Title>
          <Button
            size="small"
            to="/admin/prompts"
            LinkComponent={Link}
            startIcon={<ListIcon />}
            className="bg-green1-main hover:bg-green1-dark p-2 text-white"
          >featured contents</Button>
        </Card.Header>
        <Card.Body>
          <Form className="mb-3" encType='multipart/form-data' onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group className="input-field mb-3">
                  <Form.Control type="text" name="title" className="custom-field" placeholder="Enter title" value={title} onChange={(e) => { setTitle(e.target.value) }} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="input-field mb-3">
                  <div className="file-input position-relative">
                    <input type="file" className="file-input__input start-0 end-0 top-0 bottom-0" accept='video/*, audio/*' onChange={(e) => { setVideo(e.target.files[0]); console.log(e.target.files[0]); e.target.value = ''; }} />
                    <label className="file-input__label" htmlFor="file-input">
                      <CloudUploadIcon />
                      <span>Upload video/audio</span>
                    </label>
                  </div>
                </Form.Group>
              </Col>
              <Col sm={12}>
                <Form.Group className="input-field mb-3">
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    config={{
                      ckfinder: {
                        uploadUrl: UPLOAD_CKEDITOR_URL
                      },
                      mediaEmbed: {
                        previewsInData: true,
                        elementName: 'iframe',
                      },
                      link: {
                        addTargetToExternalLinks: true,
                      },
                      image: {
                        resizeUnit: 'px',
                        resizeOptions: [{
                          name: 'resizeImage:original',
                          value: null,
                          label: 'Original size'
                        },
                        {
                          name: 'resizeImage:50',
                          value: '50',
                          label: 'Medium size'
                        },
                        {
                          name: 'resizeImage:75',
                          value: '75',
                          label: 'Large size'
                        }]
                      },
                    }}
                    onChange={(event, editor) => {
                      setDescription(editor?.getData())
                    }}
                  />
                </Form.Group>
              </Col>
              {console.log()}
              {video !== '' && <div className="d-block border rounded p-2 position-relative my-3">
                <IconButton size="small" className="text-white position-absolute top-0 start-100 translate-middle bg-danger" onClick={() => { setVideo('') }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
                {video?.type.split('/')[0] === 'video' &&
                  <video controls width="100%" className="p-0">
                    <source src={URL.createObjectURL(video)} type="video/mp4" />
                    <source src={URL.createObjectURL(video)} type="video/ogg" />
                  </video>
                }
                {video?.type.split('/')[0] === 'audio' &&
                  <audio controls width="100%" className="p-0">
                    <source src={URL.createObjectURL(video)} type="audio/ogg" />
                    <source src={URL.createObjectURL(video)} type="audio/mpeg" />
                  </audio>
                }
              </div>}

              <Col sm={12}>
                <Form.Group className="input-field">
                  <Button type="submit" className="bg-green1-main hover:bg-green1-dark p-2 text-white">Submit {spinner === true && <ButtonSpinner />}</Button>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default AddPrompt;