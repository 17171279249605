import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import { BASEURL, checktoken, headers } from '../../../../utils/helper';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Form, Spinner } from 'react-bootstrap';
import { Tab, Tabs } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { toast } from 'react-toastify';
import { useRef, useState } from 'react';
import axios from 'axios';

function FileDetail({ open, onClose, details }) {
  const title = useRef()
  const name = useRef()
  const live = useRef()
  const paused = useRef()
  const [tab, setTab] = useState(0)
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rename, setRename] = useState(false)
  const handleChange = (_, newValue) => {
    setTab(newValue);
  };

  const updateFile = (id) => {
    const formData = {
      name: name.current.value,
      title: title.current.value,
      status: live.current.checked ? 1 : 0
    }
    setLoading(true)
    axios.put(`${BASEURL}/user/train/file/update/${id}`, formData, {
      headers: headers(),
    }).then((res) => {
      setEdit(false)
      setRename(false)
    }).catch((err) => {
      checktoken(err)
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.error);
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <div className="p-3" style={{ width: "400px" }}>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h6 className="fs-4 m-0">File Detail</h6>
          <Button className="p-0 btn-thm-2 rounded" onClick={onClose}><CloseRoundedIcon /></Button>
        </div>

        <div className="mb-4">
          <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example" >
            <Tab label="Details" id="detail-tab" aria-controls="detail-tabpanel" />
            <Tab label="Extract Data" id="extract-tab" aria-controls="extract-tabpanel" />
          </Tabs>
        </div>

        <div role="tabpanel" hidden={tab !== 0} id="detail-tabpanel" aria-labelledby="detail-tab">
          <div className="position-relative edit-file-name mb-4">
            <Form.Control ref={title} defaultValue={details?.title} className="col" disabled={!edit} />
            {!edit &&
              <Button className="p-1 btn-thm-2 rounded" onClick={() => edit ? setEdit(false) : setEdit(true)}>
                <BorderColorRoundedIcon />
              </Button>
            }
            {(!loading && edit) &&
              <Button className="p-1 btn-thm-2 rounded" onClick={() => updateFile(details?._id)}>Save</Button>
            }
            {(loading && edit) &&
              <Button className="btn-thm-2 rounded" style={{ padding: "4px 8px" }}><Spinner size="sm" /></Button>
            }
          </div>
          <div className="position-relative edit-file-name mb-4">
            <Form.Control ref={name} defaultValue={details?.name} className="col" disabled={!rename} />
            {!rename &&
              <Button className="p-1 btn-thm-2 rounded" onClick={() => rename ? setRename(false) : setRename(true)}><BorderColorRoundedIcon /></Button>
            }
            {(rename) &&
              <Button className="p-1 btn-thm-2 rounded" onClick={() => updateFile(details?._id)}>Save</Button>
            }
          </div>

          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="col">
              <p className="fw-bold">Status</p>
              <span style={{ fontSize: "12px" }}>Move to Paused to temporarily pause file usage in Sources</span>
            </div>
            <div className="status-btn border rounded p-1">
              <Form.Check
                inline
                label="Live"
                name="status"
                type="radio"
                value={1}
                ref={live}
                id="status-live"
                defaultChecked={details?.status === 1}
              />
              <Form.Check
                inline
                value={0}
                label="Paused"
                name="status"
                type="radio"
                id="status-paused"
                ref={paused}
                defaultChecked={details?.status !== 1}
              />
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="col">
              <p className="fw-bold">Delete This File</p>
              <span style={{ fontSize: "12px" }}>Remove it from the Sources</span>
            </div>
            <Button variant="outline-danger">Delete</Button>
          </div>
        </div>

        <div role="tabpanel" hidden={tab !== 1} id="extract-tabpanel" aria-labelledby="extract-tab">
          Extract Data
        </div>

      </div>
    </Drawer >
  );
}

export default FileDetail;