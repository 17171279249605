import { Stack, Skeleton, Grid } from '@mui/material';

const ChatSkelton = () => {
    return (
        <Grid container>
            <Grid item xs={12} md={10} className="mx-auto">
                <Stack spacing={1}>
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={150} />
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={150} />
                </Stack>
            </Grid>
        </Grid>
    );
}

export default ChatSkelton;