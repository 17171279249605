import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASEURL } from "../../utils/helper";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import axios from "axios";

export const handleChatFileUpload = createAsyncThunk(
  "handleChatFileUpload",
  async ({ files, id }) => {
    const cookies = new Cookies();
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(`file`, files[i]);
    }
    formData.append(`project`, id);
    const response = axios
      .post(`${BASEURL}/user/upload/chat/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${cookies.get("auth")}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
    return response;
  }
);

const initialState = {
  data: {},
  loading: false,
};

const ChatFileSlice = createSlice({
  name: "ChatFileSlice",
  initialState,
  reducers: {
    resetChatFileSlice: (state) => {
      state.data = {}; // Mutating the state directly
      state.loading = false;
    },
    updateChatFileSlice: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleChatFileUpload.pending, (state) => {
      return {
        ...state,
        loading: true,
        data: {},
      };
    });
    builder.addCase(handleChatFileUpload.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        toast.success(action.payload.msg);
        return {
          ...state,
          loading: false,
          data: action.payload.data,
        };
      }
      if (action?.payload?.response?.data.success === false) {
        toast.error(action?.payload?.response?.data?.error);
        return {
          ...state,
          loading: false,
          data: {},
        };
      }
    });
    builder.addCase(handleChatFileUpload.rejected, (state) => {
      return {
        ...state,
        loading: false,
        data: {},
      };
    });
  },
});

export const { resetChatFileSlice, updateChatFileSlice } =
  ChatFileSlice.actions;

export default ChatFileSlice.reducer;
