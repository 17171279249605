import { headers, BASEURL, checktoken } from '../../utils/helper'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const ChatPromptRequest = createAsyncThunk('ChatPromptRequest', async ({ id, type }) => {
    try {
        const response = await axios.get(`${BASEURL}/user/project/${id}/${type}/prompts`, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const SaveChatRequest = createAsyncThunk('SaveChatRequest', async (data) => {
    try {
        const response = await axios.post(`${BASEURL}/user/create/chat/history`, data, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const ChatPromptSlice = createSlice({
    name: 'ChatPrompt',
    initialState: {
        data: [],
        error: null,
        loading: false,
    },
    reducers: {
        addPrompt: (state, action) => {
            state.data = action.payload
            return state
        },
    },
    extraReducers(builder) {
        builder.addCase(ChatPromptRequest.pending, (state, action) => {
            state.loading = true
            state.data = []
            return state
        })
        builder.addCase(ChatPromptRequest.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            return state
        })
        builder.addCase(ChatPromptRequest.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = 'Error occured'
        })
        builder.addCase(SaveChatRequest.pending, (state, action) => {
            state.loading = true
            state.data = []
            return state
        })
        builder.addCase(SaveChatRequest.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            return state
        })
        builder.addCase(SaveChatRequest.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = 'Error occured'
        })
        builder.addCase('LOGOUT', () => this.initialState)
    }
})

export const { addPrompt } = ChatPromptSlice.actions
export default ChatPromptSlice.reducer
