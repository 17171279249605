import ButtonSpinner from '../../components/common/ButtonSpinner';
import { Row, Col, Container, Form } from 'react-bootstrap';
import Toaster from '../../components/common/Toaster';
import { Link, useNavigate } from 'react-router-dom';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import logo from '../../assets/images/logo.png'
import { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
import axios from 'axios'

const AdminLogin = () => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e) => {
        setSpinner(true);
        e.preventDefault();
        const formData = { email: email, password: password }
        axios.post(`${process.env.REACT_APP_API_URL}/admin/login`, formData, {
            withCredentials: true,
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            setSpinner(false);
            if (res.data.success === true) {
                cookies.set('auth', res.data.token, { path: '/' });
                navigate('/admin');
            }
            if (res.data.success === false) {
                toast.error(res.data.error);
            }
        }).catch((err) => {
            setSpinner(false);
            if (err?.response?.data?.success === false) {
                if (err.response.data.error !== undefined) {
                    toast.error(err.response.data.error);
                }
            }
        })
    }

    useEffect(() => {
        if (cookies.get('auth')) {
            navigate('/admin');
        }
    }, [])

    return (
        <Container style={{ height: "100vh" }}>
            <Toaster />
            <Row className='justify-content-center align-items-center h-100'>
                <Col md={6} lg={4}>
                    <Box className="shadow rounded my-5 p-4">
                        <Form onSubmit={handleSubmit}>
                            <div className="text-center">
                                {/* <img className="object-fit-cover rounded-5 mb-2" style={{ width: "50px", height: "50px" }} src={logo} alt="logo" /> */}
                                <h4>Admin Login</h4>
                            </div>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" placeholder="Enter email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                            </Form.Group>
                            <Form.Group className="mt-3">
                                <Form.Label>Password</Form.Label>
                                <div className="position-relative">
                                    <Form.Control type={showPassword ? 'text' : 'password'} placeholder="password" name="password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                    <span className="password-eyes position-absolute me-2" onClick={() => { setShowPassword(!showPassword) }} style={{ top: "50%", bottom: "auto" }}>
                                        {showPassword ? <FiEyeOff /> : <FiEye />}
                                    </span>
                                </div>
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Link to="/forgot-password" className="link-text forget-text mb-2 d-block">Forgot password?</Link>
                                <Button type="submit" className="w-full bg-green1-main text-white hover:bg-green1-dark" >login {spinner === true && <ButtonSpinner />}</Button>
                            </Form.Group>
                        </Form>
                    </Box>
                </Col>
            </Row>
        </Container>
    )


}

export default AdminLogin;