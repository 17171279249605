import {
  BASEURL,
  checktoken,
  convertHTMLToPlainText,
  headers,
} from "../../../utils/helper";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Toaster from "../../../components/common/Toaster";
import { Button, IconButton } from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import { Fragment, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import ProjectComponent from "./ProjectComponent";
import { useDispatch, useSelector } from "react-redux";
import { SaveRephraseHistory } from "../../../redux/slices/RephraseProjectSlice";
import { SaveRephraseReq } from "../../../redux/slices/SaveRephraseSlice";

const Rephrase = () => {
  const dispatch = useDispatch();
  const [chat, setChat] = useState([]);
  const [limitError, setLimitError] = useState(false);
  const [content, setContent] = useState("");
  const [language, setLanguage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isRephrase, setIsRephrase] = useState(false);
  const [controller, setController] = useState(new AbortController());
  const user = useSelector((state) => state.AuthSlice);
  const { data: project, loading: rloading } = useSelector(
    (state) => state.RephraseProjectSlice
  );
  const handleStopStream = (e) => {
    controller.abort();
    setController(new AbortController());
  };

  const handleCopy = (content) => {
    content
      ? toast.success("Content copied to clipboard!")
      : toast.error("Content not found !");
  };

  const RephraseText = async (e) => {
    e.preventDefault();
    setLimitError(false);

    if (content === "") {
      toast.error("Content must be field!");
      return false;
    }
    setSpinner(true);
    setChat([]);
    try {
      const message = JSON.stringify({ language, content });
      const response = await fetch(`${BASEURL}/user/rephrase/text`, {
        method: "POST",
        signal: controller.signal,
        responseType: "stream",
        headers: headers(),
        body: message,
      });
      if (!response.ok) {
        // const errorResponse = await response.json();
        // setLimitError(true);
        toast.error("Daily limit exceeded");

        setSpinner(false);
        throw new Error("Daily limit exceeded");
      }
      const readData = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      setIsRephrase(true);
      setSpinner(false);
      let aiRes = "";
      while (true) {
        const { done, value } = await readData.read();
        if (done) {
          break;
        }
        aiRes += value;
        setChat([...chat, { airesponse: aiRes }]);
      }

      const savehistory = {
        prompt: content,
        type: "rephrase",
        airesponse: aiRes,
        project: project._id,
      };
      const rephraseHistReq = await dispatch(SaveRephraseHistory(savehistory));

      const saveRephrase = {
        language,
        text: content,
        airesponse: aiRes,
        history_id:
          rephraseHistReq.payload.data[rephraseHistReq.payload.data.length - 1]
            ._id,
      };
      dispatch(SaveRephraseReq(saveRephrase));

      setIsComplete(true);
    } catch (err) {
      checktoken(err);
      setSpinner(false);
      if (err?.response?.data?.error !== undefined) {
        toast.error(err.response.data.error);
      }
    }
  };

  return (
    <div className="h-100">
      {user?.currentplan && <ProjectComponent />}

      <Toaster />
      <Row className="h-100 m-0">
        <div className="btn-thm-1 align-items-center p-3">
          {isRephrase ? (
            <Button
              type="submit"
              className="bg-primary-light hover:bg-primary-main text-white mb-3"
              onClick={(e) => {
                setIsRephrase(false);
                setLanguage("");
                setContent("");
                setIsComplete(false);
                handleStopStream(e);
                setChat([]);
              }}
            >
              reset{" "}
            </Button>
          ) : (
            <Button
              onClick={RephraseText}
              type="submit"
              className="bg-primary-light hover:bg-primary-main text-white"
            >
              rephrase content {spinner === true && <ButtonSpinner />}
            </Button>
          )}
          {limitError && (
            <>
              {/* <br /> */}
              <div className=" mt-3">
                <p className="font-bold text-red-500">Daily limit exceeded</p>
              </div>
            </>
          )}{" "}
        </div>
        <Col sm={12} md={6} className="flex-grow-1 h-100">
          <Form>
            <Form.Group className="mb-3 custom-field">
              <ReactQuill
                theme="bubble"
                placeholder="Write here to translate any text."
                className="custom-field-input"
                value={content}
                onChange={setContent}
              />
              {/* <Form.Control as={'textarea'} type="text" placeholder="Write here to translate any text." className="custom-field-input" rows={10} value={content} onChange={(e) => { setContent(e.target.value) }} /> */}
            </Form.Group>
          </Form>
        </Col>
        <Col sm={12} md={6}>
          {chat?.length > 0 && (
            <div
              className="border rounded p-3"
              style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
            >
              {chat.map((item, index) => (
                <Fragment key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        item.airesponse.replace(`"`, "")
                      ),
                    }}
                  ></div>
                  {isComplete && (
                    <div className="d-flex align-items-center justify-content-end mt-1">
                      <CopyToClipboard
                        text={convertHTMLToPlainText(item.airesponse)}
                      >
                        <IconButton onClick={() => handleCopy(item.airesponse)}>
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      </CopyToClipboard>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Rephrase;
