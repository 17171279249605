import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#ffd700",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#ffd700",
        color: '#000',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        fontWeight: 'bold'
    },
}));

const CustomTooltip = ({ title, placement, content }) => {
    return (
        <LightTooltip title={title} arrow placement={placement}>
            {content}
        </LightTooltip>
    )
}

export default CustomTooltip;