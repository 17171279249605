import { BASEURL, checktoken, headers } from "../../../../utils/helper"
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import TableSkelton from '../../../../components/common/TableSkelton'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { GET_SCRAPE_REQ } from "../../../../redux/saga/sagaConst"
import LinkRoundedIcon from '@mui/icons-material/LinkRounded'
import Toaster from '../../../../components/common/Toaster'
import { Badge, Button, Stack, Alert } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Card, Table, Dropdown, } from 'react-bootstrap'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDispatch, useSelector } from "react-redux"
import ListAltIcon from '@mui/icons-material/ListAlt'
import { ProgressBar } from 'react-loader-spinner'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import FileDetail from './FileDetail'
import axios from 'axios'

const Scrape = () => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [isAlert, setAlert] = useState(false);
	const [fileDetail, setFileDetail] = useState({});
	const { data: files, loading } = useSelector(state => state.ScrapeSlice)

	const TrainFile = (id) => {
		axios.get(`${BASEURL}/user/train/uploaded/file/${id}`, {
			headers: headers(),
		}).then((res) => {
			if (res?.data?.success === true) {
				dispatch({ type: GET_SCRAPE_REQ, payload: "" });
			}
		}).catch((err) => {
			checktoken(err)
		})
	}

	const TrainLink = (id, link) => {
		axios.post(`${BASEURL}/user/scrap/link`, { id, link }, {
			headers: headers(),
		}).then((res) => {
			if (res?.data?.success === true) {
				dispatch({ type: GET_SCRAPE_REQ, payload: "" });
			}
		}).catch((err) => {
			checktoken(err)
		})
	}

	const getSingleTrainFile = (id) => {
		axios.get(`${BASEURL}/user/train/file/info/${id}`, {
			headers: headers(),
		}).then((res) => {
			if (res?.data?.success === true) {
				setFileDetail(res?.data?.data)
			}
		}).catch((err) => {
			checktoken(err)
			if (err?.response?.data?.success === false) {
				toast.error(err.response.data.error);
			}
		})
	}

	const fileDetails = (id) => {
		getSingleTrainFile(id)
		setOpen(true)
	}

	const deleteFile = (id) => {
		axios.delete(`${BASEURL}/user/delete/trained/file/${id}`, {
			headers: headers(),
		}).then((res) => {
			dispatch({ type: GET_SCRAPE_REQ, payload: "" });
			toast.success(res?.data?.msg);
		}).catch((err) => {
			checktoken(err)
			if (err?.response?.data?.success === false) {
				toast.error(err.response.data.error);
			}
		})
	}

	useEffect(() => {
		dispatch({ type: GET_SCRAPE_REQ, payload: "" });
		// eslint-disable-next-line
	}, [open])

	useEffect(() => {
		const notTrained = files?.length && files.filter((item) => item.status === 0)
		if (notTrained?.length > 0) {
			setAlert(true)
			notTrained.forEach((item) => {
				if (item.status === 0 && item.type === 'file') {
					TrainFile(item._id)
				}

				if (item.status === 0 && item.type === 'link') {
					TrainLink(item._id, item.link)
				}
			})
		} else {
			setAlert(false)
		}
	}, [files?.length > 0])

	useEffect(() => { }, [dispatch])

	return (
		<div className="h-100">
			<Toaster />
			<div className="d-block p-3">
				<Card>
					<Card.Header>
						<Card.Title className="flex items-center justify-between m-0">
							<h5>Sources</h5>
							<Stack direction="row" spacing={1}>
								<Button LinkComponent={Link} to="/user/chat/scrapping" startIcon={<ArrowBackIcon />} className="bg-primary-light hover:bg-primary-main text-white">go back</Button>
								<Button LinkComponent={Link} to="/user/chat/scrap-reader" startIcon={<DocumentScannerIcon />} className="bg-primary-light hover:bg-primary-main text-white">reader</Button>
							</Stack>
						</Card.Title>
					</Card.Header>
					<Card.Body>
						{isAlert && <Alert className="mb-2" severity="info">Your document/link is being read by the system and it may take few minutes, please be patient.</Alert>}
						{loading && <TableSkelton />}
						{files &&
							<div className="table-responsive">
								<Table striped bordered hover className="mb-0">
									<thead>
										<tr>
											<th>title</th>
											<th>file type</th>
											<th>status</th>
											<th>CreatedAt</th>
											<th>details</th>
										</tr>
									</thead>
									<tbody>
										{files && files?.map((item, index) => {
											return (
												<tr key={index}>
													<td>
														{(item.type === 'file') &&
															<Link to={item.file} target="_blank" style={{ wordBreak: "break-all" }}>
																{item.name}
															</Link>
														}
														{(item.type === 'link') &&
															<Link to={item.link} target="_blank">
																{item.link}
															</Link>
														}
													</td>
													<td>
														{(item.type === 'file') && <Link>
															<InsertDriveFileIcon />
															<span className="table-file-title d-block">{item?.title?.split('.')[1]}</span>
														</Link>
														}
														{(item.type === 'link') &&
															<LinkRoundedIcon />
														}
													</td>
													<td className="text-center">
														{(item.status === 0) && <ProgressBar
															visible={true}
															height="40"
															width="100"
															color="#036"
															secondaryColor='#036'
															ariaLabel="progress-bar-loading"
															wrapperStyle={{}}
															wrapperClass=""
														/>}
														{item?.status === 1 &&
															<div className="text-center"><Badge sx={{ bgcolor: 'green' }} badgeContent={'Trained'} color="success"></Badge></div>
														}
													</td>
													<td>{new Date(item.createdAt).toDateString()}</td>
													<td>
														<Dropdown>
															<Dropdown.Toggle className="border-0 project-dropdown btn-light p-0" id={`dropdown-basic-${index}`}>
																<MoreVertIcon />
															</Dropdown.Toggle>
															<Dropdown.Menu className="project-abbr border-0 shadow end-0 p-0">
																<Dropdown.Item onClick={() => { deleteFile(item?._id) }} className="d-flex">
																	<DeleteForeverIcon className="me-1" />Delete
																</Dropdown.Item>
																<Dropdown.Item className="d-flex" onClick={() => { fileDetails(item?._id) }}><ListAltIcon className="me-1" />Details</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							</div>
						}
					</Card.Body>
				</Card>
			</div>
			{Object.keys(fileDetail).length > 0 && open &&
				<FileDetail details={fileDetail} open={open} onClose={() => setOpen(false)} />
			}
		</div>
	)
}

export default Scrape;