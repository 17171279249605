import { BASEURL, headers, checktoken } from '../../../utils/helper';
import { Avatar, IconButton, Button, Tooltip } from '@mui/material';
import { Link, useOutletContext } from 'react-router-dom';
import Toaster from '../../../components/common/Toaster';
import { Container, Card, Table } from 'react-bootstrap';
import Loader from '../../../components/common/Loader';
import DeleteIcon from '@mui/icons-material/Delete';
import { icons } from '../../../assets/js/icons';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Alert } from '@mui/material';
import swal from 'sweetalert';
import axios from 'axios';

const { MuiAddPerson } = icons

const Admin = () => {
	const [user] = useOutletContext()
	const [admins, setAdmins] = useState([])
	const [isLoader, setIsLoader] = useState(true)

	const AdminList = () => {
		setIsLoader(true)
		axios.get(`${BASEURL}/admin/adminlist`, {
			headers: headers()
		}).then((res) => {
			if (res?.data?.success === true) {
				setAdmins(res?.data?.data)
			}
			setIsLoader(false)
		}).catch((err) => {
			checktoken(err);
			setAdmins([])
			setIsLoader(false)
			if (err?.response?.data?.success === false) {
				toast.error(err.response.data.error);
			}
		})
	}

	const DeleteAdmin = (id) => {
		swal({
			title: "Are you sure?",
			text: "Once deleted, you will not be able to recover this admin!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				axios.delete(`${BASEURL}/admin/admindelete/${id}`, {
					headers: headers()
				}).then((res) => {
					if (res?.data?.success === true) {
						swal(res.data.msg, { icon: "success" });
						AdminList();
					}
				}).catch((err) => {
					checktoken(err);
					if (err?.response?.data?.success === false) {
						toast.error(err.response.data.error);
					}
				})
			} else { swal("Your Admin is safe!") }
		});
	}

	useEffect(() => {
		AdminList();
	}, [])

	return (
		<Container>
			{isLoader === true && <Loader />}
			<Toaster />
			<Card className="shadow-sm border-none">
				<Card.Header className="flex items-center justify-between">
					<Card.Title className="m-0">Admin List</Card.Title>
					{user.role === 'superadmin' &&
						<Button
							size="small"
							to="/admin/create"
							LinkComponent={Link}
							startIcon={MuiAddPerson}
							className="bg-green1-main hover:bg-green1-dark p-2 text-white"
						>add admin</Button>
					}
				</Card.Header>
				<Card.Body>
					{admins.length > 0 ?
						<div className="block table-responsive">
							<Table striped bordered hover className="m-0 custom-tbl">
								<thead>
									<tr>
										<th>No.</th>
										<th>Thumbnail</th>
										<th>Name</th>
										<th>Email</th>
										<th>Created at</th>
										{user.role === 'superadmin' &&
											<th>Actions</th>
										}
									</tr>
								</thead>
								<tbody>
									{
										admins.map((item, index) => {
											return (
												<tr key={index}>
													<td>{index + 1}</td>
													<td>
														<Avatar alt="img" src={item.thumbnail} sx={{ width: 35, height: 35 }} />
													</td>
													<td>{item?.firstname} {item?.lastname}</td>
													<td className="font-semibold">{item?.email}</td>
													<td>{new Date(item.createdAt).toDateString()}</td>
													{
														user.role === 'superadmin' &&
														<td>
															<Tooltip title="Edit Admin" placement="bottom-start" arrow>
																<IconButton LinkComponent={Link} to={`/admin/list/edit/${item._id}`} size="small">
																	<EditIcon className="text-green1-main hover:text-green1-dark" fontSize="inherit" />
																</IconButton>
															</Tooltip>
															<Tooltip title="Delete Admin" placement="bottom-start" arrow>
																<IconButton size="small" onClick={() => { DeleteAdmin(item._id) }}>
																	<DeleteIcon className="text-red-600 hover:text-red-700" fontSize="inherit" />
																</IconButton>
															</Tooltip>
														</td>
													}
												</tr>
											)
										})
									}
								</tbody>
							</Table>
						</div> :
						<Alert variant="filled" severity="error">
							Admins does not exist!
						</Alert>
					}
				</Card.Body>
			</Card>
		</Container>
	)
}

export default Admin;