import {
  BASEURL,
  checktoken,
  convertHTMLToPlainText,
  headers,
  languages,
} from "../../../utils/helper";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TranslateIcon from "@mui/icons-material/Translate";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Toaster from "../../../components/common/Toaster";
import { Button, IconButton } from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-dropdown-select";
import { Fragment, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import DOMPurify from "dompurify";
import { useDispatch, useSelector } from "react-redux";
import ProjectComponent from "./ProjectComponent";
import { SaveTranslationReq } from "../../../redux/slices/SaveTranslationSlice";
import { SaveTranslationHistory } from "../../../redux/slices/TranslationProjectSlice";
import plusIcon from "../../../assets/images/plus.svg";
import AttachmentIcon from "@mui/icons-material/AttachFile";

import crossIcon from "../../../assets/images/cross-mark.svg";
const Translator = () => {
  const [chat, setChat] = useState([]);
  const [limitError, setLimitError] = useState(false);
  const dispatch = useDispatch();

  const [content, setContent] = useState("");
  const [language, setLanguage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isTranslate, setIsTranslate] = useState(false);
  const [controller, setController] = useState(new AbortController());
  const user = useSelector((state) => state.AuthSlice);
  const { data: project, loading: tloading } = useSelector(
    (state) => state.TranslationProjectSlice
  );

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleStopStream = (e) => {
    controller.abort();
    setController(new AbortController());
  };

  const handleCopy = (content) => {
    content
      ? toast.success("Content copied to clipboard!")
      : toast.error("Content not found !");
  };

  const TranslateText = async (e) => {
    e.preventDefault();
    if (language === "") {
      toast.error("Please select any language!");
      return false;
    }
    if (content === "") {
      toast.error("Content must be field!");
      return false;
    }
    setSpinner(true);
    setChat([]);
    try {
      const message = JSON.stringify({ language, content });
      const response = await fetch(`${BASEURL}/user/translate/text`, {
        method: "POST",
        signal: controller.signal,
        responseType: "stream",
        headers: headers(),
        body: message,
      });

      if (!response.ok) {
        // setLimitError(true);
        toast.error("Daily limit exceeded");
        setSpinner(false);
        throw new Error("Daily limit exceeded");
      }
      const readData = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      setIsTranslate(true);
      setSpinner(false);
      let aiRes = "";
      while (true) {
        const { done, value } = await readData.read();
        if (done) {
          break;
        }
        aiRes += value;
        setChat([...chat, { airesponse: aiRes }]);
      }
      setIsComplete(true);

      const savehistory = {
        prompt: content,
        type: "translation",
        airesponse: aiRes,
        project: project._id,
      };
      const translationHistReq = await dispatch(
        SaveTranslationHistory(savehistory)
      );

      const saveTranslation = {
        language,
        text: content,
        airesponse: aiRes,
        history_id:
          translationHistReq.payload.data[
            translationHistReq.payload.data.length - 1
          ]._id,
      };
      dispatch(SaveTranslationReq(saveTranslation));
    } catch (err) {
      checktoken(err);
      setSpinner(false);
      if (err?.response?.data?.error) {
        toast.error(err.response.data.error);
      }
    }
  };

  const handleExtractText = async (e) => {
    e.preventDefault();
    if (language === "") {
      toast.error("Please select any language!");
      return false;
    }
    if (!selectedImage) {
      toast.error("Please select an image!");
      return false;
    }
    setSpinner(true);
    setChat([]);

    if (selectedImage) {
      const payload = {
        language,
        img: selectedImage,
      };

      try {
        const response = await fetch(`${BASEURL}/user/image/extractText`, {
          method: "POST",
          signal: controller.signal,
          responseType: "stream",
          headers: headers(),
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          setSpinner(false);

          throw new Error("Network response was not ok");
        }

        const reader = response.body.getReader();
        setIsTranslate(true);
        const decoder = new TextDecoder();
        let result = "";
        let done = false;

        while (!done) {
          const { value, done: streamDone } = await reader.read();
          done = streamDone;
          result += decoder.decode(value, { stream: !done });
          setChat([...chat, { airesponse: result }]);
        }
        setIsComplete(true);
        const savehistory = {
          prompt: selectedImage,
          promptType: "image",
          type: "translation",
          airesponse: result,
          project: project._id,
        };
        const translationHistReq = await dispatch(
          SaveTranslationHistory(savehistory)
        );

        const saveTranslation = {
          language,
          text: selectedImage,
          airesponse: result,
          history_id:
            translationHistReq.payload.data[
              translationHistReq.payload.data.length - 1
            ]._id,
        };
        dispatch(SaveTranslationReq(saveTranslation));

        setSpinner(false);

        console.log("Extracted Text:", result);
        // Handle the extracted text as needed
      } catch (error) {
        setSpinner(false);

        console.error("There was a problem with the fetch operation:", error);
      }
    }
  };

  useEffect(() => {
    if (language) {
      setIsTranslate(false);
      // setContent("");
      setIsComplete(false);
      // setSelectedImage(null);
      setChat([]);
      // handleStopStream(e);
    }
  }, [language]);

  return (
    <div className="h-100">
      {user?.currentplan && <ProjectComponent />}
      <Toaster />
      <Row className="h-100 m-0 pt-3 pb-5">
        <div className="mb-3">
          <div className="btn-thm-1 d-flex align-items-center flex-wrap">
            <span>
              <TranslateIcon />
            </span>
            <Select
              className="shadow-sm multiselect-dropdown me-2"
              style={{ width: "200px" }}
              options={languages}
              searchable={true}
              placeholder="Choose a language"
              labelField="value"
              valueField="value"
              onChange={(value) => {
                setLanguage(value.map(({ value }) => value)[0]);
              }}
            />
            {isTranslate ? (
              <Button
                type="submit"
                className="bg-primary-light hover:bg-primary-main text-white"
                onClick={(e) => {
                  setIsTranslate(false);
                  setContent("");
                  setIsComplete(false);
                  setSelectedImage(null);
                  setChat([]);
                  handleStopStream(e);
                }}
              >
                reset{" "}
              </Button>
            ) : (
              <Button
                onClick={(e) => {
                  if (!selectedImage) TranslateText(e);
                  else handleExtractText(e);
                }}
                type="submit"
                className="bg-primary-light hover:bg-primary-main text-white"
              >
                translate {selectedImage && "Image"}{" "}
                {spinner === true && <ButtonSpinner />}
              </Button>
            )}
          </div>
          {limitError && (
            <div className="mt-3">
              <p className="font-bold text-red-500">Daily limit exceeded</p>
            </div>
          )}
        </div>
        {!selectedImage ? (
          <Col sm={12} md={6}>
            <Form>
              <Form.Group className="mb-3 custom-field">
                <ReactQuill
                  theme="bubble"
                  placeholder="Write here to translate any text."
                  className="custom-field-input"
                  value={content}
                  onChange={setContent}
                />
                {/* <Form.Control as={'textarea'} type="text" placeholder="Write here to translate any text." className="custom-field-input" rows={10} value={content} onChange={(e) => { setContent(e.target.value) }} /> */}
              </Form.Group>
            </Form>
          </Col>
        ) : (
          <Col sm={12} md={6}>
            <div className="relative h-full w-full">
              <img
                src={selectedImage}
                alt="Selected"
                className="w-full object-contain h-64"
              />
              <button
                onClick={() => {
                  setIsTranslate(false);
                  setContent("");
                  setIsComplete(false);
                  setSelectedImage(null);
                  setChat([]);
                }}
                className="absolute top-2 right-2 bg-white p-1 rounded-full"
              >
                <img src={crossIcon} alt="Remove" className="h-8" />
              </button>
            </div>
          </Col>
        )}
        {!selectedImage && !isTranslate && (
          <Col sm={12} md={12}>
            <div className="my-3 w-[40%]">
              <p className="font-bold text-2xl text-center">OR</p>
            </div>
          </Col>
        )}
        {!selectedImage && !isTranslate && (
          <Col sm={12} md={6}>
            <div
              className={`border-2 border-gray-300 flex justify-center items-center h-48 custom-field`}
            >
              <div className="">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="hidden"
                  id="imageUpload"
                />
                <label
                  htmlFor="imageUpload"
                  className="cursor-pointer flex gap-2 justify-center items-center"
                >
                  <AttachmentIcon />
                  {/* <img src={plusIcon} className="h-20" alt="plus icon" /> */}
                  <p className="text-lg font-medium">
                    Select a text image to Translate
                  </p>
                </label>
              </div>
            </div>
          </Col>
        )}
        <Col sm={12} md={6}>
          {chat?.length > 0 && (
            <div
              className="border rounded p-3"
              style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
            >
              {chat.map((item, index) => (
                <Fragment key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        item.airesponse.replace(`"`, "")
                      ),
                    }}
                  ></div>
                  {isComplete && (
                    <div className="d-flex align-items-center justify-content-end mt-1">
                      <CopyToClipboard
                        text={convertHTMLToPlainText(item.airesponse)}
                      >
                        <IconButton
                          className="d-flex align-items-center"
                          onClick={() => handleCopy(item.airesponse)}
                        >
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      </CopyToClipboard>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Translator;
