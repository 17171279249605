import watchHomeVideoSaga from '../saga/HomeVideoSaga';
import watchScrapeSaga from '../saga/scrapFileSaga';
import { all } from 'redux-saga/effects'

const rootSaga = function* () {
  yield all([
    watchScrapeSaga(),
    watchHomeVideoSaga(),
  ])
}

export default rootSaga;