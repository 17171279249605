import { headers, BASEURL, checktoken } from "../../../utils/helper";
import { Alert, IconButton, Button, Tooltip } from '@mui/material';
import { ToneListRequest } from '../../../redux/slices/ToneSlice';
import Toaster from '../../../components/common/Toaster';
import { Container, Card, Table } from 'react-bootstrap';
import Loader from "../../../components/common/Loader";
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import axios from 'axios';

const Tones = () => {
    const dispatch = useDispatch()
    const { data: tones, loading } = useSelector(state => state.ToneSlice)

    const DeleteTone = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this tone!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.delete(`${BASEURL}/admin/delete/tone/${id}`, {
                    headers: headers()
                }).then((res) => {
                    if (res.data.success === true) {
                        toast.error(res.data.message);
                        dispatch(ToneListRequest());
                    }
                }).catch((err) => {
                    checktoken(err)
                    if (err.response !== undefined) {
                        if (err.response.data.error !== undefined) {
                            toast.error(err.response.data.error);
                        }
                    }
                })
            } else { swal("Your tone is safe!") }
        });
    }


    useEffect(() => {
        dispatch(ToneListRequest());
    }, [])

    return (
        <Container>
            {loading === true && <Loader />}
            <Toaster />
            <Card className="shadow-sm border-0">
                <Card.Header className="d-flex align-items-center justify-content-between">
                    <Card.Title className="my-2">tone list</Card.Title>
                    <Button
                        size="small"
                        startIcon={<AddIcon />}
                        LinkComponent={Link}
                        to="/admin/create/tone"
                        className="bg-green1-main hover:bg-green1-dark p-2 text-white"
                    >add tone</Button>
                </Card.Header>
                <Card.Body>
                    {(tones.length > 0) &&
                        <Fragment>
                            <div className="table-responsive">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>N°</th>
                                            <th>Img</th>
                                            <th>Name</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            tones.length && tones.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.imoji}</td>
                                                        <td className="text-capitalize">{item.title}</td>
                                                        <td>
                                                            <Tooltip title="Edit" arrow placement="bottom-start">
                                                                <IconButton LinkComponent={Link} to={`/admin/edit/tone/${item._id}`} size="small">
                                                                    <EditIcon className="text-green1-main hover:text-green1-dark" fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Delete" arrow placement="bottom-start">
                                                                <IconButton onClick={() => { DeleteTone(item._id) }} size="small">
                                                                    <DeleteIcon className="text-red-600 hover:text-red-700" fontSize="inherit" />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </Fragment>
                    }
                    {!tones.length && <Alert variant="filled" severity="error">Tone does not exist!</Alert>}
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Tones;