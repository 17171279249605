import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const SaveTextToVideoReq = createAsyncThunk(
  "SaveTextToVideoReq",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/save/textToVideo`,
        data,
        {
          headers: headers(),
        }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
    }
  }
);

const SaveTextToVideoSlice = createSlice({
  name: "SaveTextToVideoSlice",
  initialState: {
    data: {},
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(SaveTextToVideoReq.pending, (state, action) => {
      state.loading = true;
      state.data = {};
    });
    builder.addCase(SaveTextToVideoReq.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(SaveTextToVideoReq.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export default SaveTextToVideoSlice.reducer;
