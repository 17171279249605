import { headers, BASEURL, checktoken } from '../../utils/helper';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const ToneListRequest = createAsyncThunk('ToneListRequest', async () => {
    try {
        const response = await axios.get(`${BASEURL}/admin/tone/list`, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const ToneSlice = createSlice({
    name: 'Tones',
    initialState: {
        data: [],
        error: null,
        loading: false,
    },
    reducers: {
    },
    extraReducers(builder) {
        builder.addCase(ToneListRequest.pending, (state, action) => {
            state.loading = true
            state.data = []
            return state
        })
        builder.addCase(ToneListRequest.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            return state
        })
        builder.addCase(ToneListRequest.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = 'Error occured'
        })
        builder.addCase('LOGOUT', () => this.initialState)
    }
})

export default ToneSlice.reducer
