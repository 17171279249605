// ImageProjectSlice
import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";

export const ImageProjectRequest = createAsyncThunk(
  "ImageProjectRequest",
  async (type) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/latest/project?type=${type}`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const ImgProjectCreateReq = createAsyncThunk(
  "ImgProjectCreateReq",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/project/create`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const SaveChatRequest = createAsyncThunk(
  "SaveChatRequest",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/create/chat/history`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const ImageChatRequest = createAsyncThunk(
  "ImageChatRequest",
  async ({ id, type }) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/project/${id}/${type}/prompts`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);
export const ImageDownload = createAsyncThunk(
  "ImageDownload",
  async ({ id }) => {
    try {
      const response = await axios.get(`${BASEURL}/user/download/image/${id}`, {
        headers: headers(),
      });
      return response.data;
    } catch (error) {
      checktoken(error);
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      }
      return error;
    }
  }
);

export const ImageProjectSlice = createSlice({
  name: "ImageProject",
  initialState: {
    data: {},
    chats: [],
    image: null,
    error: null,
    imgload: false,
    loading: false,
    saveloader: false,
  },
  reducers: {
    AddImageProject: (state, action) => {
      state.data = action.payload;
      return state;
    },
    AddImage: (state, action) => {
      state.image = null;
      return state;
    },
    RemoveImageProject: (state, action) => {
      state.data = action.payload;
      return state;
    },
    DeleteImageProject: (state, action) => {
      state.data = {};
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(ImageProjectRequest.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(ImageProjectRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(ImageProjectRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase(ImgProjectCreateReq.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(ImgProjectCreateReq.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(ImgProjectCreateReq.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase(ImageChatRequest.pending, (state, action) => {
      state.saveloader = true;
      state.chats = [];
      return state;
    });
    builder.addCase(ImageChatRequest.fulfilled, (state, action) => {
      state.saveloader = false;
      state.chats = action.payload.data;
      return state;
    });
    builder.addCase(ImageChatRequest.rejected, (state, action) => {
      state.saveloader = false;
      state.chats = [];
      state.error = "Error occured";
    });

    builder.addCase(ImageDownload.pending, (state, action) => {
      state.imgload = true;
      state.image = [];
      return state;
    });
    builder.addCase(ImageDownload.fulfilled, (state, action) => {
      state.imgload = false;
      state.image = action.payload.data;
      return state;
    });
    builder.addCase(ImageDownload.rejected, (state, action) => {
      state.imgload = false;
      state.image = null;
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export const {
  AddImageProject,
  RemoveImageProject,
  DeleteImageProject,
  AddImage,
} = ImageProjectSlice.actions;
export default ImageProjectSlice.reducer;
