//DeviceSlice
import { headers, BASEURL, checktoken } from '../../utils/helper'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const MemberPaymentRequest = createAsyncThunk('MemberPaymentRequest', async ({ user_id, subscriptionid, priceid }) => {
    try {
        const response = await axios.post(`${BASEURL}/user/member/create/subscription`, { user_id, subscriptionid, priceid }, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const MemberSlice = createSlice({
    name: 'Member',
    initialState: {
        data: null,
        error: null,
        loading: false,
    },
    reducers: {
        AddSubs: (state, action) => {
            state.data = action.payload
            return state
        },
    },
    extraReducers(builder) {
        builder.addCase('LOGOUT', () => this.initialState)
    }
})

export const { AddSubs } = MemberSlice.actions
export default MemberSlice.reducer
