import { createSlice } from '@reduxjs/toolkit'

export const AuthSlice = createSlice({
    name: 'counter',
    initialState: {},
    reducers: {
        addAuth: (state, action) => {
            state = action.payload
            return state
        },
        removeAuth: (state, action) => {
            state = action.payload
            return state
        },
    },
    extraReducers(builder) {
        builder.addCase('LOGOUT', () => console.log(this.initialState))
    }
})

export const { addAuth, removeAuth } = AuthSlice.actions
export default AuthSlice.reducer
