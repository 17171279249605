import React, { createContext, useState } from 'react';

// Create a Context
const PopupContext = createContext();

// Create a Provider component
const PopupProvider = ({ children }) => {
  const [popupState, setpopupState] = useState(false);

  return (
    <PopupContext.Provider value={{ popupState, setpopupState }}>
      {children}
    </PopupContext.Provider>
  );
};

export { PopupContext, PopupProvider };