import { SaveEmailContent } from "../../../redux/slices/EmailProjectSlice";
import { SaveChatRequest } from "../../../redux/slices/ChatPromptSlice";
import { useState, useEffect, useMemo, Fragment } from "react";
import { WordCountRequest } from "../../../redux/slices/CounterSlice";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import {
  BASEURL,
  checktoken,
  headers,
  markdownToText,
} from "../../../utils/helper";
import MarkdownViewer from "../../../components/chat/MarkdownViewer";
import { ToneListRequest } from "../../../redux/slices/ToneSlice";
import FormSkelton from "../../../components/common/FormSkelton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Toaster from "../../../components/common/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { Form, Stack, Card } from "react-bootstrap";
import { Button, IconButton } from "@mui/material";
import ProjectComponent from "./ProjectComponent";
import Select from "react-dropdown-select";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";

const EmailTemplate = () => {
  const dispatch = useDispatch();
  const [limitError, setLimitError] = useState(false);

  const [tone, setTone] = useState("");
  const [text, setText] = useState("");
  const [word, setWord] = useState(50);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState([]);
  const [tonelist, setToneList] = useState([]);
  const [audience, setAudience] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const user = useSelector((state) => state.AuthSlice);
  const { data: tones, loading } = useSelector((state) => state.ToneSlice);
  const { data: project } = useSelector((state) => state.EmailProjectSlice);
  const options = [
    { text: "50", value: 50 },
    { text: "100", value: 100 },
    { text: "150", value: 150 },
    { text: "200", value: 200 },
    { text: "400", value: 400 },
    { text: "600", value: 600 },
  ];

  const handleCopy = (content) => {
    content
      ? toast.success("Response copied to clipboard!")
      : toast.error("Response not found !");
  };

  useMemo(() => {
    if (tones && Object.keys(tones)?.length) {
      const values = [];
      tones.forEach((item) => {
        values.push({
          label: `${item.imoji} ${item.title}`,
          value: item.title,
        });
      });
      setToneList(values);
      return tonelist;
    }
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    setLimitError(false);

    try {
      dispatch(WordCountRequest());
      const formData = { subject, audience, text, tone, word };
      const message = JSON.stringify(formData);
      const response = await fetch(`${BASEURL}/user/generate/email/template`, {
        method: "POST",
        responseType: "stream",
        headers: headers(),
        body: message,
      });
      if (response.status === 500) {
        // console.log("response.status === 500");

        // setLimitError(true);
        toast.error("Daily limit exceeded");
        setSpinner(false);
        throw new Error("Daily limit exceeded");
      }
      //eslint-disable-next-line
      const readData = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      setSpinner(false);
      let aiRes = "";
      while (true) {
        const { done, value } = await readData.read();
        if (done) {
          break;
        }
        aiRes += value;
        setContent([...content, { airesponse: aiRes }]);
      }
      setIsComplete(true);
      const saveprompt = {
        prompt: text,
        type: "email",
        airesponse: aiRes,
        project: project._id,
      };
      dispatch(SaveChatRequest(saveprompt));
      dispatch(
        SaveEmailContent({
          tone: tone,
          text: text,
          subject: subject,
          audience: audience,
          airesponse: aiRes,
        })
      );
      dispatch(WordCountRequest());
    } catch (err) {
      setSpinner(false);
      checktoken(err);
      if (err?.response?.data?.error) {
        toast.error(err.response.data.error);
      }
    }
  };

  useMemo(() => {
    setContent([]);
    return content;
  }, [project]);

  useEffect(() => {
    dispatch(ToneListRequest());
    dispatch(WordCountRequest());
  }, []);

  return (
    <div className="h-100">
      {user?.currentplan && <ProjectComponent />}
      <Toaster />
      <Stack className="h-100">
        <div
          className={`mx-auto p-4 rounded border my-5 ${
            content.length === 0 ? "col-md-6 col-11" : "col-sm-11"
          }`}
        >
          {loading && <FormSkelton />}
          {!content?.length && !loading && (
            <Form
              onSubmit={(e) => {
                user?.currentplan ? handleSubmit(e) : e.preventDefault();
              }}
            >
              <Form.Group className="mb-3 input-field">
                <Select
                  style={{ height: "50px", fontSize: "16px" }}
                  className="text-capitalize multiselect-dropdown"
                  options={options}
                  searchable={false}
                  placeholder="Select Words"
                  labelField="text"
                  valueField="value"
                  onChange={(values) => {
                    setWord(values[0].value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 inuput-field">
                <Form.Control
                  type="text"
                  as={"textarea"}
                  placeholder="Email Subject:"
                  className="custom-field custom-textarea"
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 input-field">
                <Form.Control
                  as={"textarea"}
                  type="text"
                  placeholder="Target audience:"
                  className="custom-field custom-textarea"
                  value={audience}
                  onChange={(e) => {
                    setAudience(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 input-field">
                <Form.Control
                  as={"textarea"}
                  type="text"
                  placeholder="Email text:"
                  className="custom-field custom-textarea"
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-3 input-field">
                <Select
                  style={{ height: "50px", fontSize: "16px" }}
                  className="text-capitalize multiselect-dropdown"
                  options={tonelist}
                  searchable={false}
                  placeholder="Select Tone"
                  labelField="label"
                  valueField="value"
                  onChange={(values) => {
                    setTone(values[0].value);
                  }}
                />
              </Form.Group>
              {!subject || !audience || !tone || !text || word < 1 ? (
                <Button
                  type="button"
                  disabled
                  className="bg-primary-light hover:bg-primary-main text-white"
                >
                  Generate content {spinner === true && <ButtonSpinner />}
                </Button>
              ) : (
                <Button
                  type="submit"
                  className="bg-primary-light hover:bg-primary-main text-white"
                >
                  Generate content {spinner === true && <ButtonSpinner />}
                </Button>
              )}
              {limitError && (
                <div className="mt-3">
                  <p className="font-bold text-red-500">Daily limit exceeded</p>
                </div>
              )}
            </Form>
          )}
          <Fragment>
            {content.length > 0 && (
              <Card className="border-0">
                <Card.Title> {subject}</Card.Title>
                <Card.Body
                  className="p-0"
                  style={{ whiteSpace: "break-spaces" }}
                >
                  {content.map((item, index) => (
                    <MarkdownViewer
                      className="answer-content"
                      content={DOMPurify.sanitize(item.airesponse).replaceAll(
                        /【.*?】/g,
                        ""
                      )}
                    />
                  ))}
                  {isComplete === true && (
                    <div className="mt-3 d-flex align-items-center justify-content-between">
                      <Button
                        type="button"
                        className="primary-blue-btn ff-lato"
                        onClick={() => {
                          setContent([]);
                          setAudience("");
                          setIsComplete(false);
                          setSubject("");
                          setText("");
                        }}
                      >
                        Reset
                      </Button>
                      <CopyToClipboard
                        text={markdownToText(content[0].airesponse)}
                      >
                        <IconButton
                          onClick={() => handleCopy(content[0].airesponse)}
                        >
                          <ContentCopyIcon fontSize="inherit" />
                        </IconButton>
                      </CopyToClipboard>
                    </div>
                  )}
                </Card.Body>
              </Card>
            )}
          </Fragment>
        </div>
      </Stack>
    </div>
  );
};

export default EmailTemplate;
