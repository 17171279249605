import ButtonSpinner from '../../components/common/ButtonSpinner';
import { Row, Col, Container, Form } from 'react-bootstrap';
import Toaster from '../../components/common/Toaster';
import { BASEURL } from '../../utils/helper';
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import axios from 'axios';

function ForgotPass() {
  const [email, setEmail] = useState('');
  const [spinner, setSpinner] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSpinner(true);
    axios.post(`${BASEURL}/forgot/password/link`, { email: email }, {
      withCredentials: true,
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' }
    }).then((res) => {
      if (res.data.success === true) {
        toast.success(res.data.message);
      }
      setSpinner(false);
    }).catch((err) => {
      setSpinner(false);
      if (err?.response?.data?.success === false) {
        toast.error(err.response.data.error);
      }
    })
  }

  return (
    <Fragment>
      <section className="section-wrapper form-sec border-t-[1px] border-border d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
        <Container>
          <Row>
            <Toaster />
            <Col md={6} lg={4} className="form-wrapper form-box">
              <h2 className="text-center mb-4">Forgot your password?</h2>
              <p className="text-center mb-3">We'll email you a link to reset your password. If you have any issues, contact</p>
              <Form className="mb-3" onSubmit={handleSubmit}>
                <Form.Group className="input-field">
                  <Form.Control type="email" name="email" className="custom-field" placeholder="Enter Your Email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                  <Button onClick={handleSubmit} className="text-gray-900 p-0 leading-[1] capitalize mt-3" disableRipple>Resend Link</Button>
                </Form.Group>
                <Form.Group className="form-btn">
                  <Button type="submit" className="form-button capitalize">Send Link {spinner === true && <ButtonSpinner />}</Button>
                </Form.Group>
                <p className="text-center"><Link to="/login" className="link-text">Back to login</Link></p>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default ForgotPass;
