import { WordCountRequest } from "../../../redux/slices/CounterSlice";
import {
  DeleteFileRequest,
  FileListRequest,
} from "../../../redux/slices/FileListSlice";
import { Drawer, Box, IconButton, Skeleton, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { headers } from "../../../utils/helper";
import ButtonSpinner from "../../../components/common/ButtonSpinner";

const FileDrawer = ({ open, toggleDrawer }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: project } = useSelector((state) => state.HRUploadSlice);
  const { data, loading } = useSelector((state) => state.FileListSlice);
  const [downloadLoader, setDownloadLoader] = useState(false);
  useMemo(() => {
    project?._id && dispatch(FileListRequest({ id: project?._id }));
    return true;
  }, [project]);

  useEffect(() => {
    dispatch(WordCountRequest());
    project?._id && dispatch(FileListRequest({ id: project?._id }));
    //eslint-disable-next-line
  }, [navigate, open]);

  // const handleDownload = async (url, name) => {
  //   try {
  //     const response = await fetch(url);
  //     const blob = await response.blob();
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.setAttribute("download", name);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };

  // const handleDownload = async (url, name) => {
  //   try {
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/octet-stream",
  //       },
  //       // If CORS issues, ensure the server allows CORS
  //       mode: "cors",
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Network response was not ok: ${response.statusText}`);
  //     }

  //     const blob = await response.blob();
  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.setAttribute("download", name);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };

  // const handleDownload = async (url, name) => {
  //   try {
  //     console.log(`Starting download from URL: ${url}`);
  //     const response = await axios({
  //       url: url,
  //       method: "GET",
  //       responseType: "blob", // Important for handling binary data
  //     });

  //     const blob = new Blob([response.data], {
  //       type: response.headers["content-type"],
  //     });
  //     console.log("Blob received:", blob);

  //     const link = document.createElement("a");
  //     link.href = window.URL.createObjectURL(blob);
  //     link.setAttribute("download", name);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //     console.log("File downloaded successfully");
  //   } catch (error) {
  //     console.error("Error downloading file:", error);
  //   }
  // };

  const handleDownload = async (name) => {
    try {
      setDownloadLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/project/hrUploads/downloadFile`,
        { file: name },
        {
          headers: headers(),

          responseType: "blob",
        }
      );
      const fileName = name.substring(name.lastIndexOf("/") + 1);

      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadLoader(false);
    } catch (error) {
      setDownloadLoader(false);
      console.error("Error downloading file:", error);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
      <Box component="section" sx={{ p: 2, maxWidth: "300px", width: "300px" }}>
        <h4 className="mb-2 text-capitalize">
          uploaded files [{data?.length}]
        </h4>
        {!loading &&
          !downloadLoader &&
          data?.length > 0 &&
          data.map((item) => {
            return (
              <div
                key={item?._id}
                className="bg-light border rounded p-2 position-relative mb-3 me-4"
              >
                <div
                  className="position-absolute top-0 start-100 end-0 translate-middle"
                  style={{ width: "25px", height: "25px" }}
                >
                  <IconButton
                    className="border bg-light"
                    onClick={() => {
                      dispatch(
                        DeleteFileRequest({
                          id: item._id,
                          project: project?._id,
                          file: item.bucketAddress,
                        })
                      );
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div
                  //   className="h-full w-full cursor-pointer"
                  onClick={() => {
                    // downloadFile(item.bucketAddress);
                  }}
                >
                  <div
                    // href={item.bucketAddress}
                    onClick={() => handleDownload(item.bucketAddress)}
                    // download={item.title}
                    target="_blank"
                    className="text-black h-full w-full cursor-pointer"
                  >
                    {item.title ? item.title : "No file name"}
                    {/* {downloadLoader && <ButtonSpinner />} */}
                  </div>
                </div>
              </div>
            );
          })}
        {(loading || downloadLoader) && (
          <div className="d-block">
            <Skeleton loading width="100%" height={60} className="mb-1" />
            <Skeleton loading width="100%" height={60} className="mb-1" />
            <Skeleton loading width="100%" height={60} className="mb-1" />
            <Skeleton loading width="100%" height={60} className="mb-1" />
            <Skeleton loading width="100%" height={60} className="mb-1" />
          </div>
        )}
        {!loading && data?.length === 0 && (
          <Alert severity="info">Not uploaded yet!</Alert>
        )}
      </Box>
    </Drawer>
  );
};

export default FileDrawer;
