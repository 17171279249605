import { headers, BASEURL, checktoken } from '../../utils/helper'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const SavePromptRequest = createAsyncThunk('SavePromptRequest', async (data) => {
    try {
        const response = await axios.post(`${BASEURL}/user/save/translated/prompt`, data, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const DeletePromptRequest = createAsyncThunk('DeletePromptRequest', async (id) => {
    try {
        const response = await axios.delete(`${BASEURL}/user/delete/translated/prompt/${id}`, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const UserPromptSlice = createSlice({
    name: 'UserPrompt',
    initialState: {
        data: {},
        error: null,
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(SavePromptRequest.pending, (state, action) => {
            state.loading = true
            state.data = {}
            return state
        })
        builder.addCase(SavePromptRequest.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            return state
        })
        builder.addCase(SavePromptRequest.rejected, (state, action) => {
            state.loading = false
            state.data = {}
            state.error = 'Error occured'
        })
        builder.addCase(DeletePromptRequest.pending, (state, action) => {
            state.loading = true
            state.data = {}
            return state
        })
        builder.addCase(DeletePromptRequest.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            return state
        })
        builder.addCase(DeletePromptRequest.rejected, (state, action) => {
            state.loading = false
            state.data = {}
            state.error = 'Error occured'
        })
        builder.addCase('LOGOUT', () => this.initialState)
    }
})

export default UserPromptSlice.reducer
