import ScrapeReader from "../pages/user/chat/scraping/ScrapeReader";
import ImageGeneration from "../pages/user/chat/ImageGeneration";
import EmailTemplate from "../pages/user/chat/EmailTemplate";
import SocialMediaGen from "../pages/user/chat/SocialMedia";
import Scrape from "../pages/user/chat/scraping/Scrape";
import Reader from "../pages/user/chat/scraping/Reader";
import GptArticle from "../pages/user/chat/GptArticle";
import Translator from "../pages/user/chat/Translator";
import BlockPage from "../pages/user/chat/BlockPage";
import Profile from "../pages/user/profile/Profile";
import Account from "../pages/user/account/Account";
import Rephrase from "../pages/user/chat/Rephrase";
import Projects from "../pages/user/chat/Projects";
import HRUpload from "../pages/user/chat/HRUpload";
import History from "../pages/user/chat/History";
import Prompt from "../pages/user/chat/Prompt";
import Chat from "../pages/user/chat/Chat";
import Team from "../pages/user/team/Team";
import HRHistory from "../pages/user/chat/HRHistory";
import ImageGenerationHistory from "../pages/user/chat/ImageGenerationHistory";
import ReaderHistory from "../pages/user/chat/scraping/ReaderHistory";
import TranscribeImage from "../pages/user/chat/TranscribeImage";
import TextToVideo from "../pages/user/chat/TextToVideo";

const ChatRoute = [
  { path: "/user/chat/outside", name: "Chat", component: <Chat /> },
  { path: "/user/chat/profile", name: "User", component: <Profile /> },
  { path: "/user/chat/account", name: "Account", component: <Account /> },
  { path: "/user/chat/team-members", name: "Team", component: <Team /> },
  { path: "/user/chat/prompt/:id", name: "Prompt", component: <Prompt /> },
  { path: "/user/chat/scrap-list", name: "Scrape", component: <Scrape /> },
  { path: "/user/chat/scrap-reader", name: "Reader", component: <Reader /> },
  { path: "/user/chat/projects", name: "Projects", component: <Projects /> },
  { path: "/user/chat/rephrase", name: "Rephrase", component: <Rephrase /> },
  { path: "/user/chat/hruploads", name: "HRUpload", component: <HRUpload /> },
  { path: "/user/chat/blocked", name: "BlockPage", component: <BlockPage /> },
  {
    path: "/user/chat/TextToVideo",
    name: "TextToVideo",
    component: <TextToVideo />,
  },
  {
    path: "/user/chat/articles",
    name: "GptArticle",
    component: <GptArticle />,
  },
  {
    path: "/user/chat/translator",
    name: "Translator",
    component: <Translator />,
  },
  {
    path: "/user/chat/TranscribeImage",
    name: "Transcribe Image",
    component: <TranscribeImage />,
  },
  {
    path: "/user/chat/projects/:ptype",
    name: "Projects",
    component: <Projects />,
  },
  {
    path: "/user/chat/history/:id/:type",
    name: "History",
    component: <History />,
  },
  {
    path: "/user/chat/history/doc/:id",
    name: "Reader History",
    component: <ReaderHistory />,
  },
  {
    path: "/user/chat/history/hr/:id/",
    name: "HR History",
    component: <HRHistory />,
  },
  {
    path: "/user/chat/emails",
    name: "EmailTemplate",
    component: <EmailTemplate />,
  },
  {
    path: "/user/chat/scrapping",
    name: "ScrapeReader",
    component: <ScrapeReader />,
  },
  {
    path: "/user/chat/social-media",
    name: "SocialMediaGen",
    component: <SocialMediaGen />,
  },
  {
    path: "/user/chat/image-generator",
    name: "ImageGeneration",
    component: <ImageGeneration />,
  },
  {
    path: "/user/chat/history/image/:id/",
    name: "Image Generation History",
    component: <ImageGenerationHistory />,
  },
];

export default ChatRoute;
