import { headers, checktoken } from "../../utils/helper";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Fragment, useEffect } from "react";
import ScrollTop from "../common/ScrollTop";
import { Cookies } from "react-cookie";
import { Box } from "@mui/material";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";

const Layout = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const isLogin = Boolean(cookies.get("auth"));
  const { pathname } = useLocation();
  console.log("pathname.../", pathname);
  useEffect(() => {
    if (isLogin) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/admin/islogin`, {
          headers: headers(),
        })
        .then((res) => {
          if (res.data?.success === true) {
            return true;
          }
        })
        .catch((err) => {
          checktoken(err);
        });
    }

    // eslint-disable-next-line
  }, [navigate]);

  return (
    <>
      {pathname === "/" ? (
        <Outlet />
      ) : (
        <Fragment>
          <Box
            className="grid min-h-[100vh]"
            sx={{ gridTemplateRows: "auto 1fr auto" }}
          >
            <Header />
            <ScrollTop />
            <main>
              <Outlet />
            </main>
            <Footer />
          </Box>
        </Fragment>
      )}
    </>
  );
};

export default Layout;
