import { DialogContent, DialogTitle, Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { BASEURL, checktoken } from '../../utils/helper';
import { forwardRef, Fragment, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Toaster from './Toaster';
import axios from 'axios';
import ButtonSpinner from './ButtonSpinner';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const ReportModal = ({ open, handleClose }) => {
    const [name, setName] = useState('')
    const [file, setFile] = useState('')
    const [email, setEmail] = useState('')
    const [content, setContent] = useState(null)
    const [message, setMessage] = useState('')
    const [spinner, setSpinner] = useState(false)

    const SendContactMail = (e) => {
        setSpinner(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('file', file);
        formData.append('message', message);
        axios.post(`${BASEURL}/contact/mail`, formData).then((res) => {
            if (res.data.success === true) {
                toast.success(res.data.msg);
                setSpinner(false);
                setName('')
                setEmail('')
                setMessage('')
                setContent(null)
                handleClose();
            }
        }).catch((err) => {
            checktoken(err)
            setSpinner(false);
            if (err.response.data.error !== undefined) {
                toast.error(err.response.data.error);
            }
        })
    }

    return (
        <Fragment>
            <Toaster />
            <Dialog
                maxWidth={'sm'}
                fullWidth
                open={open}
                keepMounted
                onClose={handleClose}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className="text-center ff-lato">Report your problem/or ask question</DialogTitle>
                <DialogContent>
                    <Form onSubmit={SendContactMail}>
                        <p className="mb-2 warning-text">For faster reply, send screenshots/screen recording of the problem</p>
                        <Form.Group className='input-field mb-3'>
                            <Form.Control type="text" name="name" className="custom-field" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='input-field mb-3'>
                            <Form.Control type="text" name="email" className="custom-field" placeholder="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='input-field mb-3'>
                            <Form.Control as={'textarea'} type="text" name="name" className="custom-field" placeholder="Explain your problem" value={message} onChange={(e) => setMessage(e.target.value)} />
                        </Form.Group>
                        <Form.Group className='input-field mb-3'>
                            <div className="file-input position-relative w-100 me-1">
                                <input type="file" className="file-input__input start-0 end-0 top-0 bottom-0" accept='image/jpg, image/jpeg, image/png, video/mp4' onChange={(e) => { setFile(e.target.files[0]); setContent(e.target.files[0]); e.target.value = ''; }} />
                                <label className="file-input__label" htmlFor="file-input">
                                    <CloudUploadIcon />
                                    <span>upoads /image or video</span>
                                </label>
                            </div>
                        </Form.Group>
                        {content?.type.split('/')[0] === 'video' &&
                            <div className='border rounded p-2 mb-2 text-center' style={{ height: '160px' }}>
                                <video controls className="p-0" style={{ height: '100%', width: '100%', objectFit: 'contain', background: '#6c0345' }}>
                                    <source src={URL.createObjectURL(content)} type="video/mp4" />
                                    <source src={URL.createObjectURL(content)} type="video/ogg" />
                                </video>
                            </div>
                        }
                        {content?.type.split('/')[0] === 'image' &&
                            <div className='border rounded p-2 mb-2 text-center'>
                                <img src={URL.createObjectURL(content)} alt="img" style={{ width: '200px', maxWidth: '200px', margin: 'auto' }} />
                            </div>
                        }
                        <Form.Group className='input-field text-center'>
                            <Button type="submit" className="primary-blue-btn ff-lato me-2"><AttachEmailIcon className='me-2' /> Send Email {spinner === true && <ButtonSpinner />}</Button>
                        </Form.Group>
                    </Form>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
}

export default ReportModal;