import { headers, BASEURL, checktoken } from '../../utils/helper';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const SaveArticleReq = createAsyncThunk('SaveArticleReq', async (data) => {
    try {
        const response = await axios.post(`${BASEURL}/user/save/article`, data, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
    }
})

const SaveArticleSlice = createSlice({
    name: 'SaveArticleSlice',
    initialState: {
        data: {},
        error: null,
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(SaveArticleReq.pending, (state, action) => {
            state.loading = true
            state.data = {}
        })
        builder.addCase(SaveArticleReq.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload
        })
        builder.addCase(SaveArticleReq.rejected, (state, action) => {
            state.loading = false
            state.data = {}
            state.error = 'Error occured'
        })
        builder.addCase('LOGOUT', () => this.initialState)
    }
})

export default SaveArticleSlice.reducer