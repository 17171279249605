import { Link, useLocation } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import { icons } from "../../assets/js/icons";
import { Fragment } from "react";

const {
  MuiGrid,
  MuiUsers,
  MuiAdmin,
  MuiFeatured,
  MuiTransaction,
  MusicNoteIcon,
} = icons;
const Sidebar = () => {
  const { pathname } = useLocation();
  const navItems = [
    { text: "Dashboard", path: "/admin", icon: MuiGrid },
    { text: "Admin", path: "/admin/list", icon: MuiAdmin },
    { text: "User", path: "/admin/user/list", icon: MuiUsers },
    { text: "User Devices", path: "/admin/user/devices", icon: MuiUsers },
    { text: "Tone", path: "/admin/tones", icon: MusicNoteIcon },
    { text: "Business Insights", path: "/admin/prompts", icon: MuiFeatured },
    { text: "Payment", path: "/admin/payment", icon: MuiTransaction },
    { text: "Transactions", path: "/admin/transactions", icon: MuiTransaction },
  ];

  return (
    <Fragment>
      <Box className="bg-green1-x-dark border-e-[1px] border-solid border-e-white">
        <Stack spacing={2}>
          <Box className="py-2">
            <Button
              LinkComponent={Link}
              to="/admin"
              // startIcon={
              //   <img className="w-[40px] h-[40px] object-cover rounded-full border-solid border-[1px] border-white p-[2px]" src={logo} alt="logo" />
              // }
              className="w-full font-semibold text-white text-2xl ff-jost"
            >
              Top executive AI
            </Button>
          </Box>
          <Stack spacing={1} className="px-[12px] pb-2">
            {navItems &&
              navItems.map((item, i) => (
                <Box className="mx-2" key={`key-${i}`}>
                  <Button
                    LinkComponent={Link}
                    startIcon={item?.icon}
                    to={item.path}
                    className={`admin-nav${
                      pathname.includes(item.path) ? " active" : ""
                    }`}
                  >
                    {item.text}
                  </Button>
                </Box>
              ))}
          </Stack>
        </Stack>
      </Box>
    </Fragment>
  );
};

export default Sidebar;
