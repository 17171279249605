import {
  Fragment,
  Suspense,
  lazy,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AppHistoryRequest } from "../../../redux/slices/AppHistorySlice";
import { useNavigate, useParams } from "react-router-dom";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { HRProjectChatReq } from "../../../redux/slices/HRUploadSlice";
import { WordCountRequest } from "../../../redux/slices/CounterSlice";
import ButtonSpinner from "../../../components/common/ButtonSpinner";
import { BASEURL, checktoken, headers } from "../../../utils/helper";
import CustomTooltip from "../../../components/common/CustomTooltip";
import { handleFileUpload } from "../../../redux/slices/FileSlice";
import { Alert, Grid, IconButton, Snackbar } from "@mui/material";
import ChatSkelton from "../../../components/common/ChatSkelton";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AttachmentIcon from "@mui/icons-material/AttachFile";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Toaster from "../../../components/common/Toaster";
import { useDispatch, useSelector } from "react-redux";
import ico from "../../../assets/images/favicon.png";
import SquareIcon from "@mui/icons-material/Square";
import ProjectComponent from "./ProjectComponent";
import { Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import FileDrawer from "./FileDrawer";
import DOMPurify from "dompurify";
import axios from "axios";
const MarkdownViewer = lazy(() =>
  import("../../../components/chat/MarkdownViewer")
);

const HRHistory = () => {
  const chatBlock = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { type, id } = useParams();
  const [height, setHeight] = useState(0);
  console.log("type.../", type);
  const user = useSelector((state) => state.AuthSlice);
  const { data: prompts, loading } = useSelector(
    (state) => state.AppHistorySlice
  );
  const { loading: fileLoading } = useSelector((state) => state.FileSlice);

  console.log("prompts.../", prompts);
  const handleCopy = (content) => {
    content
      ? toast.success("Content copied to clipboard!")
      : toast.error("Content not found !");
  };

  useEffect(() => {
    if (id) {
      dispatch(AppHistoryRequest({ id, type: "hr" }));
    }
    setHeight(document.getElementById("chat-layout").offsetHeight);
  }, [navigate]);

  // HR UPLOADS CODE
  const [input, setInput] = useState("");
  const [rows, setRows] = useState(1);

  const [chat, setChat] = useState([]);
  const [open, setOpen] = useState(false);
  const [stop, setStop] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [controller, setController] = useState(new AbortController());
  const {
    data: project,
    chat: chatlist,
    cloading,
  } = useSelector((state) => state.HRUploadSlice);

  console.log("chatlist.../", chatlist);

  const handleStopStream = (e) => {
    setStop(false);
    controller.abort();
    setController(new AbortController());
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const scrollChat = () => {
    setTimeout(function () {
      const objDiv = chatBlock.current;
      if (objDiv !== null && objDiv !== undefined) {
        objDiv.scrollTo({
          top: objDiv.scrollHeight,
          left: 0,
          behavior: "smooth",
        });
      }
    });
  };
  const handleSend = async (e) => {
    e.preventDefault();
    dispatch(WordCountRequest());
    setInput("");
    setSpinner(true);
    setChat([...chat, { role: "user", prompt: input }]);
    try {
      scrollChat();
      const message = JSON.stringify({ que: input, project: project?._id });
      const response = await fetch(`${BASEURL}/user/hrupload/chats`, {
        method: "POST",
        signal: controller.signal,
        responseType: "stream",
        headers: headers(),
        body: message,
      });

      //eslint-disable-next-line
      if (!response.ok) {
        const errorResponse = await response.json();
        toast.error(errorResponse.error || "Something went wrong");
        setSpinner(false);
        return;
      }
      const readData = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      setSpinner(false);
      setStop(true);
      let aiRes = "";
      while (true) {
        scrollChat();
        const { done, value } = await readData.read();
        if (done) {
          break;
        }
        aiRes += value;
        setChat([
          ...chat,
          { role: "user", prompt: input },
          { airole: "assistant", airesponse: aiRes },
        ]);
        scrollChat();
      }
      setStop(false);
      if (aiRes.length > 10) {
        const saveprompt = {
          prompt: input,
          type: "hr",
          airesponse: aiRes,
          project: project._id,
        };
        setSpinner(false);
        saveHistory(saveprompt);
        dispatch(WordCountRequest());
      }
    } catch (err) {
      setSpinner(false);
      checktoken(err);
      if (err?.response?.data?.error) {
        toast.error(err.response.data.error);
      }
    }
  };

  const saveHistory = (data) => {
    axios
      .post(`${BASEURL}/user/create/chat/history`, data, { headers: headers() })
      .then((res) => {
        if (res.data.success === true) {
          setChat(res.data.data);
        }
        scrollChat();
      })
      .catch((err) => {
        checktoken(err);
        if (err?.response?.data?.error) {
          toast.error(err.response.data.error);
        }
      });
  };

  useEffect(() => {
    setHeight(document.getElementById("chat-layout").offsetHeight);
    if (
      user?.currentplan &&
      (user?.currentplan?.plankey === "execs_pro_monthly" ||
        user?.currentplan?.plankey === "execs_pro_semianual" ||
        user?.currentplan?.plankey === "execs_pro_yearly" ||
        user?.currentplan?.plankey === "team_member_monthly" ||
        user?.currentplan?.plankey === "team_member_semi" ||
        user?.currentplan?.plankey === "team_member_annual")
    ) {
      dispatch(WordCountRequest());
      if (project?._id) {
        dispatch(HRProjectChatReq({ id: project._id, type: "hr" }));
      }
    }
    scrollChat();
    //eslint-disable-next-line
  }, [project]);

  useEffect(() => {
    if (prompts.length > 0) setChat([...prompts]);
  }, [prompts]);

  useMemo(() => {
    if (chatlist?.length) {
      setChat(chatlist);
    } else {
      setChat([]);
    }
    scrollChat();
    return chat;
  }, [project, chatlist]);

  useEffect(() => {
    setHeight(document.getElementById("chat-layout").offsetHeight);
    if (
      user?.currentplan &&
      (user?.currentplan?.plankey === "execs_pro_monthly" ||
        user?.currentplan?.plankey === "execs_pro_semianual" ||
        user?.currentplan?.plankey === "execs_pro_yearly" ||
        user?.currentplan?.plankey === "team_member_monthly" ||
        user?.currentplan?.plankey === "team_member_semi" ||
        user?.currentplan?.plankey === "team_member_annual")
    ) {
      dispatch(WordCountRequest());
      if (project?._id) {
        dispatch(HRProjectChatReq({ id: project._id, type: "hr" }));
      }
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // console.log("input.length.../", input.length);
    // console.log("rows../", rows);
    if (input.length >= 0 && input.length < 120) setRows(1);
    else if (input.length >= 120 && input.length < 240) setRows(2);
    else if (input.length >= 240 && input.length < 360) setRows(3);
    else if (input.length >= 360 && input.length < 480) setRows(4);
    else if (input.length >= 480 && input.length < 600) setRows(5);
    else if (input.length > 600) setRows(6);
  }, [input]);

  return (
    <div
      className="flex flex-col"
      style={{ height: `calc(100vh - ${height}px)` }}
    >
      <FileDrawer open={open} toggleDrawer={toggleDrawer} />
      {/* {user?.currentplan &&
        (user?.currentplan?.plankey === "execs_pro_monthly" ||
          user?.currentplan?.plankey === "execs_pro_semianual" ||
          user?.currentplan?.plankey === "execs_pro_yearly" ||
          user?.currentplan?.plankey === "team_member_monthly" ||
          user?.currentplan?.plankey === "team_member_semi" ||
          user?.currentplan?.plankey === "team_member_annual") && (
          <div className="">
            <ProjectComponent />
          </div>
        )} */}

      {user?.currentplan?.[0] && <ProjectComponent />}

      <Toaster />
      {loading && <ChatSkelton />}
      <div
        id="chat-body"
        className="grow chat-block overflow-y-auto h-full p-[20px]"
        ref={chatBlock}
      >
        {prompts?.length > 0 && (
          <Grid container className={`hide-scroll-bar chat-section mt-3`}>
            {chat?.map((item, index) => (
              <Fragment key={index}>
                {item.role === "user" && item.prompt !== "" && (
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start ${item.promptrole} w-100`}
                  >
                    <div className="d-flex ms-auto flex-grow-1 align-items-start">
                      <div className="d-block">
                        <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2">
                          {user?.firstname?.split("")[0]}
                          {user?.lastname?.split("")[0]}
                        </span>
                      </div>
                      <div
                        className="leading-loose w-100"
                        style={{
                          whiteSpace: "break-spaces",
                          wordBreak: "break-word",
                        }}
                      >
                        <div
                          className="question"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item.prompt),
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                )}

                {item.airole === "assistant" && (
                  <Grid
                    item
                    xs={12}
                    md={10}
                    className={`chat-response mx-auto p-2 p-sm-3 mb-2 d-flex align-items-start w-100 answer`}
                  >
                    <div className="d-block">
                      <span className="prompt-small-icon d-flex align-items-center justify-content-center text-uppercase fw-bold me-2 p-1">
                        <img className="w-100 h-100" src={ico} alt="ico" />
                      </span>
                    </div>
                    <div className="d-block flex-grow-1 answer-div">
                      <div
                        className={`leading-loose ${
                          item.type === "image" ? "d-block" : ""
                        }`}
                        style={{ fontWeight: 500 }}
                      >
                        {item.type === "image" ? (
                          <div style={{ maxWidth: "400px" }}>
                            <img
                              src={item.airesponse}
                              alt="image"
                              className="rounded mx-auto w-100"
                            />
                          </div>
                        ) : (
                          <Suspense fallback={<div>Loading...</div>}>
                            <MarkdownViewer
                              className="answer-content"
                              content={DOMPurify.sanitize(
                                item.airesponse
                              ).replaceAll(/【.*?】/g, "")}
                            />
                          </Suspense>
                        )}
                      </div>
                      {item?._id && item?.type !== "image" && (
                        <div className="d-flex align-items-center justify-content-end mt-1">
                          <CopyToClipboard
                            text={
                              item.type === "image"
                                ? item.airesponse
                                : item.airesponse.replaceAll(/【.*?】/g, "")
                            }
                          >
                            <IconButton
                              onClick={() => handleCopy(item.airesponse)}
                            >
                              <ContentCopyIcon fontSize="inherit" />
                            </IconButton>
                          </CopyToClipboard>
                        </div>
                      )}
                    </div>
                  </Grid>
                )}
              </Fragment>
            ))}
            {!prompts?.length && (
              <div className="col-12 p-3">
                <Alert severity="info" variant="filled">
                  Content not available is this project.
                </Alert>
              </div>
            )}
          </Grid>
        )}
      </div>
      <Grid
        container
        className="chat-actions sticky bottom-0 bg-white px-[20px] pb-[12px]"
        style={{ zIndex: "2" }}
      >
        <Grid
          item
          xs={12}
          md={10}
          className="chat-input-group chat-input-group-custom mx-auto"
        >
          <Form
            onKeyDown={(e) => {
              e.key === "Enter" && !e.shiftKey && e.preventDefault();
            }}
          >
            <Form.Group className="position-relative">
              <Form.Control
                placeholder="Type your message here..."
                onChange={(e) => {
                  setInput(e.target.value);
                }}
                onKeyUp={(e) => {
                  e.key === "Enter" &&
                    !e.shiftKey &&
                    e.target.value.trim() !== "" &&
                    handleSend(e);
                }}
                value={input}
                as="textarea"
                rows={rows}
              />
              <div className="chat-btns">
                {input.length < 4 ? (
                  <Button type="button" disabled className="ochat-btn">
                    {spinner === true ? (
                      <ButtonSpinner />
                    ) : (
                      <ArrowUpwardRoundedIcon />
                    )}
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    className="ochat-btn"
                    onClick={(e) => {
                      handleSend(e);
                    }}
                  >
                    {spinner === true ? (
                      <ButtonSpinner />
                    ) : (
                      <ArrowUpwardRoundedIcon />
                    )}
                  </Button>
                )}
                {stop && (
                  <Button
                    onClick={handleStopStream}
                    type="button"
                    className="ms-2 ochat-btn"
                  >
                    <SquareIcon />
                  </Button>
                )}
                <CustomTooltip
                  title="Upload Files"
                  arrow
                  placement="top"
                  content={
                    <Button
                      type="button"
                      className="ms-2 ochat-btn position-relative"
                    >
                      {fileLoading ? <ButtonSpinner /> : <AttachmentIcon />}
                      <input
                        type="file"
                        onChange={(e) => {
                          dispatch(
                            handleFileUpload({
                              files: e.target.files,
                              id: project?._id,
                            })
                          );
                          e.target.value = "";
                        }}
                        multiple
                        className="opacity-0 position-absolute w-100 top-0 bottom-0 start-0 end-0"
                      />
                    </Button>
                  }
                />
                <CustomTooltip
                  title="View Files"
                  arrow
                  placement="top"
                  content={
                    <Button
                      type="button"
                      className="ms-2 ochat-btn"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      {" "}
                      <FilePresentIcon />
                    </Button>
                  }
                />
              </div>
            </Form.Group>
          </Form>
        </Grid>
      </Grid>
    </div>
  );
};

export default HRHistory;
