import {
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import ButtonSpinner from "../../components/common/ButtonSpinner";
import { BASEURL, headers, checktoken } from "../../utils/helper";
import { addMessage } from "../../redux/slices/MessageSlice";
import GoogleImg from "../../assets/images/google.png";
import Toaster from "../../components/common/Toaster";
import { Fragment, useEffect, useState } from "react";
import Logo from "../../assets/images/logo-white.png";
import { FiEyeOff, FiEye } from "react-icons/fi";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import axios from "axios";
import { useTranslation } from "react-i18next";

const options = [
  { value: "friends", text: "Friends" },
  { value: "youtube", text: "YouTube Channel" },
  { value: "insta-page", text: "Instagram Channel" },
  { value: "insta-ad", text: "Instagram Ads" },
  { value: "on-internet", text: "Searching Net" },
  { value: "other", text: "Other" },
];

function Login() {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [gUser, setGuser] = useState({});
  const [open1, setOpen1] = useState(false);
  const [modal, setModal] = useState(false);
  const [language, setLanguage] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [knowAbout, setKnowAbout] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [roundSpinner, setRoundSpinner] = useState(false);
  const [searchParam, setSearchParams] = useSearchParams();
  const priceid = searchParam.get("priceid");
  // eslint-disable-next-line

  const handleClose = (e) => {
    setOtp("");
    setModal(false);
  };
  const deviceID = localStorage.getItem("deviceID");
  console.log("deviceID..", deviceID);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      callfun(codeResponse.access_token);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const logOut = () => {
    googleLogout();
    cookies.remove("auth", { path: "/" });
    cookies.remove("workspaceauth", { path: "/" });
    window.location.assign(window.location.origin);
  };

  const callfun = async (token) => {
    await new Promise((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `https://www.googleapis.com/oauth2/v3/userinfo`);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          goLogn(this.responseText);
          resolve(JSON.parse(this.responseText));
        } else {
          resolve({ err: "404" });
        }
      };
      xhr.send();
    });
  };

  const goLogn = (data) => {
    setSpinner(true);
    const deviceID = localStorage.getItem("deviceID");
    const newData = JSON.parse(data);
    newData.device = deviceID;
    axios
      .post(`${BASEURL}/google/signup`, newData, {
        withCredentials: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSpinner(false);
        if (res.data.success === true) {
          if (res.data.role === "user") {
            if (
              res.data?.authuser?.where === null ||
              res.data?.authuser?.language === null
            ) {
              setGuser(res.data?.authuser);
              setOpen1(true);
            } else {
              if (priceid) {
                CreatePaymentLink(priceid);
              } else {
                navigate("/user/chat/outside");
              }
            }
          } else {
            cookies.remove("auth", { path: "/" });
            toast.error(res.data.error);
            navigate("/login");
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setSpinner(false);
        if (err?.response?.data?.success === false) {
          toast.error(err.response.data.error);
        }
      });
  };

  const handleSubmit = (e) => {
    setSpinner(true);
    e.preventDefault();
    const formData = { email: email, password: password, device: deviceID };
    axios
      .post(`${BASEURL}/login`, formData, {
        withCredentials: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setSpinner(false);
        if (res.data.success === true) {
          cookies.set("auth", res.data.token, { path: "/" });
          cookies.set("refreshToken", res.data.refreshToken, { path: "/" });
          console.log("res.data...", res.data);
          localStorage.setItem("deviceID", res.data.device);
          dispatch(addMessage({ msg: res.data.message }));
          if (priceid) {
            CreatePaymentLink(priceid);
          } else {
            navigate("/user/chat/outside");
          }
        }
        if (res.data.success === false) {
          cookies.remove("auth", { path: "/" });
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        setSpinner(false);
        if (err?.response?.data?.status === "email") {
          setModal(true);
          setEmail(err?.response?.data?.email);
        }
        if (err?.response?.data?.success === false) {
          toast.error(err.response.data.error);
        }
      });
  };

  const VerifyUserEmail = (otp) => {
    setRoundSpinner(true);
    const FormData = { otp: otp, email: email, password: password };
    axios
      .post(`${BASEURL}/verify/email`, FormData)
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.role === "user") {
            cookies.set("auth", res.data.token, { path: "/" });
            cookies.set("refreshToken", res.data.refreshToken, { path: "/" });
            if (priceid) {
              CreatePaymentLink(priceid);
            } else {
              navigate("/user/chat/outside");
            }
          } else {
            toast.error("Unable to login Please try again!");
            cookies.remove("auth", { path: "/" });
            navigate("/login");
          }
        } else {
          toast.error(res.data.message);
        }
        setRoundSpinner(false);
      })
      .catch((err) => {
        setRoundSpinner(false);
        if (err.response !== undefined) {
          if (err.response.data.error !== undefined) {
            toast.error(err.response.data.error);
          }
        }
      });
  };

  const updateAbout = () => {
    axios
      .put(
        `${BASEURL}/user/update/info/${gUser?._id}`,
        { ...gUser, where: knowAbout, language },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("auth")}`,
          },
        }
      )
      .then((res) => {
        setOpen1(false);
        if (res?.data?.success === true) {
          toast.success(res.data.msg);
          if (priceid) {
            CreatePaymentLink(priceid);
          } else {
            navigate("/user/chat/outside");
          }
        }
      })
      .catch((err) => {
        setOpen1(false);
        if (err?.response?.data?.success === false) {
          toast.error(err.response.data.error);
        }
      });
  };

  const CreatePaymentLink = (id) => {
    axios
      .post(
        `${BASEURL}/admin/create/payment`,
        { id: id },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          dispatch(addMessage({ msg: "You all are set!" }));
          window.location.replace(res.data.data.url);
        }
      })
      .catch((err) => {
        checktoken(err);
      });
  };

  useEffect(() => {
    const msg = localStorage.getItem("logmsg");
    if (msg !== "null" || msg !== "undefined") {
      toast.error(msg);
      // localStorage.clear();
    }
    if (cookies.get("auth")) {
      navigate("/user/chat/outside");
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <section className="section-wrapper form-sec border-t-[1px] border-border py-5">
        <Container>
          <Toaster />
          <Modal show={modal} onHide={handleClose} className="Otp-modal">
            <Modal.Body className="p-4 border-0 rounded-0">
              <h2 className="text-center mb-4 purple-color">
                <img src={Logo} width="100" alt="img" />
              </h2>
              <h5 className="text-center text-white">
                Please enter the code sent to
              </h5>
              <h5 className="text-center mb-5 fw-bold text-white">{email}</h5>
              <OtpInput
                containerStyle="otp-parent justify-content-between mb-5"
                value={otp}
                onInput={(e) => {
                  console.log(otp);
                }}
                onChange={(value) => {
                  setOtp(value);
                  value.length === 6 && VerifyUserEmail(value);
                }}
                numInputs={6}
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} />}
              />
              <Form.Control type="hidden" name="email" value={email} />
              <Form.Control
                type="hidden"
                name="password"
                placeholder="Password"
                value={password}
              />
              {roundSpinner === true && (
                <div className="mb-5 text-center purple-color">
                  <Spinner animation="border" />
                </div>
              )}
            </Modal.Body>
          </Modal>
          <Row>
            <Col md={10} lg={9} className="m-auto">
              <p className="warning-text">
                Signup for free trial. No credit card needed
              </p>
              <p className="warning-text">
                For buying subscription if you don't have credit card send a
                message to Telegram group of "topexecutives"
              </p>
              <p className="warning-text">Thank You.</p>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={5} className="form-wrapper form-box">
              <h2 className="text-center mb-3">{t("login_heading")}</h2>
              <div className="social-login-btn d-flex mb-3 justify-content-center">
                {cookies.get("auth") === undefined ? (
                  <Button
                    className="btn google-login-btn"
                    onClick={() => login()}
                  >
                    {" "}
                    <img
                      alt="g-img"
                      className="me-2"
                      src={GoogleImg}
                      width={30}
                    />
                    {t("google_btn")}{" "}
                  </Button>
                ) : (
                  <Button onClick={logOut} className="btn google-login-btn">
                    {" "}
                    Logout
                  </Button>
                )}
              </div>
              <div
                className="d-block text-center mb-3"
                style={{ fontWeight: "600" }}
              >
                {t("or_text")}
              </div>
              <Form className="mb-3" onSubmit={handleSubmit}>
                <Form.Group className="input-field">
                  <Form.Control
                    type="email"
                    name="email"
                    className="custom-field"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group className="input-field position-relative">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    id="password"
                    className="custom-field"
                    placeholder="Enter Your Password"
                    maxLength="15"
                    autoComplete="off"
                  />
                  <span
                    className="password-eyes position-absolute me-2"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </span>
                </Form.Group>
                <Form.Group className="form-btn">
                  <Link to="/forgot-password" className="link-text forget-text">
                    {t("f_password")}
                  </Link>
                  <Button
                    type="submit"
                    className="primary-btn-1 text-uppercase w-100"
                  >
                    {t("l_btn")} {spinner === true && <ButtonSpinner />}
                  </Button>
                </Form.Group>
                <p className="text-center mt-2">
                  {t("d_acc")}{" "}
                  <Link
                    to={`${priceid ? "/signup?priceid=" + priceid : "/signup"}`}
                    className="link-text"
                  >
                    {t("s_text")}
                  </Link>
                </p>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>

      <Dialog open={open1} maxWidth={"sm"} fullWidth>
        <DialogTitle>How did you know about us?</DialogTitle>
        <Divider style={{ borderColor: "black" }} />
        <DialogContent>
          {options?.map((item, i) => (
            <Form.Check
              key={`item-${i}`}
              name="page"
              type="radio"
              value={item?.value}
              label={item?.text}
              id={`radio-${i + 1}`}
              onChange={(e) => {
                setKnowAbout(e.target.value);
              }}
            />
          ))}
          <Form.Group className="input-field mt-3">
            <Form.Select
              style={{ color: "#595c5f" }}
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
            >
              <option>Your profession best described by</option>
              <option value="business owner">Business owner</option>
              <option value="digital marketer">Digital marketer</option>
              <option value="HR specialist">HR specialist</option>
              <option value="Business consultant">Business consultant</option>
              <option value="other">Other</option>
            </Form.Select>
          </Form.Group>
        </DialogContent>
        <DialogActions>
          {knowAbout && language ? (
            <MuiButton
              className="bg-primary-light hover:bg-primary-main text-white"
              onClick={updateAbout}
            >
              Ok
            </MuiButton>
          ) : (
            <MuiButton className="bg-slate-400 text-white" disabled>
              Ok
            </MuiButton>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default Login;
