import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const AppHistoryRequest = createAsyncThunk(
  "AppHistoryRequest",
  async ({ id, type }) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/project/${id}/${type}/prompts`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const AppHistorySlice = createSlice({
  name: "AppHistory",
  initialState: {
    data: [],
    error: null,
    message: null,
    loading: false,
  },
  reducers: {
    addHistory: (state, action) => {
      state.data = action.payload;
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(AppHistoryRequest.pending, (state, action) => {
      state.loading = true;
      state.data = [];
      return state;
    });
    builder.addCase(AppHistoryRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(AppHistoryRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export const { addHistory } = AppHistorySlice.actions;
export default AppHistorySlice.reducer;
