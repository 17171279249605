// ModalSlice
import { createSlice } from '@reduxjs/toolkit'

export const ModalSlice = createSlice({
    name: 'planmodal',
    initialState: false,
    reducers: {
        openModal: (state, action) => {
            state = action.payload
            return state
        },
        hideModal: (state, action) => {
            state = action.payload
            return state
        },
    },
    extraReducers(builder){
        builder.addCase('LOGOUT', () => this.initialState)
    }
})

export const { openModal, hideModal } = ModalSlice.actions
export default ModalSlice.reducer
