// TranscribeImageSlice

import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const TranscribeImageProjectRequest = createAsyncThunk(
  "TranscribeImageProjectRequest",
  async (type) => {
    try {
      const response = await axios.get(
        `${BASEURL}/user/latest/project?type=${type}`,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const CreateTranscribeImageProject = createAsyncThunk(
  "CreateTranscribeImageProject",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/project/create`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const SaveTranscribeImageHistory = createAsyncThunk(
  "SaveTranscribeImageHistory",
  async (data) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/create/chat/history`,
        data,
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const TranscribeImageSlice = createSlice({
  name: "TranscribeImageProject",
  initialState: {
    data: {},
    error: null,
    loading: false,
  },
  reducers: {
    AddTranscribeImageProject: (state, action) => {
      state.data = action.payload;
      return state;
    },
    DeleteTranscribeImageProject: (state, action) => {
      state.data = {};
      return state;
    },
  },
  extraReducers(builder) {
    builder.addCase(TranscribeImageProjectRequest.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(
      TranscribeImageProjectRequest.fulfilled,
      (state, action) => {
        state.loading = false;
        state.data = action.payload.data;
        return state;
      }
    );
    builder.addCase(TranscribeImageProjectRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase(CreateTranscribeImageProject.pending, (state, action) => {
      state.loading = true;
      state.data = {};
      return state;
    });
    builder.addCase(CreateTranscribeImageProject.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(CreateTranscribeImageProject.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export const { AddTranscribeImageProject, DeleteTranscribeImageProject } =
  TranscribeImageSlice.actions;
export default TranscribeImageSlice.reducer;
