import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";

const About = () => {
  const sections = [
    {
      title: "About TopExecutives.ai",
      content:
        "At TopExecutives.ai, we believe that empowering people is the key to driving business success. Our mission is to provide small and medium businesses with the most affordable and powerful AI tools that allow them to thrive and grow in today's competitive landscape.",
      image: "../about/logo.png",
    },
    {
      title: "The DNA of a New Generation Solution",
      content:
        "At the heart of TopExecutives.ai is a focus on people. We designed our platform to provide businesses with the tools they need to manage and optimize their daily operations. Whether it's generating insightful articles, creating compelling social media content, or translating documents across 80 languages, our suite of AI-powered modules ensures that every task is handled with precision and efficiency.",
      image: "../about/Image-1.png",
    },
    {
      title: "Forward-thinking and Future-proofed",
      content:
        "The AI tools we provide are built with the future in mind. As technology advances, so do the needs of businesses. TopExecutives.ai embraces these changes and continuously updates our platform to meet the evolving demands of our users. Our solutions are not just reactive; they are proactive, helping businesses stay ahead of the curve and prepare for the future.",
      image: "../about/Image-2.png",
    },
    {
      title: "Why We Put People Before Processes",
      content:
        "When we created TopExecutives.ai, we had two options: follow the crowd and build a generic solution or create something truly unique. We chose the latter. Our focus has always been on delivering exceptional value to our users by creating tools that are intuitive, accessible, and tailored to their needs. By prioritizing people over processes, we've built a platform that genuinely supports business growth.",
      image: "../about/Image-3.png",
    },
    {
      title: "Our Vision",
      content:
        "TopExecutives.ai was born from a vision to empower businesses with the tools they need to succeed. Our platform is more than just software; it's a partner in your business's journey. We envision a world where AI is not a luxury but a standard tool that every business, regardless of size, can access and benefit from.",
      image: "../about/Image-4.png",
    },
  ];

  return (
    <Container
      sx={{ bgcolor: "white", py: { xs: 2, sm: 4 }, maxWidth: { md: "90%" } }}
    >
      <Grid container spacing={4}>
        {sections.map((section, index) => (
          <div className="my-8">
            <Grid item xs={12} key={index}>
              <Typography
                variant="h5"
                sx={{
                  fontSize: {
                    xs: "20px", // 20px for mobile screens
                    sm: "40px", // 30px for screens larger than mobile
                  },
                  fontWeight: "bold",
                  mb: 2,
                  color: "#6C0345",
                  textAlign: "center",
                }}
              >
                {section.title}
              </Typography>
              <Grid container spacing={4} alignItems="center">
                <Grid
                  item
                  xs={12}
                  // md={6}
                  // md={index % 2 === 0 ? 4 : 8}
                  md={4}
                  order={{ xs: 1, md: index % 2 === 0 ? 2 : 1 }}
                >
                  <div
                    className={` px-5 md:px-0 ${
                      index % 2 === 0
                        ? "md:pl-3 md:border-l-4 md:border-l-primary-main"
                        : "md:pr-3 md:border-r-4 md:border-r-primary-main"
                      //   : "border-l-primary-main"
                    }`}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "14px",
                          sm: "20px",
                        },
                        color: "#495057",
                        // fontFamily: "'Montserrat', sans-serif",
                        // fontWeight: 400,
                      }}
                    >
                      {section.content}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  // md={6}
                  // md={index % 2 === 0 ? 4 : 8}
                  md={8}
                  order={{ xs: 2, md: index % 2 === 0 ? 1 : 2 }}
                >
                  <Box
                    component="img"
                    src={section.image}
                    alt={section.title}
                    sx={{
                      width: { xs: "80%", md: "100%" },
                      borderRadius: 6,
                      padding: "10px",
                      display: "block", // Makes the image a block-level element
                      marginLeft: "auto", // Centers the image horizontally
                      marginRight: "auto", // Centers the image horizontally
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        ))}
      </Grid>
    </Container>
  );
};

export default About;
