import { headers, BASEURL, checktoken } from "../../utils/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const FileListRequest = createAsyncThunk(
  "FileListRequest",
  async ({ id }) => {
    try {
      const response = await axios.get(`${BASEURL}/user/project/${id}/files`, {
        headers: headers(),
      });
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const DeleteFileRequest = createAsyncThunk(
  "DeleteFileRequest",
  async ({ id, project, file }) => {
    try {
      const response = await axios.post(
        `${BASEURL}/user/project/${project}/delete/file/${id}`,
        { file },
        { headers: headers() }
      );
      return response.data;
    } catch (error) {
      checktoken(error);
      return error;
    }
  }
);

export const FileListSlice = createSlice({
  name: "FileList",
  initialState: {
    data: [],
    deleted: {},
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(FileListRequest.pending, (state, action) => {
      state.loading = true;
      state.data = [];
      return state;
    });
    builder.addCase(FileListRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(FileListRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error occured";
    });

    builder.addCase(DeleteFileRequest.pending, (state, action) => {
      state.loading = true;
      state.data = [];
      return state;
    });
    builder.addCase(DeleteFileRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data;
      return state;
    });
    builder.addCase(DeleteFileRequest.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = "Error occured";
    });
    builder.addCase("LOGOUT", () => this.initialState);
  },
});

export default FileListSlice.reducer;
