import { headers, BASEURL, checktoken } from '../../utils/helper'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios';

export const AppProjectRequest = createAsyncThunk('AppProjectRequest', async ({ type, filter }) => {
    try {
        const response = await axios.get(`${BASEURL}/user/project/list/${type}?filter=${filter}`, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
        return error;
    }
})
export const SearchProjectRequest = createAsyncThunk('SearchProjectRequest', async ({ type, text, filter }) => {
    try {
        const response = await axios.get(`${BASEURL}/user/search/project/${type}?text=${text}&filter=${filter}`, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
        return error;
    }
})

export const DeleteProjectRequest = createAsyncThunk('DeleteProjectRequest', async (data) => {
    try {
        const response = await axios.post(`${BASEURL}/user/project/delete`, data, { headers: headers() })
        return response.data
    } catch (error) {
        checktoken(error)
        return error;
    }
})


export const AppProjectSlice = createSlice({
    name: 'AppProject',
    initialState: {
        data: [],
        error: null,
        message: null,
        loading: false,
    },
    reducers: {},
    extraReducers(builder) {
        builder.addCase(AppProjectRequest.pending, (state, action) => {
            state.loading = true
            state.data = []
            return state
        })
        builder.addCase(AppProjectRequest.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            return state
        })
        builder.addCase(AppProjectRequest.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = 'Error occured'
        })
        builder.addCase(DeleteProjectRequest.pending, (state, action) => {
            state.loading = true
            state.data = []
            return state
        })
        builder.addCase(DeleteProjectRequest.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            state.message = action.payload.message
            return state
        })
        builder.addCase(DeleteProjectRequest.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = 'Error occured'
        })
        builder.addCase(SearchProjectRequest.pending, (state, action) => {
            state.loading = true
            state.data = []
            return state
        })
        builder.addCase(SearchProjectRequest.fulfilled, (state, action) => {
            state.loading = false
            state.data = action.payload.data
            return state
        })
        builder.addCase(SearchProjectRequest.rejected, (state, action) => {
            state.loading = false
            state.data = []
            state.error = 'Error occured'
        })
        builder.addCase('LOGOUT', () => this.initialState)
    }
})

export default AppProjectSlice.reducer
