import { createSlice } from '@reduxjs/toolkit'
const initialState = {}

export const ScrapeSlice = createSlice({
    name: 'scrape',
    initialState,
    reducers: {
        // Single-video-content
        ScrapeOnLoading: (state, { payload }) => {
            return {
                ...state,
                loading: true,
                data: null,
                error: null
            }
        },
        ScrapeOnSuccess: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null
            }
        },
        ScrapeOnFailure: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: null,
                error: payload
            }
        },
        // end reducers
    },
})

export const {
    ScrapeOnLoading,
    ScrapeOnSuccess,
    ScrapeOnFailure,
} = ScrapeSlice.actions

export default ScrapeSlice.reducer