import Logo from '../../assets/images/logo-white.png'
import { Typography } from '@mui/material';

const Block = () => {
    return (
        <div className="position-fixed top-0 bottom-0 start-0 end-0 d-grid align-items-center justify-content-center loadar-div" style={{ zIndex: 999, background: '#6c0345' }}>
            <div className="flex justify-center flex-col col-sm-8 mx-auto">
                <div className="text-center">
                    <img className='zoom-in-zoom-out' src={Logo} width={150} alt="img" />
                </div>
                <Typography component="p" className="text-2xl text-center font-semibold ff-jost p-7 text-white">
                    Thank you for your interest in Top-Executives platform. To activate your account pls send your user name and language of choice to "Top-Executives" group either in telegram or WhatsApp<br />
                    Top-Executives<br />
                    Admin
                </Typography>
            </div>
        </div>
    )
}

export default Block;