import { BASEURL, headers, checktoken } from '../../utils/helper';
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { AddSubs } from '../../redux/slices/MemberSlice';
import Loader from '../../components/common/Loader';
import { useDispatch } from 'react-redux';
import axios from 'axios';

const Success = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [spinner, setSpinner] = useState(true);
    const searchParams = new URLSearchParams(location.search);
    const user_id = searchParams.get('user_id');
    const payment_intent = searchParams.get('session_id');

    const paymentSuccess = () => {
        axios.post(`${BASEURL}/admin/payment/link/success`, { payment_intent: payment_intent }, {
            headers: headers()
        }).then((res) => {
            console.log(res.data)
            if (res.data.success === true) {
                if (user_id) {
                    dispatch(AddSubs(res?.data?.subscription))
                    navigate(`/user/chat/account?user_id=${user_id}`)
                } else {
                    navigate('/user/chat/account')
                }
                // window.location.href = window.location.origin+'/user/chat/account';
            }
        }).catch((err) => {
            checktoken(err);
            setSpinner(false)
        })
    }


    useEffect(() => {
        paymentSuccess();
    }, []);

    return (
        <Fragment>
            {(spinner === true) && <Loader />}
            <h1 className="success-h1">Success</h1>
        </Fragment>
    )
}

export default Success;