import ButtonSpinner from '../../components/common/ButtonSpinner';
import { headers, checktoken, BASEURL } from "../../utils/helper";
import { Fragment, useEffect, useState } from "react";
import Toaster from '../../components/common/Toaster';
import { useOutletContext } from "react-router-dom";
import Loader from '../../components/common/Loader';
import { Form, Card, Col } from "react-bootstrap";
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import axios from 'axios';

const Profile = () => {
    const [user] = useOutletContext();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [lastname, setLastname] = useState('')
    const [firstname, setFirstame] = useState('')
    const [spinner, setSpinner] = useState(false)
    const [isLoader, setIsLoader] = useState(true)
    const [oldpassword, setOldPassword] = useState('')

    const AdminProfile = () => {
        setIsLoader(true)
        axios.get(`${BASEURL}/admin/user/profile`, {
            headers: headers()
        }).then((res) => {
            if (res.data?.success === true) {
                setEmail(res.data.data.email);
                setLastname(res.data.data.lastname);
                setFirstame(res.data.data.firstname);
            }
            setIsLoader(false)
        }).catch((err) => {
            checktoken(err);
            setIsLoader(false)
            if (err?.response?.data?.success === false) {
                toast.error(err.response.data.error);
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSpinner(true);
        const formData = {
            email: email,
            password: password,
            lastname: lastname,
            firstname: firstname,
            oldpassword: oldpassword
        }
        axios.put(`${BASEURL}/admin/update/profile/${user._id}`, formData, {
            headers: headers()
        }).then((res) => {
            if (res.data?.success === true) {
                AdminProfile()
            }
            setSpinner(false)
        }).catch((err) => {
            checktoken(err);
            setSpinner(false)
            if (err?.response?.data?.success === false) {
                toast.error(err.response.data.error);
            }
        })
    }

    useEffect(() => {
        AdminProfile()
    }, [])

    return (
        <Card>
            {isLoader === true && <Loader />}
            <Toaster />
            <Card.Header>
                <Card.Title className="my-2">Admin Info</Card.Title>
            </Card.Header>
            <Card.Body>
                <Col md={6} className="m-auto">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Firstname</Form.Label>
                            <Form.Control type="text" name="firstname" value={firstname} onChange={(e) => { setFirstame(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Lastame</Form.Label>
                            <Form.Control type="text" name="lastname" value={lastname} onChange={(e) => { setLastname(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        </Form.Group>
                        {
                            user.role === 'admin' &&
                            <Fragment>
                                <Form.Group className="mb-3">
                                    <Form.Label>Old Password</Form.Label>
                                    <Form.Control type="password" name="oldpassword" value={oldpassword} onChange={(e) => { setOldPassword(e.target.value) }} />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>New Password</Form.Label>
                                    <Form.Control type="password" name="password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                </Form.Group>
                            </Fragment>
                        }
                        <Form.Group className="input-field">
                            <Button type="submit" className="bg-primary-light text-white hover:bg-primary-dark">Update {spinner === true && <ButtonSpinner />}</Button>
                        </Form.Group>
                    </Form>
                </Col>
            </Card.Body>

        </Card>
    )
}

export default Profile;